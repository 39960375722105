import { FC, useMemo } from 'react';
import { Input, Form, Space, InputNumber, Typography, Tooltip } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemPhotoCount: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const photoCount = Form.useWatch('photoCount');
  const publishedItemValues = useMemo(
    () => ({
      photoCount: fieldChanges?.photoCount.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      label={
        fieldChanges?.photoCount.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('required_images_tooltip')}>
              <span>{translation('required_images')} </span>{' '}
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemPhotoCount />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('required_images_tooltip')}>
            <span>{translation('required_images')} </span>{' '}
          </Tooltip>
        )
      }
    >
      <Input.Group>
        <Space>
          <Form.Item
            label="Min"
            name={['photoCount', 'min']}
            rules={[
              { required: true, message: 'Insert a minimum' },
              {
                validator: async (_, min) => {
                  if (min > photoCount?.max) {
                    return Promise.reject(
                      new Error("Min can't be higher then max")
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              disabled={!editMode}
              placeholder="Min"
              min={1}
              max={photoCount?.max}
            />
          </Form.Item>
          <Form.Item
            label="Max"
            name={['photoCount', 'max']}
            rules={[{ required: true, message: 'Insert a maximum' }]}
          >
            <InputNumber disabled={!editMode} placeholder="Max" />
          </Form.Item>
        </Space>
      </Input.Group>
    </Form.Item>
  );
};

export default FormItemPhotoCount;
