import { Modal } from 'antd';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { showNotificationMessage } from '../../utils/notification';
import styles from './imageSelector.module.scss';
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor';

interface Props {
  src: string;
  isSelected?: boolean;
  isVisible: boolean;
  okText: string;
  isDisabled?: boolean;
  onSelect?: (editedImageUrl?: string) => void;
  onClose: () => void;
}

const ImageEdit: React.FC<Props> = ({
  src,
  isVisible = false,
  isDisabled = false,
  okText,
  onSelect,
  onClose,
}) => {
  const intl = useIntl();
  const handleClose = () => {
    onClose();
  };
  const editedImage = useRef<
    (
      imageFileInfo?: object,
      pixelRatio?: boolean,
      keepLoadingSpinnerShown?: boolean
    ) => {
      imageData: object;
      designState: object;
      hideLoadingSpinner: (...args: any[]) => any;
    }
  >();

  const handleSave = async () => {
    let imgData: any;
    const { current: saveFunction } = editedImage;
    if (saveFunction) {
      const result = await saveFunction();
      imgData = result?.imageData;
    }

    const data = imgData?.imageCanvas?.toDataURL();
    if (data) {
      const req = new Request(data);
      try {
        const resp = await fetch(req);
        const tempBlob = await resp.blob();
        if (tempBlob) {
          const objectUrl = URL.createObjectURL(tempBlob);
          onSelect && onSelect(objectUrl);
          onClose();
        }
      } catch (error: any) {
        showNotificationMessage({
          message: intl.formatMessage({
            id: 'image_editor_error',
          }),
          error: error,
        });
      }
    } else {
      showNotificationMessage({
        message: intl.formatMessage({
          id: 'image_editor_error',
        }),
      });
    }
  };

  if (isVisible && src) {
    return (
      <Modal
        centered
        destroyOnClose
        width={'90vw'}
        onCancel={handleClose}
        onOk={handleSave}
        okText={okText}
        cancelText={intl.formatMessage({
          id: 'cancel_editing',
        })}
        okButtonProps={{
          disabled: isDisabled,
        }}
        maskClosable={false}
        open={isVisible}
        title={<div style={{ height: '20px' }}></div>}
        bodyStyle={{
          padding: '0',
          backgroundColor: '#fff',
          width: '100%',
          height: '87vh',
          position: 'relative',
        }}
        className={styles.imgEditModal}
      >
        <FilerobotImageEditor
          source={src}
          getCurrentImgDataFnRef={editedImage}
          onBeforeSave={() => false}
          onClose={undefined}
          annotationsCommon={{
            fill: 'transparent',
            stroke: '#FF0000',
            strokeWidth: 3,
            opacity: 1,
          }}
          Text={{
            text: 'Text',
            fontSize: 18,
            letterSpacing: 0,
            lineHeight: 1,
            align: 'left',
            fontStyle: 'normal',
            strokeWidth: 1,
            fill: '#003580',
            stroke: '#003580',
          }}
          Rotate={{ angle: 90, componentType: 'slider' }}
          Crop={{
            presetsItems: [
              {
                titleKey: 'classicTv',
                descriptionKey: '4:3',
                ratio: 4 / 3,
              },
              {
                titleKey: 'cinemascope',
                descriptionKey: '21:9',
                ratio: 21 / 9,
              },
            ],
            presetsFolders: [],
          }}
          Pen={{
            strokeWidth: 3,
            tension: 0.5,
            lineCap: 'round',
            fill: '#FF0000',
            stroke: '#FF0000',
          }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FINETUNE]}
          defaultTabId={TABS.ANNOTATE}
          defaultToolId={TOOLS.PEN}
          savingPixelRatio={4}
          previewPixelRatio={4}
        />
      </Modal>
    );
  } else {
    return null;
  }
};

export default ImageEdit;
