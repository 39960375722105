import { Option, WorkType, WorkTypesGroup } from '../types';

export const asignGroupsForWorkTypes = (
  allGroups: WorkTypesGroup[],
  allWorkTypes: WorkType[]
) => {
  if (
    allWorkTypes?.length &&
    allWorkTypes?.length > 0 &&
    allGroups?.length &&
    allGroups?.length > 0
  ) {
    let indexedGroupObj: any = {};

    allGroups.forEach((item: any) => {
      if (!indexedGroupObj[item.name]) {
        //key = group name and value = array of workTypes:
        indexedGroupObj[item.name] = item.workTypes;
      }
    });

    allWorkTypes = allWorkTypes.map((obj: any) => {
      return {
        ...obj,
        groups: Object.keys(indexedGroupObj)
          .map(
            (group) =>
              indexedGroupObj[group].filter((item: any) => item.id === obj.id)
                .length > 0 && group
          )
          .filter((item) => !!item),
      };
    });
  }
  return allWorkTypes;
};

export const getTagColor = (group: string | undefined): string => {
  switch (group) {
    case 'AC':
      return 'gold';
    case 'UK':
      return 'lime';
    case 'DC':
      return 'magenta';
    case 'Doku':
      return 'orange';
    case 'ZT':
      return 'cyan';
    case 'WallBox':
      return 'geekblue';
    case 'AC-M':
      return 'purple';
    default:
      return 'green';
  }
};

export const getOptions = <T>(obj: T): Option[] => {
  // @ts-ignore
  return Object.entries(obj).map(([key, value]) => ({
    label: value,
    value: key,
  }));
};

// Comparing two items:
export const compare = (item1: any, item2: any) => {
  // Get the object type
  const itemType = Object.prototype.toString.call(item1);

  // If an object or array, compare recursively
  if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
    if (!isEqual(item1, item2)) return false;
  }
  // Otherwise, a simple comparison:
  else {
    // If the two items are not the same type, return false
    if (itemType !== Object.prototype.toString.call(item2)) return false;

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (itemType === '[object Function]') {
      if (item1.toString() !== item2.toString()) return false;
    } else {
      if (item1 !== item2) return false;
    }
  }
};
//Function comparing if Arrays are same:
export const isEqual = (value: any, other: any) => {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the two items
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }
  // If nothing failed, return true
  return true;
};
