import React from 'react';

const UserApprovalPackageHistoryEmptyIcon: React.FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      d="M58.0521 13.7195H13.7248C12.7307 13.7195 11.9248 14.5254 11.9248 15.5195V35.9375C11.9248 36.9316 12.7307 37.7375 13.7248 37.7375H58.0521C59.0462 37.7375 59.8521 36.9316 59.8521 35.9375V15.5195C59.8521 14.5254 59.0462 13.7195 58.0521 13.7195Z"
      fill="#597EF7"
    />
    <path
      d="M52.05 19.7195H31.725C31.3936 19.7195 31.125 19.9881 31.125 20.3195V21.5195C31.125 21.8509 31.3936 22.1195 31.725 22.1195H52.05C52.3814 22.1195 52.65 21.8509 52.65 21.5195V20.3195C52.65 19.9881 52.3814 19.7195 52.05 19.7195Z"
      fill="#2F54EB"
    />
    <path
      d="M42.6 29.3196H31.725C31.3936 29.3196 31.125 29.5882 31.125 29.9196V31.1196C31.125 31.451 31.3936 31.7196 31.725 31.7196H42.6C42.9314 31.7196 43.2 31.451 43.2 31.1196V29.9196C43.2 29.5882 42.9314 29.3196 42.6 29.3196Z"
      fill="#597EF7"
    />
    <path
      d="M52.05 24.5195H31.725C31.3936 24.5195 31.125 24.7882 31.125 25.1195V26.3195C31.125 26.6509 31.3936 26.9195 31.725 26.9195H52.05C52.3814 26.9195 52.65 26.6509 52.65 26.3195V25.1195C52.65 24.7882 52.3814 24.5195 52.05 24.5195Z"
      fill="#2F54EB"
    />
    <path
      d="M23.3248 19.7195H16.1248C15.4621 19.7195 14.9248 20.2567 14.9248 20.9195V28.1195C14.9248 28.7822 15.4621 29.3195 16.1248 29.3195H23.3248C23.9875 29.3195 24.5248 28.7822 24.5248 28.1195V20.9195C24.5248 20.2567 23.9875 19.7195 23.3248 19.7195Z"
      fill="#2F54EB"
    />
    <path
      d="M27.5625 19.7195V35.345"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.2523 28.3625H5.925C4.93089 28.3625 4.125 29.1684 4.125 30.1625V50.5806C4.125 51.5747 4.93089 52.3806 5.925 52.3806H50.2523C51.2464 52.3806 52.0522 51.5747 52.0522 50.5806V30.1625C52.0522 29.1684 51.2464 28.3625 50.2523 28.3625Z"
      fill="#E4EBF7"
    />
    <path
      d="M44.2502 34.3625H23.9252C23.5938 34.3625 23.3252 34.6312 23.3252 34.9625V36.1625C23.3252 36.4939 23.5938 36.7626 23.9252 36.7626H44.2502C44.5816 36.7626 44.8502 36.4939 44.8502 36.1625V34.9625C44.8502 34.6312 44.5816 34.3625 44.2502 34.3625Z"
      fill="#597EF7"
    />
    <path
      d="M34.8002 43.9624H23.9252C23.5938 43.9624 23.3252 44.231 23.3252 44.5624V45.7624C23.3252 46.0938 23.5938 46.3624 23.9252 46.3624H34.8002C35.1316 46.3624 35.4002 46.0938 35.4002 45.7624V44.5624C35.4002 44.231 35.1316 43.9624 34.8002 43.9624Z"
      fill="#597EF7"
    />
    <path
      d="M44.2502 39.1626H23.9252C23.5938 39.1626 23.3252 39.4312 23.3252 39.7626V40.9626C23.3252 41.294 23.5938 41.5626 23.9252 41.5626H44.2502C44.5816 41.5626 44.8502 41.294 44.8502 40.9626V39.7626C44.8502 39.4312 44.5816 39.1626 44.2502 39.1626Z"
      fill="#597EF7"
    />
    <path
      d="M15.525 34.3625H8.325C7.66226 34.3625 7.125 34.8998 7.125 35.5625V42.7626C7.125 43.4253 7.66226 43.9626 8.325 43.9626H15.525C16.1877 43.9626 16.725 43.4253 16.725 42.7626V35.5625C16.725 34.8998 16.1877 34.3625 15.525 34.3625Z"
      fill="#597EF7"
    />
    <path
      d="M19.7627 34.3625V49.988"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UserApprovalPackageHistoryEmptyIcon;
