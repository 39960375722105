export const MapsSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18 6V6.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 13.0009L14.5 8.00095C14.1631 7.39198 13.9909 6.70564 14.0004 6.00974C14.0099 5.31384 14.2008 4.63247 14.5543 4.03295C14.9077 3.43342 15.4115 2.9365 16.0158 2.59127C16.6201 2.24604 17.304 2.06445 18 2.06445C18.696 2.06445 19.3799 2.24604 19.9842 2.59127C20.5885 2.9365 21.0923 3.43342 21.4457 4.03295C21.7992 4.63247 21.9901 5.31384 21.9996 6.00974C22.0091 6.70564 21.8369 7.39198 21.5 8.00095L18 13.0009Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 4.75L9 4L3 7V20L9 17L15 20L21 17V15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 4V17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 15V20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
