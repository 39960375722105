import { FC, useMemo, useState } from 'react';
import { Modal, Form, Input, Upload, Button, Typography, message } from 'antd';
import styles from '../appointmentTypeDetail.module.scss';
import { UploadOutlined } from '@ant-design/icons';
import { AppointmentTypeFile } from '../../types';
import ApiRepository from '../../../../services/api/apiRepository';
import translation from '../../../../i18n/translation';
const { Text } = Typography;

interface Props {
  visible: boolean;
  file: any;
  hideModal: () => void;
  handleUpdateFileList: (arg0: string, arg1: AppointmentTypeFile) => void;
}

const FileUploadModal: FC<Props> = ({
  file,
  visible,
  hideModal,
  handleUpdateFileList,
}) => {
  const [formObj] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);

  const uploadAttachment = async () => {
    const currentFile = fileList[0];
    if (currentFile) {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append(`file`, currentFile);

        const res = await ApiRepository.uploadFile(
          'appointmentdocuments',
          formData
        );
        setUploading(false);
        const url = res?.response[0]?.url;
        const originalFileName = res?.response[0]?.originalFileName;

        handleUpdateFileList('add-new-file', {
          ...currentFile,
          url: url,
          label: formObj.getFieldValue('label'),
          originalFileName: originalFileName,
        });

        message.success('File successfully uploaded!', 5);
        setLoading(false);
        hideModal();
      } catch (error: any) {
        setUploading(false);
        message.error(error?.message, 5);
      }
    }
  };

  const handleOk = async () => {
    //case: updating the label
    if (!!file) {
      handleUpdateFileList('update-file', formObj.getFieldsValue(true));
      setLoading(false);
      hideModal();
    } else {
      //case: adding new
      uploadAttachment();
    }
  };

  const initialFormState: { [key: string]: any } = useMemo(
    () => ({
      label: file?.label,
      url: file?.url,
    }),
    [file]
  );

  return (
    <Modal
      destroyOnClose
      title="File"
      okText={!!file?.label ? 'Update' : 'Save'}
      open={visible}
      onOk={handleOk}
      onCancel={hideModal}
      okButtonProps={{
        disabled: fileList.length === 0 && !file,
        loading: uploading,
      }}
      cancelButtonProps={{ disabled: loading }}
      zIndex={2020}
    >
      <Form
        form={formObj}
        layout="vertical"
        initialValues={initialFormState}
        style={{ zIndex: 2021 }}
      >
        <Form.Item
          key="label"
          name="label"
          label="Label"
          rules={[
            {
              required: true,
              message: translation('provide_file_label'),
            },
          ]}
        >
          <Input placeholder="Insert a label" />
        </Form.Item>
        <Form.Item name="attachments" key="upload">
          <Upload
            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            disabled={!!file}
            multiple={false}
            beforeUpload={(file: any) => {
              setFileList([file]);
              return false;
            }}
          >
            <Button disabled={!!file} icon={<UploadOutlined />}>
              Upload
            </Button>
            {!!file && (
              <a
                className={styles.download}
                target="_blank"
                href={file.url}
                rel="noreferrer"
                style={{ width: '85%', maxWidth: '80%' }}
              >
                <Text
                  ellipsis
                  style={{
                    paddingLeft: '16px',
                    paddingTop: '4px',
                    maxWidth: '80%',
                    height: '32px',
                  }}
                >
                  {fileList[0]?.label}
                </Text>
              </a>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FileUploadModal;
