import { FC, ReactNode } from 'react';
import { Col, Row, Tag, Typography } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

interface Props {
  id?: string;
  children: ReactNode;
  status?: string;
  tooltip?: string;
  showDropdownArrow?: boolean;
  isLoading?: boolean;
}

export const getIcon = (status: string | undefined) => {
  switch (status) {
    case 'success':
      return <CheckCircleOutlined />;
    case 'warning':
      return <SyncOutlined />;
    case 'error':
      return <CloseCircleOutlined />;
    default:
      return <ClockCircleOutlined />;
  }
};

const StatusTag: FC<Props> = ({
  id = '',
  children,
  status = 'default',
  tooltip = '',
  isLoading = false,
  showDropdownArrow = false,
}) => {
  return (
    <Tag
      id={id}
      title={tooltip}
      color={status}
      icon={isLoading ? <SyncOutlined spin /> : getIcon(status)}
      style={{ width: '100%', fontSize: '12px' }}
    >
      <Row style={{ display: 'inline-flex', width: '100%', fontSize: '12px' }}>
        <Col flex="auto">
          <Text ellipsis style={{ color: 'inherit', fontSize: '12px' }}>
            {children}
          </Text>
        </Col>
        <Col flex="30px">{showDropdownArrow && <DownOutlined />}</Col>
      </Row>
    </Tag>
  );
};

export default StatusTag;
