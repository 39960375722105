import { Dispatch } from 'redux';
import { FormBuilderTableActions, FetchFormsReqProps } from './types';
import { metaDataTypes } from '../../constants/types';
import ApiRepository from '../../services/api/apiRepository';

export const getMetaData = async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: metaDataTypes.GET_META_DATA,
      payload: await ApiRepository.getMetadataList(),
    });
  } catch (err: any) {
    dispatch({
      type: metaDataTypes.GET_META_DATA_FAIL,
      payload: err,
    });
  }
};

export const createMetaData = (key: string, description: string) => async (
  dispatch: Dispatch
) => {
  try {
    await ApiRepository.createMetaData(key, description);
    const asyncResp = await ApiRepository.getMetadataList();

    dispatch({
      type: metaDataTypes.POST_META_DATA,
      payload: asyncResp,
    });
  } catch (err: any) {
    dispatch({
      type: metaDataTypes.GET_META_DATA_FAIL,
      payload: err,
    });
  }
};

export const setMetaDataLoading = (loading: boolean) => ({
  type: metaDataTypes.SET_META_DATA_LOADING,
  payload: loading,
});

export const resetMetaDataError = () => ({
  type: metaDataTypes.GET_META_DATA_FAIL,
  payload: null,
});

export const fetchFormsStart = () => ({
  type: FormBuilderTableActions.FETCH_FORMS_START,
});

export const fetchFormsAsync = ({
  pageNumber,
  pageSize,
  sortedBy,
  status,
  search,
}: FetchFormsReqProps) => async (dispatch: Dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const response = await ApiRepository.getForms({
      pageNumber,
      pageSize,
      sortedBy,
      status,
      search,
    });
    dispatch({
      type: FormBuilderTableActions.FETCH_FORMS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FormBuilderTableActions.FETCH_FORMS_FAILED,
      payload: error,
    });
  }
};
