import { AnyAction } from 'redux';
import { approvalPackageTypes, IQueue } from '../../constants/types';
import ApiRepository from '../../services/api/apiRepository';

const actions = {
  getAssignedApprovalPackageInfo() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: approvalPackageTypes.GET_APPROVAL_PACKAGE_INFO,
          payload: await ApiRepository.getAssignedApprovalPackageInfo(),
        });
      } catch (err: any) {
        dispatch({
          type: approvalPackageTypes.SET_APPROVAL_PACKAGE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  getUserAppprovalPackageHistory() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: approvalPackageTypes.GET_USER_APPROVAL_PACKAGE_HISTORY,
          payload: await ApiRepository.getUserApprovalPackageHistory(),
        });
      } catch (err: any) {
        dispatch({
          type: approvalPackageTypes.SET_USER_APPROVAL_PACKAGE_HISTORY_ERROR,
          payload: err,
        });
      }
    };
  },

  setUserAppprovalPackageHistoryLoading() {
    return {
      type: approvalPackageTypes.SET_USER_APPROVAL_PACKAGE_HISTORY_LOADING,
    };
  },

  resetUserAppprovalPackageHistory() {
    return {
      type: approvalPackageTypes.RESET_USER_APPROVAL_PACKAGE_HISTORY,
    };
  },

  assignApprovalPackage(source: IQueue) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: approvalPackageTypes.GET_APPROVAL_PACKAGE_QUEUE,
          payload: await ApiRepository.assignApprovalPackage(source),
        });
      } catch (err: any) {
        dispatch({
          type: approvalPackageTypes.SET_APPROVAL_PACKAGE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  assignNextApprovalPackage(assignmentId: string, formFieldId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: approvalPackageTypes.GET_APPROVAL_PACKAGE_QUEUE,
          payload: await ApiRepository.assignNextApprovalPackage(
            assignmentId,
            formFieldId
          ),
        });
      } catch (err: any) {
        dispatch({
          type: approvalPackageTypes.SET_APPROVAL_PACKAGE_DATA_FAIL,
          payload: err,
        });
        if (err?.body?.errors[904]) {
          dispatch({
            type: approvalPackageTypes.UNASSIGN_APPROVAL_PACKAGE,
            payload: true,
          });
        }
      }
    };
  },

  unassignApprovalPackage(
    assignmentId: string,
    formFieldId: string,
    goToApprovalPackagePage: boolean = true
  ) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        await ApiRepository.unassignApprovalPackage(assignmentId, formFieldId);
        dispatch({
          type: approvalPackageTypes.UNASSIGN_APPROVAL_PACKAGE,
          payload: goToApprovalPackagePage,
        });
      } catch (err: any) {
        dispatch({
          type: approvalPackageTypes.SET_APPROVAL_PACKAGE_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setLoading(loading: boolean) {
    return {
      type: approvalPackageTypes.SET_APPROVAL_PACKAGE_LOADING,
      payload: loading,
    };
  },

  setDefaultValues() {
    return {
      type: approvalPackageTypes.SET_DEFAULT_VALUES,
    };
  },

  resetError() {
    return {
      type: approvalPackageTypes.SET_APPROVAL_PACKAGE_DATA_FAIL,
      payload: null,
    };
  },
};

export default actions;
