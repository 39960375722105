import React from 'react';

const PeopleGroupSvg: React.FC = () => (
  <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none">
    <rect width="18px" height="18px" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.126 9.99928H4C2.887 9.99928 2 10.8943 2 11.9993V15.7293C2 17.0393 3.874 17.7823 6 17.9593V14.9953C6.00159 14.2005 6.3183 13.4388 6.88068 12.8771C7.44306 12.3155 8.2052 11.9998 9 11.9993H9.354C8.7539 11.4704 8.32615 10.7737 8.126 9.99928ZM15.874 9.99928H20C21.105 9.99928 22 10.8843 22 11.9993V15.7293C22 17.0393 20.126 17.7823 18 17.9593V14.9953C17.9989 14.2003 17.6824 13.4383 17.1199 12.8765C16.5574 12.3148 15.795 11.9993 15 11.9993H14.646C15.2461 11.4704 15.6738 10.7737 15.874 9.99928ZM9.967 5.55328C9.87498 4.94233 9.59657 4.37469 9.16981 3.92792C8.74304 3.48115 8.18875 3.17704 7.58265 3.05714C6.97656 2.93724 6.34823 3.00739 5.7835 3.25802C5.21878 3.50865 4.74521 3.92753 4.4275 4.45743C4.10979 4.98732 3.96343 5.60239 4.00843 6.21859C4.05342 6.83479 4.28758 7.42207 4.67889 7.90019C5.07021 8.37831 5.59961 8.72395 6.19475 8.88988C6.78989 9.05582 7.42175 9.03395 8.004 8.82728C8.03235 8.16118 8.22679 7.51273 8.56962 6.94093C8.91246 6.36913 9.39282 5.89211 9.967 5.55328ZM15.997 8.82728C16.5792 9.03386 17.2109 9.05569 17.806 8.88977C18.401 8.72386 18.9304 8.3783 19.3217 7.90028C19.713 7.42227 19.9471 6.83511 19.9922 6.21901C20.0373 5.60292 19.8911 4.98792 19.5736 4.45802C19.2561 3.92812 18.7828 3.50916 18.2182 3.25836C17.6537 3.00757 17.0255 2.93716 16.4194 3.05677C15.8133 3.17638 15.259 3.48016 14.832 3.92663C14.4051 4.3731 14.1264 4.94048 14.034 5.55128C14.6088 5.88995 15.0895 6.36719 15.4324 6.93945C15.7753 7.5117 15.9694 8.16073 15.997 8.82728Z"
      fill="#5F5F68"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14.9993C7 13.8943 7.887 12.9993 9 12.9993H15C16.105 12.9993 17 13.8843 17 14.9993V18.7293C17 21.7563 7 21.7563 7 18.7293V14.9993Z"
      fill="#5F5F68"
    />
    <path
      d="M12 11.9993C13.6569 11.9993 15 10.6561 15 8.99928C15 7.34242 13.6569 5.99928 12 5.99928C10.3431 5.99928 9 7.34242 9 8.99928C9 10.6561 10.3431 11.9993 12 11.9993Z"
      fill="#5F5F68"
    />
  </svg>
);

export default PeopleGroupSvg;
