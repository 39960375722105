import { LOCALES } from '../locales';

const german = {
  [LOCALES.GERMAN]: {
    all: 'Alle',
    add: 'Hinzufügen',
    address: 'Adresse',
    team: 'Team',
    customer: 'Kunde',
    appointment: 'Geplanter Termin',
    appointmentDate: 'Termindatum',
    approved: 'Genehmigt',
    appointmentType: 'Anlage',
    end: 'Ende',
    progress: 'Fortschritt',
    appointment_status: 'Terminstatus',
    work_order_status: 'Auftragsstatus',
    work_order_type: 'Arbeitsauftragstyp',
    zip: 'PLZ',
    module: 'Module',
    meter_exchange: 'ZT',
    meter_exchange_cabinet: 'ZSS',
    attention_error: 'Achtung Fehler',
    acceptance_criteria: 'Akzeptanzkriterium',
    quality_manager: 'Qualitätsmanager',
    craftsman: 'Monteur',
    submission: 'Einreichung',
    btn_approve: 'Genehmigen',
    btn_reject: 'Ablehnen',
    btn_startNow: 'Jetzt anfangen',
    btn_goBack: 'Geh Zurück',
    btn_escalate: 'Eskalieren',
    btn_confirm: 'Zustimmen',
    btn_deny: 'Überschreiben',
    btn_comment: 'Kommentar',
    btn_reopen: 'Öffnen',
    cancel: 'Stornieren',
    cancel_editing: 'Abbrechen',
    attach_to_comment: 'Zum Kommentar hinzufügen',
    max_five_images_for_comment: 'Du kannst bis zu 5 Bilder einfügen.',
    image_editor_error: 'Fehler beim Laden des Moduls.',
    create_first_form: 'Erstellen Sie Ihr erstes Formular',
    comment_placeholder: 'Max 600 Zeichen',
    collapse_all_sections: 'Alle Abschnitte ausblenden',
    change_order: 'Bestellung ändern',
    copy_form: 'Formular kopieren',
    create_form: 'Formular erstellen',
    save_answer_first:
      'Um die Antwort in der Liste zu sehen, muss sie zuerst gespeichert werden.',
    save_error_message_after_answers:
      'Um die Fehlermeldung einzugeben, müssen zuerst die gültigen Antworten angegeben werden.',
    comment_modal_visible_text:
      'Bitte schreibe dem Monteur warum du seine Arbeit ablehnst und was er machen soll um den Fehler zu korrigieren',
    comment_modal_confirmation_visible_title:
      'Dieser Arbeitsschritt wurde bereits Approved!',
    comment_modal_confirmation_visible_text:
      'Bist du sicher dass du den Arbeitsschritt Rejecten willst? Wenn Ja, dann schreibe bitte eine ausführliche Erklärung für den Monteur damit er versteht warum du den Status änderst.',
    delete_form: 'Formular löschen',
    date_created: 'Datum erstellt',
    date_modified: 'Datum geändert',
    date_updated: 'Datum aktualisiert',
    description: 'Beschreibung',
    description_optional: 'Beschreibung (optional)',
    edit_from: 'Bearbeiten von',
    back_to_homepage: 'Zurück zur Startseite',
    page_not_found: 'Seite nicht gefunden',
    an_error_occured: 'Ein Fehler ist aufgetreten',
    error_notification_description:
      'Wahrscheinlich existiert dieses Formular noch nicht.',
    error_boundary_title: 'Unsere Experten korrigieren den Fehler.',
    error_boundary_text: 'Entschuldigung für vorübergehende Unannehmlichkeiten',
    error_fetch_apointments: 'Fehler beim Laden der Termine.',
    error_fetch_teams: 'Fehler beim Laden der Teams.',
    error_post_appointment_note: 'Fehler beim Laden der Terminnotizen.',
    error_post_appointment_delay_reason:
      'Fehler beim Laden des Verspätungsgrundes.',
    error_fetch_apointment_details: 'Fehler beim Laden der Termindaten.',
    error_fetch_appointment_time_log: 'Fehler beim Laden der Terminzeitdaten.',
    error_fetch_appointment_status_change_log:
      'Fehler beim Laden der Terminstatusdaten.',
    error_fetch_appointment_login_users:
      'Beim Abrufen der Liste der eingecheckten Nutzer ist ein Fehler aufgetreten.',
    error_fetch_appointment_types: 'Fehler beim Laden der Terminart.',
    error_fetch_worktype_groups: 'Fehler beim Laden der Gruppen.',
    error_fetch_worktype_instance: 'Fehler beim Laden der Arbeitsschritte.',
    error_fetch_answers: 'Fehler beim Laden der Antworten',
    error_fetch_form_revisions: 'Fehler beim Laden der Formular Revisionsdaten',
    error_fetch_answer_details:
      'Fehler beim Laden der Antwortdaten für das Feld.',
    error_fetch_approval_comments: 'Fehler beim Laden der Kommentare.',
    error_post_comment: 'Fehler beim Übermitteln der Kommentare.',
    error_post_confirm_approval: 'Fehler beim Zustimmen der Prüfung.',
    error_post_reopen: 'Fehler beim Öffnen des Felds.',
    error_upload_images: 'Fehler beim Hochladen der Bilder.',
    error_report_generation: 'Fehler beim Erstellen der Berichte.',
    error_post_appointment_status:
      'Fehler beim Aktualisieren des Terminstatus.',
    error_close_appointment:
      'Beim Schließen des Termins ist ein Fehler aufgetreten.',
    error_post_approval_conflict:
      'Die Erstprüfung für das Feld wurde bereits gemacht.',
    error_post_metadata:
      'Beim Erstellen neuer Metadaten ist ein Fehler aufgetreten.',

    error_fetch_assignments_details: 'Fehler beim Laden der Zuweisungsdetails.',
    error_empty_queue_item:
      'Es gibt gerade keine verfügbaren Abnahmepakete für dich zu prüfen. Bitte versuche es später nochmal.',
    error_fetch_approval_package: 'Fehler beim Laden der Abnahmepakete.',
    error_fetch_next_approval_package:
      'Fehler beim Laden der nächsten Abnahmepakete.',
    error_fetch_approval_package_count:
      'Fehler beim Laden der Anzahl der Abnahmepakete.',
    error_unassign_approval_package:
      'Fehler beim Zurücknehmen der Zuweisung der Abnahmepakete.',
    error_fetch_additional_infos: 'Fehler beim Laden der Zusatzinfos.',
    error_fetch_assigned_approval_package:
      'Fehler beim Laden der zugewiesenen Abnahmepakete.',
    error_fetch_user_approval_package_history:
      'Fehler beim Laden der Historie des überprüften Annahmepakets.',
    error_get_appointment_caseid:
      'Beim Abrufen von Terminen nach Fall-ID ist ein Fehler aufgetreten',
    error_fetch_entity_status:
      'Beim Abrufen der Entitätsstatuslisten ist ein Fehler aufgetreten.',
    error_update_entity_status:
      'Beim Aktualisieren des Entitätsstatus ist ein Fehler aufgetreten.',
    error_invalid_snapshot_version:
      'Für dieses Feld gibt es neue Einträge. Bitte lade die Seite erneut, damit du den aktuellsten Eintrag prüfen kannst.',

    expand_all_sections: 'Erweitern Sie alle Abschnitte',
    field_name: 'Prüfpunkt',
    field_value: 'Wert',
    form_builder: 'Formular-Generator',
    generate_report: 'Bericht generieren',
    report_title: 'Bericht',
    generate_report_success:
      'Der Bericht wird generiert und wird in Kürze als Download zur Verfügung stehen',
    got_it: 'Habe es',
    include_archived: 'Archivierte einschließen',
    index_title: 'Index titel*',
    name: 'Name',
    open: 'Offen',
    wo_dashboard: 'Arbeitsauftrags Dashboard',
    workorders: 'Arbeitsaufträge',
    workorder: 'Arbeitsauftrag',
    'installation-progress': 'Baufortschritt',
    'approval-packages': 'Abnahmepakete',
    question_appointment_take_place: 'Hat der Termin stattgefunden?*',
    re_submitted: 'Wieder Eingereicht',
    in_review: 'In Prüfung',
    two_review: '2. Prüfung',
    rejected: 'Abgelehnt',
    roof_inside: 'Dach Innen',
    roof_shape: 'Dachform?',
    roof_shape_description:
      'abhängig von "Hat der Termin stattgefunden?" Wert ist "Ja"',
    save: 'Speichern',
    save_changes: 'Änderungen speichern',
    search: 'Suche',
    clear_filters: 'Filter entfernen',
    subsection: 'Unterabschnitt',
    submitted: 'Eingereicht',
    status: 'Status',
    total: 'Gesamt',
    today: 'Heute',
    tomorrow: 'Morgen',
    work_types: 'Kapitel',
    work_type: 'Arbeitstyp*',
    warning: 'Warnung',
    stopped: 'Gestoppt',
    active: 'Aktiv',
    closed: 'Geschlossen',
    case_id: 'Order number',
    customer_name: 'Kundenname',
    enter_customer_name_or_case_id: 'Kundenname oder Order number eingeben',
    escalate_notify: 'Information und Link in Zwischenablage kopiert',
    close_appointment: 'Termin schließen',
    close_appointment_message:
      'Sind Sie sicher, dass Sie den Termin schließen wollen?',
    close_appointment_confirm: 'Ja, schließen',
    close_appointment_pending_forms_message:
      'Achtung, es gibt noch offene oder nicht freigegebene Prüfpunkte. Möchtest du den Termin wirklich schließen?',
    reopen_appointment: 'Termin wiedereröffnen',
    reopen_appointment_message:
      'Sind Sie sicher, dass Sie den Termin wieder öffnen wollen?',
    appointment_details: 'Termindetails',
    no_submissions: 'Bisher noch nichts eingereicht',
    montage_documents: 'Montage Dokumente',
    reset_selection: 'Auswahl zurücksetzen',
    select_teams: 'Teams auswählen',
    preview_comments: 'Vorschau',
    additional_comments: 'Zusätzliche Kommentare',
    leave_a_comment: 'Bitte gib einen Kommentar ein.',
    two_eyes_info: 'Prüfpunkt wird ohne Zweitprüfung freigegeben',
    approver_label: 'Zweite Prüfung',
    approver_desc:
      'Klick auf "Zustimmen", wenn du mit der ersten Entscheidung einverstanden bist. Klick auf "Überschreiben" wenn die erste Entscheidung falsch ist. Deine Entscheidung wird NICHT erneut geprüft und muss final sein. Klick auf “Escalate”, wenn du an einen Vorgesetzten eskalieren möchtest.',
    approver_desc_2:
      'Dieser Artikel wird zur zweiten Prüfung geprüft. Du kannst keine Änderung vornehmen, bis der Artikel von einem anderen QM gelöst wurde.',
    deny_modal_title: 'Überschreiben bestätigen',
    deny_modal_text: 'Bist du sicher?',
    deny_modal_desc:
      'Die Genehmigung oder Ablehnung die du jetzt im Anschluss sendest ist die endgültige Entscheidung und wird OHNE weitere QM Prüfung an den Monteur übermittelt.',
    reopen_modal_title: 'Wiedereröffnung bestätigen',
    reopen_modal_desc:
      'Wenn du wieder eröffnest, muss für die Einreichung das 4 Augen Prinzip nochmal durchlaufen werden. Bitte beachten: Die Aktion kann nicht rückgängig gemacht werden.',
    reopen_modal_2_desc:
      'Wenn du wieder eröffnest, muss der Prüfpunkt nochmal neu geprüft werden. Die Aktion kann nicht rückgängig gemacht werden.',
    edit_images: 'Bilder bearbeiten',
    close: 'Schließen',
    edit_images_confirm_close_message:
      'Sind Sie sicher, dass Sie den Bearbeitungsmodus verlassen wollen? Alle Änderungen gehen dann verloren.',
    images_selected: 'Bilder ausgwählt',
    submit_selection: 'Auswahl senden',
    image_preview: 'Bildvorschau',
    select_image: 'Bild auswählen',
    remove_image_from_selection: 'Bild aus der Auswahl entfernen',
    error: 'Fehler',
    error_message_required_with_choice:
      'Wenn eine gültige Antwort oder ein Gültigkeitsbereich ausgewählt wurde, muss auch eine Fehlermeldung angegeben werden. Ohne die gewählte richtige Antwort sollte sie leer sein.',
    update_images: 'Bilder anpassen',
    loading_selected_images: 'Ausgewählte Bilder werden übermittelt',
    error_generic_post: 'Bei der Übertragung ist ein Fehler aufgetreten',
    zoom: 'Vergrößerung',
    rotation: 'Drehung',
    apply_changes_and_select_image: 'Änderungen übernehmen und Bild auswählen',
    apply_changes: 'Änderungen übernehmen',
    questions: 'Fragen',
    include_auto_approval: 'Automatisch genehmigte Prüfpunkte mit anzeigen',
    cancel_reviewing: 'Prüfung abbrechen',
    assign_next_package: 'Nächstes Paket zuweisen',
    review: 'Prüfen',
    review_completed: 'Prüfung abgeschlossen',
    select_all: 'Alle auswählen',
    select_images: 'Bilder auswählen',
    approval_package_welcome:
      'Hi! Es gibt Abnahmepakete zu prüfen. Wähle Erst- oder Zweitprüfungen um zu starten!',
    reviewed_packages: 'Geprüfte Pakete',
    open_review_history: 'Verlauf öffnen',
    history: 'Verlauf',
    latest: 'Zuletzt',
    assign_first_queue: 'Pakete - Erstprüfung',
    first_queue: 'Erstprüfung',
    start_first_queue: 'Erstprüfung starten',
    assign_second_queue: 'Pakete - Zweitprüfung',
    second_queue: 'Zweitprüfung',
    start_second_queue: 'Zweitprüfung starten',
    skipped: 'Übersprungen',
    inReview: 'In Bearbeitung',
    packages_assigned_to_me: 'Mir zugewiesene Pakete',
    'show-skipped-packages': 'Übersprungene Pakete anzeigen',
    all_todays_packages: 'Das sind alle Deine Pakete von heute.',
    no_reviewed_packages_in_history: 'Du hast heute noch keine Pakete geprüft.',
    additional_infos: 'Zusatzinfos',
    incomplete_pakage_note:
      'Dir ist bereits ein Abnahmepaket zugewiesen. Bitte mache dort weiter.',
    your_assigned_package: 'Dir zugewiesenes Paket',
    continue_package: 'Prüfung fortsetzen',
    package_completed_title: 'Prüfung abgeschlossen!',
    package_completed_message:
      'Klasse! Du kannst mit dem nächsten Paket weitermachen.',
    on_cancel_package_review:
      'Du hast die Prüfung des Paket noch nicht abgeschlossen. Bist du sicher, dass du die Prüfung abbrechen möchtest?',
    skip_review: 'Ja, Prüfung abbrechen',
    keep_reviewing: 'Weiter prüfen',
    note: 'Notiz',
    notes: 'Notizen',
    show_all_notes: 'Alle Kommentare anzeigen',
    add_a_note: 'Kommentar hinzufügen',
    edited: 'bearbeitet',
    add_new_note: 'Notiz hinzufügen',
    filter_by_sat: 'Gewerk auswählen',
    work_items: 'Gewerke',
    started: 'Gestartet',
    paused: 'Beendet',

    follow_up: 'Folgetermin',
    at_risk: 'Termin in Gefahr',
    at_risk_tooltip:
      'Termin in Gefahr. Überschneidung mit vorherigem Termin oder geplantes Ende nach Freitag 12 Uhr.',
    parallel_appointment_tooltip:
      'Der Termin hat einen Paralleltermin, der zur gleichen Zeit stattfindet.',
    last_appointment_tooltip: 'Abschließender Installationstermin',
    banner_text:
      'Geplante Downtime wegen Migration zu Salesforce: CraftOS ist von Di 9.5. 20 Uhr - Mi 10.5. 6 Uhr nicht verfügbar!',
    enter_key: 'Bitte einen key eingeben',
    enter_description: 'Bitte geben Sie eine Beschreibung ein',
    multiple_rejection:
      'Dieser Prüfpunkt wurde {timesRejected, number}-mal abgelehnt',

    //Detail drawer
    appointments: 'Termine',
    all_appointments: 'Alle Termine',
    appointment_info: 'Termininfo',
    current_appointment: 'Aktueller Termin',
    time_remaining: 'Restarbeitszeit',
    change_start_end: 'Start / Ende anpassen',
    change_team: 'Team anpassen',
    open_in_drawer: 'Details ansehen',
    time_change_log: 'Zeitverlauf',
    status_change_log: 'Statusverlauf',
    work_orders_details: 'Details zu Arbeitsaufträgen',
    change_appointment_time: 'Terminzeit Ändern',
    appointment_time: 'Terminzeit',
    appointment_time_log: 'Veränderungen',
    time_log_error: 'Die Termin Änderungshistorie konnte nicht geladen werden.',
    check_time_log_input: 'Bitte gib mindestens eine Zeitänderung an.',
    check_time_log_end_start_input:
      'Das Enddatum muss nach dem Startdatum liegen.',
    save_new_time_log_error:
      'Die Änderungen konnten nicht gespeichert werden. Bitte versuche es nochmal!',
    save_note: 'Notiz speichern',
    customer_information: 'Kundeninformation',
    customer_search: 'Kundensuche',
    customer_name_order_number: 'Kundenname, Order Number',
    phone_number: 'Telefonnummer',
    confirm_status_change: 'Möchten Sie den Status wirklich ändern?',
    confirm_delete_field: 'Möchtest du folgendes Feld sicher löschen:',
    yes: 'Ja',
    no: 'Nein',
    parallel_appointment: 'Paralleltermin',
    checkin_history: 'Check-in Historie',

    //Service Appointment status
    none: 'None',
    scheduled: 'Scheduled',
    dispatched: 'Dispatched',
    in_progress: 'In Progress',
    cannotComplete: 'Cannot Complete',
    completed: 'Completed',
    cancelled: 'Cancelled',

    //Appointment Type Editor
    provide_appointment_type_name: 'Bitte gib den Namen für den Termintyp an',
    provide_external_id: 'Bitte gib die externe Id an (Salesforce Worktype)',
    provide_file_label: 'Bitte gib einen Dateinamen an',
    provide_group_name: 'Bitte gib einen Gruppennamen an',
    //SF Appoinbtment Type
    acdc: 'AC/DC',
    vorbau: 'Vorbau',
    fertigstellung: 'Fertigstellung',
    dc_acm_ac: 'DC/ACM/AC',
    dc_acm_ac_backlog: 'DC/ACM/AC Backlog',
    acm: 'ACM',
    acm_backlog: 'ACM Backlog',
    dc_acm: 'DC/ACM',
    dc_acm_backlog: 'DC/ACM Backlog',
    dc: 'DC',
    dc_backlog: 'DC Backlog',
    ac: 'AC',
    ac_backlog: 'AC Backlog',
    clarification_IA: 'Klärfall IA',
    clarification: 'Klärfall',

    ac_healing: 'AC Heilung',
    //Work Order status
    wo_status: 'Auftragsstatus',
    new: 'New',
    in_progress_WO: 'In Progress',
    technical_cancellation: 'Tech Cancel',
    technical_cancellation_tooltip: 'Technical Cancellation',
    customer_cancellation: 'Customer Cancel',
    customer_cancellation_tooltip: 'Customer Cancellation',
    backlog: 'Backlog',
    wo_backlog: 'Backlog',
    installation_preparation: 'Preparation',
    installation_preparation_tooltip: 'Installation Preparation',
    completed_WO: 'Completed',
    completed_app: 'Completed',
    cancelled_WO: 'Cancelled',
    on_hold: 'On Hold',
    //WorkOrder Info Page
    job_details: 'Job details',
    work_order: 'Arbeitsauftrag',
    ac_healing_necessary: 'AC Heilung notwendig?',
    //Saving Filters
    manage_filters: 'Filter verwalten',
    create_new_preset: 'Neuen Filter erstellen',
    saved_filters: 'Gespeicherte Filter',
    no_saved_filters: 'Noch keine Filter gespeichert',
    no_selected_filters: 'Keine Filter ausgewählt',
    no_filter_name_error: 'Bitte gib einen Namen für diesen Filter ein.',
    filter_name: 'Filtername',
    title: 'Titel',
    your_filters: 'Deine Filter',
    filter_group: 'Filtergruppe',
    branch: 'Niederlassung',
    team_selection: 'Teamauswahl',
    project_leads: 'Projektleiter',
    filter_presets: 'Benutzerdefinierte Filter',
    saved_presets: 'Gespeicherte Filter',
    error_invalid_filter: 'Dieser Filter ist nicht mehr gültig.',
    remarks: 'Hinweise',
    //QM statuses
    uk_status: 'UK Status',
    dc_status: 'DC Status',
    ac_status: 'AC Status',
    acm_status: 'AC-M Status',
    uk_open: 'UK Offen',
    uk_submitted: 'UK Eingereicht',
    uk_approved: 'UK Genehmigt',
    uk_rejected: 'UK Abgelehnt',
    dc_open: 'DC Offen',
    dc_submitted: 'DC Eingereicht',
    dc_approved: 'DC Genehmigt',
    dc_rejected: 'DC Abgelehnt',
    ac_open: 'Offen',
    ac_submitted: 'Eingereicht',
    ac_approved: 'Genehmigt',
    ac_rejected: 'Abgelehnt',
    acm_open: 'Offen',
    acm_submitted: 'Eingereicht',
    acm_approved: 'Genehmigt',
    acm_rejected: 'Abgelehnt',
    acm_video_status: 'ACM Abnahme Status',
    acm_video_open: 'ACM Abnahme Offen',
    acm_video_submitted: 'ACM Abnahme Eingereicht',
    acm_video_approved: 'ACM Abnahme Genehmigt',
    acm_video_rejected: 'ACM Abnahme Abgelehnt',

    week: 'Woche',
    done: 'Fertig',
    scheduled_appointments: 'Geplante Termine',
    active_appointments: 'Aktive Termine',
    delayed_start_appointments: 'Termine mit verspätetem Start',
    delayed_end_appointments: 'Verspätetes Ende Termine',
    delayed_appointments: 'Verspätete Termine',
    appointments_count: 'Anzahl Termine',
    completion_goal: 'Abschlussziel',
    completion_goal_on: 'Abschlussziel am',
    start_date_modified: 'Terminstart geändert',
    end_date_modified: 'Terminende geändert',
    appointment_type: 'Anlage',
    appointment_type_filter: 'Terminart',
    appointmentTypeFilter: 'Terminart',
    with_meter_exchange: 'Mit ZT',
    with_wallbox: 'Mit Wallbox',
    with_meter_cabinet_exchange: 'Mit ZSS',
    qm_approval: 'QM Freigabe',
    qm_rejection: 'QM Ablehnung',
    question: 'Frage',
    long_wait_time: 'Lange QM Wartezeit',
    punctuality: 'Pünktlichkeit',
    started_late: 'Unpünktlich gestartet',
    overdue: 'Verspätet',
    documents: 'Dokumente',
    view_route: 'Route ansehen',
    checked_in_users: 'Eingecheckte Nutzer',
    //delayed appointments
    delayed_reason_update: 'Verspätung melden',
    choose_delayed_reason: 'Verspätungsgrund eingeben',
    change_delay_reason: 'Verspätungsgrund ändern',
    select_delay_reason: 'Bitte wähle einen Verspätungsgrund',
    cannot_change_status_to_completed:
      'Kann nicht zu “Completed” geändert werden.',
    cannot_change_status_to_in_progress:
      'Kann nicht zu “In Progress” geändert werden.',
    delay_reason_history: 'Verspätungsgrundverlauf',
    status_change_in_progress_message:
      'Der Status wird automatisch auf “In Progress” wechseln, wenn das erste Paket zur QM Prüfung hochgeladen wird.',
    status_change_completed_message:
      'Der Status wird automatisch auf “Completed” wechseln, wenn die folgenden Bedingungen erfüllt sind:',
    status_change_completed_first_condition:
      'Alle Pakete wurden von QM abgenommen',
    status_change_completed_second_condition:
      'Falls der Termin eine Verspätung hat, wurde der Verspätungsgrund angegeben',
    //form builder
    field_type: 'Art des Prüfpunktes',
    display_as: 'Darstellen als',
    formbuilder_field_name: 'Name des Prüfpunktes',
    instruction_text: 'Anleitungstext',
    instruction_image: 'Anleitungsbild',
    instruction_images: 'Anleitungsbilder',
    protocol_text: 'Protokolltext',
    required: 'Pflichtfeld',
    read_only: 'Schreibgeschützt',
    approval_required: 'Freigabe erforderlich',
    approval_type: 'Genehmigungsart',
    depends_on: 'Hängt ab von',
    answer: 'Antwort',
    answers: 'Antworten',
    default: 'Voreingestellt',
    required_answers: 'Benötigte Antworten',
    required_images: 'Benötigte Bilder',
    form_name: 'Formular Name',
    new_form: 'Neues Formular',
    edit_form: 'Formular bearbeiten',
    prefilled_value: 'Vorausgefüllter Wert',
    anyone: 'Jeder',
    write_to: 'Schreibe nach',
    add_dependency: 'Abhängigkeit hinzufügen',
    valid_answers: 'Gültige Antwort',
    error_message: 'Fehlermeldung',
    checkbox: 'Mehrfachauswahl',
    delete: 'Löschen',
    publish: 'Veröffentlichen',
    publish_confirm: 'Möchten Sie dieses Formular veröffentlichen?',
    formbuilder_work_type: 'Abnahmepaket',
    type: 'Typ',
    legal_text: 'Unterschriftstext',
    add_answer: 'Antwort hinzufügen',
    range: 'Bereich',
    published_version: 'Veröffentlichte Version',
    comment_required: 'Erforderliche Anmerkung',
    delete_field: 'Feld löschen',

    choice: 'Auswahl',
    radio: 'Einzelauswahl',
    section: 'Abschnitt',
    input: 'Eingabe',
    other: 'Alles',
    numeric: 'Numerisch',
    instruction: 'Anleitung',
    date: 'Datum',
    signature: 'Unterschrift',

    form_fields: 'Prüfpunkte',
    new_field: 'Neues Feld',
    selected_field: 'Ausgewähltes Feld',
    edit: 'Bearbeiten',
    published: 'Veröffentlicht',
    draft: 'Entwurf',
    revision: 'Ausgabe',
    created: 'Erstellt',
    updated: 'Updated',
    update: 'Update',
    add_new_form: 'Neues Formular hinzufügen',
    page: 'Seite',
    updated_by: 'Geändert von',

    delete_field_check: 'Bist du sicher, dass du das löschen möchtest?',
    delete_field_warning:
      'Dieses Feld ist ein Paket. Wenn du es löscht, löscht du auch alle dazugehörigen Prüfpunkte.',
    answer_field_warning:
      'Speichere die Antwort zuerst, um sie anschließend als default zu nutzen.',
    delete_attachment_warning:
      'Bist du sicher, dass du diesen Anhang löschen möchtest? Die angehängte Datei wird verloren gehen.',
    appointment_is_delayed: 'Dieser Termin hat sich verzögert.',
    choose_cannot_complete_reason:
      'Bitte wählen Sie einen Grund aus der folgenden Liste aus:',
    select_reason: 'Wähle einen Grund',
    appointment_status_update: 'Terminstatus ändern',
    update_appointment_status: 'Terminstatus aktualisieren?',
    workorder_status_update: 'Auftragsstatus ändern',
    update_workorder_status: 'Auftragsstatus aktualisieren?',

    //Form Builder Tooltips:
    field_type_tooltip:
      'Dieses Feld bestimmt die Abfrage und Eingabe Möglichkeit für den Nutzer.',
    field_name_tooltip:
      'Name des Prüfpunktes.(Sichtbar im Formular und in der App)',
    display_as_tooltip: 'Definiert bzw. beschränkt die Eingabe Möglichkeiten.',
    instruction_text_tooltip:
      'Text indem die Nutzer ihre Aufgabe/die Akzeptanzkriterien zu dem jeweiligen Prüfpunkt sehen.',
    instruction_image_tooltip:
      'Bild welches unter dem Text angezeigt wird zur weiteren Erklärung.',
    protocol_text_tooltip:
      'Anzeigename des Prüfpunkts im Protokoll.(Nicht in der App sichtbar)',
    required_tooltip: 'Macht den Prüfpunkt verpflichtend.',
    read_only_tooltip:
      'Nimmt die Möglichkeit den Prüfpunkt zu bearbeiten. (Nur Lesen möglich)',
    approval_required_tooltip:
      'Der Prüfpunkt muss geprüft werden und wird nicht automatisch freigegeben.',
    valid_answers_tooltip:
      'Lehnt den Prüfpunkt bei der falschen Antwort automatisch ohne Zustimmung von QM ab.',
    approval_type_tooltip:
      'Bestimmt die Anzahl der Personen die für die Freigabe notwendig sind.',
    depends_on_tooltip:
      'Lässt ein Prüfpunkt nur auftauchen wenn eine entsprechende Antwort vorher ausgewählt wurde.',
    answers_tooltip: 'Antwortmöglichkeiten',
    default_tooltip: 'Die Antwort ist als Default ausgewählt.',
    required_answers_tooltip: 'Wie viele Antworten kann der Nutzer auswählen.',
    required_images_tooltip:
      'Wie viele Bilder muss der Nutzer für den Prüfpunkt hochladen.',
    error_message_tooltip:
      'Fehlermeldung die der Nutzer bekommt wenn die falsche Antwort ausgewählt wurde. (Automatische Ablehnung)',
    range_tooltip:
      'Automatische Prüfung bei Numeric. Gibt das Intervall vor, in dem die Zahlen-Eingaben liegen dürfen, um gültig zu sein.',
    metadata_tooltip:
      'Die Metadaten werden verwendet um Abhängigkeiten zwischen Prüfpunkte in unterschiedlichen Formularen herzustellen.',
    depend_on_tooltip:
      'Der Prüfpunkt wird nur angezeigt, wenn die Metadaten Variable den eingetragenen Wert hat.',
    default_value_key_tooltip:
      'Hierüber kann der Prüfpunkt vorausgefüllt werden. Die Antwort wird dann als “System Integration” dargestellt. Für diese Option muss der Prüfpunkt immer auf Read-Only eingestellt werden.',
    write_to_tooltip:
      'In dieses Metadaten Feld wird der Wert geschrieben werden.',

    //weekdays
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
  },
};

export default german;
