import React from 'react';

export const MenuFoldRightMTWIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g id="MenuFold">
      <rect
        width="14"
        height="14"
        transform="matrix(-1 0 0 1 14 0)"
        fill="white"
      />
      <path
        id="Vector"
        d="M8.62476 5.90625H1.12475C1.056 5.90625 0.999754 5.85 0.999754 5.78125V4.90625C0.999754 4.8375 1.056 4.78125 1.12475 4.78125H8.62476C8.69351 4.78125 8.74976 4.8375 8.74976 4.90625V5.78125C8.74976 5.85 8.69351 5.90625 8.62476 5.90625ZM8.74976 9.09375C8.74976 9.1625 8.69351 9.21875 8.62476 9.21875H1.12475C1.056 9.21875 0.999754 9.1625 0.999754 9.09375V8.21875C0.999754 8.15 1.056 8.09375 1.12475 8.09375H8.62476C8.69351 8.09375 8.74976 8.15 8.74976 8.21875V9.09375ZM0.874754 1.5H13.1248C13.1935 1.5 13.2498 1.55625 13.2498 1.625V2.5C13.2498 2.56875 13.1935 2.625 13.1248 2.625H0.874754C0.806005 2.625 0.749754 2.56875 0.749754 2.5V1.625C0.749754 1.55625 0.806005 1.5 0.874754 1.5ZM0.874754 11.375H13.1248C13.1935 11.375 13.2498 11.4313 13.2498 11.5V12.375C13.2498 12.4438 13.1935 12.5 13.1248 12.5H0.874754C0.806005 12.5 0.749754 12.4438 0.749754 12.375V11.5C0.749754 11.4313 0.806005 11.375 0.874754 11.375ZM13.1966 7.10781L10.7544 9.03125C10.6638 9.10313 10.5294 9.03906 10.5294 8.92344V5.07656C10.5294 4.96094 10.6623 4.89688 10.7544 4.96875L13.1966 6.89219C13.2131 6.90496 13.2263 6.92131 13.2355 6.93999C13.2446 6.95867 13.2494 6.9792 13.2494 7C13.2494 7.0208 13.2446 7.04133 13.2355 7.06001C13.2263 7.0787 13.2131 7.09505 13.1966 7.10781Z"
        fill="#3E7EFF"
      />
    </g>
  </svg>
);
