import { MailOutlined } from '@ant-design/icons';
import { Image, Popover, Space, Tag, Typography } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { IComment } from '../../constants/types';
import { findStatusTagLabel } from '../../utils/helpers';

import QmIcon from '../icons/qm';
import TechnicianIcon from '../icons/user';

import styles from './comment.module.scss';

const Comment: React.FC<IComment> = ({
  actor,
  timestamp,
  text,
  author,
  approval,
  isReviewComment,
  isHighlighted,
  uploads,
  setSelectedImageUrl,
  isEditable = true,
}) => {
  const { Text, Link } = Typography;
  const intl = useIntl();

  const authorName = author.name
    ? author.name
    : intl.formatMessage({
        id: actor === 'User' ? 'craftsman' : 'quality_manager',
      });
  const authorEmail = author.username || author.email;

  const renderAuthorDetails = (
    <div>
      <Text>{authorName}</Text>
      {authorEmail && (
        <div>
          <Space>
            <Text type="secondary">
              <MailOutlined />
            </Text>
            <Link href={`mailto:${authorEmail}`}>{authorEmail}</Link>
          </Space>
        </div>
      )}
    </div>
  );
  return (
    <div className={isHighlighted ? styles.highlight : styles.content}>
      <div className={styles.wrapper}>
        <Popover content={renderAuthorDetails} placement="left">
          <span style={{ cursor: 'pointer' }}>
            {actor === 'User' ? <TechnicianIcon /> : <QmIcon />}
          </span>
        </Popover>

        <span>{`${intl.formatDate(timestamp || new Date())} ${intl.formatTime(
          timestamp || new Date()
        )}`}</span>
        <span></span>
        {approval && (
          <Tag
            className={`${styles.status} ${
              isReviewComment
                ? styles.statusInReview
                : approval === 'Approved'
                ? styles.statusApproved
                : styles.statusRejected
            }`}
          >
            {findStatusTagLabel(approval ?? '')}
          </Tag>
        )}
      </div>
      <div className={styles.message}>{text}</div>
      <Space style={{ paddingLeft: '38px' }}>
        {uploads &&
          uploads.map((url: any, i: number) => (
            <Image
              key={`comment-attachment-${i}`}
              preview={!isEditable}
              src={url}
              alt="thumbnail image"
              className={styles.commentImage}
              onClick={() => (isEditable ? setSelectedImageUrl(url) : {})}
              style={{ height: '80px', width: '80px' }}
            />
          ))}
      </Space>
    </div>
  );
};

export default Comment;
