import React from 'react';
import ReactPlayer from 'react-player';

interface IVideoPlayer {
  url?: string;
  width?: string;
}

const VideoPlayer: React.FC<IVideoPlayer> = ({ url, width = '100%' }) => {
  if (!url) return null;
  return (
    <>
      <ReactPlayer url={url} controls width={width} />
    </>
  );
};

export default VideoPlayer;
