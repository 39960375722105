import { FC } from 'react';
import { Modal, Form, FormInstance } from 'antd';
import FormGenerator from './FormGenerator';
import styles from '../formBuilderDetail.module.scss';
import { useIntl } from 'react-intl';

interface Props {
  visible: boolean;
  saveLoading: boolean;
  hideModal: () => void;
  handleNewField: (form: FormInstance) => void;
}

const AddNewFieldModal: FC<Props> = ({
  visible,
  saveLoading,
  hideModal,
  handleNewField,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      bodyStyle={{ padding: '24px 16px 24px 40px' }}
      maskClosable={false}
      title={intl.formatMessage({ id: 'new_field' })}
      open={visible}
      onCancel={hideModal}
      onOk={() => handleNewField(form)}
      okButtonProps={{
        loading: saveLoading,
      }}
      cancelButtonProps={{
        disabled: saveLoading,
      }}
    >
      <div className={styles.newFieldContainer}>
        <FormGenerator editMode={true} formInstance={form} isNewField />
      </div>
    </Modal>
  );
};

export default AddNewFieldModal;
