import { changeLanguage } from './constants';

const action = {
  setLanguage(lang: string) {
    return {
      type: changeLanguage.SET_LANGUAGE,
      payload: lang,
    };
  },
};

export default action;
