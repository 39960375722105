export enum TimeLogActions {
  FETCH_TIME_LOG_START = 'FETCH_TIME_LOG_START',
  FETCH_TIME_LOG_SUCCESS = 'FETCH_TIME_LOG_SUCCESS',
  FETCH_TIME_LOG_FAILED = 'FETCH_TIME_LOG_FAILED',
  RESET_STATE = 'RESET_STATE',
}

export interface TimeLogState {
  loadingTimeLog: boolean;
  timeLog: any[];
  error: any;
}

export type TimeLogObject = {
  oldStartDate?: string | null;
  oldEndDate?: string | null;
  newStartDate: string;
  newEndDate: string;
  comment: string | null;
  logDate: string;
  userInfo: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
  };
};

export interface TimeLogReqBody {
  comment: string;
  startDate?: string;
  endDate?: string;
}
