import React from 'react';

const UserApprovalPackageHistoryIcon: React.FC = () => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none">
    <path
      d="M43.5363 7.04248H14.1757C13.3791 7.04248 12.7334 7.68822 12.7334 8.48479V49.5172H38.9624C38.9624 53.9223 42.7292 57.0004 47.1415 56.9777C51.478 56.9559 55.0255 53.2799 55.0255 48.9439V18.4752H47.3698C46.1487 18.4752 43.5363 15.8021 43.5363 14.5815V7.04248Z"
      fill="#E4EBF7"
    />
    <path
      d="M39.0198 49.3882H8.9375C8.9375 53.3233 11.7999 56.1682 15.5553 56.7986L16.8774 56.9092L47.0198 56.8491C42.6352 56.8491 38.9081 53.4819 39.0198 49.3882Z"
      fill="#253368"
    />
    <path
      d="M25.6664 10.8911H19.0991C18.7885 10.8911 18.5366 11.143 18.5366 11.4536V18.0209C18.5366 18.3316 18.7885 18.5834 19.0991 18.5834H25.6664C25.9771 18.5834 26.2289 18.3316 26.2289 18.0209V11.4536C26.2289 11.143 25.9771 10.8911 25.6664 10.8911Z"
      fill="#597EF7"
    />
    <path
      d="M46.8071 22.3696H19.0859C18.7753 22.3696 18.5234 22.6215 18.5234 22.9321V23.7302C18.5234 24.0409 18.7753 24.2927 19.0859 24.2927H46.8071C47.1178 24.2927 47.3696 24.0409 47.3696 23.7302V22.9321C47.3696 22.6215 47.1178 22.3696 46.8071 22.3696Z"
      fill="#597EF7"
    />
    <path
      d="M46.8071 28.1597H19.0859C18.7753 28.1597 18.5234 28.4115 18.5234 28.7222V29.5202C18.5234 29.8309 18.7753 30.0827 19.0859 30.0827H46.8071C47.1178 30.0827 47.3696 29.8309 47.3696 29.5202V28.7222C47.3696 28.4115 47.1178 28.1597 46.8071 28.1597Z"
      fill="#597EF7"
    />
    <path
      d="M46.8071 33.9502H19.0859C18.7753 33.9502 18.5234 34.202 18.5234 34.5127V35.3108C18.5234 35.6214 18.7753 35.8733 19.0859 35.8733H46.8071C47.1178 35.8733 47.3696 35.6214 47.3696 35.3108V34.5127C47.3696 34.202 47.1178 33.9502 46.8071 33.9502Z"
      fill="#597EF7"
    />
    <path
      opacity="0.6"
      d="M43.5322 16.6357V18.1381C43.5322 18.461 43.6666 18.7693 43.9032 18.9889C44.1398 19.2085 44.4573 19.3197 44.7792 19.2956L55.0641 18.5306L43.5322 16.6357Z"
      fill="#CCD7EE"
    />
    <path
      d="M49.4942 12.7673L43.52 7.00586L43.5356 18.0479C43.5356 18.3134 43.7509 18.5287 44.0164 18.5287H55.0645L49.4942 12.7673Z"
      fill="#FAAD14"
    />
  </svg>
);

export default UserApprovalPackageHistoryIcon;
