import React, { useState } from 'react';
import { Button, Checkbox, Row, Col, Typography, Spin } from 'antd';
import { SendSvg } from '../../../components/icons/sendPlainSvg';
import Thumbnail from '../../../components/imageSelector/thumbnail';
import styles from '../formInstance.module.scss';
import { useIntl } from 'react-intl';
import { store } from '../../../store';
import { config } from '../../../config';
import { EditedImage, FetchOptions } from '../../../constants/types';
import ImageEdit from '../../../components/imageSelector/imageEdit';
import translation from '../../../i18n/translation';

const { Text } = Typography;

interface MandatoryImageSelectionProps {
  images: Array<string>;
  appointmentId: string;
  formInstanceId?: string;
  formFieldId?: string;
  externalCaseId?: string;
  setImageSubmitSuccess: any;
  setError: (arg0: string | undefined) => void;
}

const MandatoryImageSelection = ({
  images,
  appointmentId,
  formInstanceId,
  formFieldId,
  externalCaseId,
  setImageSubmitSuccess,
  setError,
}: MandatoryImageSelectionProps) => {
  const intl = useIntl();

  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [editedImages, setEditedImages] = useState<EditedImage[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const [togglePreview, setTogglePreview] = useState(false);
  const [previewImg, setPreviewImg] = useState<number>(-1);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelect = (index: number) => {
    if (checkAll) setCheckAll(false);
    var find = selectedImages.indexOf(index);
    let newImagesArr;
    if (find > -1) {
      newImagesArr = [...selectedImages];
      newImagesArr.splice(find, 1);
    } else {
      newImagesArr = [...selectedImages, index];
    }
    setSelectedImages(newImagesArr);
    if (newImagesArr?.length === images?.length) setCheckAll(true);
  };

  const handleSelectAll = () => {
    checkAll
      ? setSelectedImages([])
      : setSelectedImages(images.map((img, i) => i));
    setCheckAll(!checkAll);
  };

  const handleClose = (isSubmited: boolean) => {
    setSelectedImages([]);
    setEditedImages([]);
    setIsSubmitting(false);
    setError(undefined);
    setImageSubmitSuccess(isSubmited);
  };

  const handlePreview = (index: number) => {
    setTogglePreview(true);
    setPreviewImg(index);
  };

  const handlePreviewSelect = (newImage?: string) => {
    if (newImage) {
      images[previewImg] = newImage;
      let newEditedImages = [
        ...editedImages.filter((img) => img.position !== previewImg),
      ];
      newEditedImages.push({ position: previewImg, newUrl: newImage });
      setEditedImages(newEditedImages);
      if (!selectedImages.includes(previewImg)) {
        handleSelect(previewImg);
      }
    } else {
      handleSelect(previewImg);
    }
    setTogglePreview(false);
  };

  const handlePreviewClose = () => {
    setTogglePreview(false);
    setPreviewImg(-1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (editedImages.length) await handleUpload();

    const payload = {
      formInstanceId: formInstanceId,
      appointmentId: appointmentId,
      formFieldId: formFieldId,
      uploads: [...selectedImages].map((item) => images[item]),
      qmSubmission: true,
    };

    const opt: FetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
      body: JSON.stringify(payload),
    };

    try {
      const resp = await fetch(`${config.apiUrl}/Forms/answers`, opt);
      const result = await resp.json();
      if (resp.status !== 201) {
        setError(
          result?.errors?.Uploads[0] ??
            intl.formatMessage({
              id: 'error_upload_images',
            })
        );
      } else {
        handleClose(true);
      }
    } catch (err: any) {
      setError(
        err ??
          intl.formatMessage({
            id: 'error_upload_images',
          })
      );
    }
  };

  const handleUpload = async () => {
    let formData = new FormData();

    for (const image of editedImages) {
      let blob = await fetch(image.newUrl).then((r) => r.blob());
      var blobUrl = images[image.position].split('/');
      var blobName = `${blobUrl[blobUrl.length - 1]}`.split('.')[0];
      var blobExtention = blob.type.split('/').pop();
      formData.append(`file`, blob, `${blobName}.${blobExtention}`);
    }

    const opt = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
      body: formData,
    };

    try {
      const resp = await fetch(
        `${config.apiUrl}/Files?container=${externalCaseId}`,
        opt
      );
      const result = await resp.json();
      if (resp.status !== 200) {
        setError(
          result?.message ??
            intl.formatMessage({
              id: 'error_upload_images',
            })
        );
      } else {
        result.forEach((file: any) => {
          const blobName = file.fileName
            .slice(file.fileName.indexOf('_'))
            .slice(1)
            .split('.')[0];
          const index = images.findIndex((element: any) =>
            element.includes(blobName)
          );
          images[index] = file.url;
        });
        setEditedImages([]);
      }
    } catch (err: any) {
      setError(
        err ??
          intl.formatMessage({
            id: 'error_upload_images',
          })
      );
    }
  };

  return (
    <div className={styles.selectImagesContainer}>
      <Spin
        spinning={isSubmitting}
        tip={
          <div className={styles.spinnerTip}>
            <Text className={styles.spinnerText}>
              {translation('loading_selected_images')}
            </Text>
          </div>
        }
      >
        <Row className={styles.imageSelectionContainer}>
          <Text strong> {translation('select_images')}</Text>
          <Checkbox
            onChange={handleSelectAll}
            checked={checkAll}
            disabled={isSubmitting}
          >
            {translation('select_all')}
          </Checkbox>
        </Row>
        <Row gutter={[16, 16]}>
          {images?.length &&
            images.map((element: string, index: number) => (
              <Col xs={{ span: 5 }} lg={{ span: 6 }} key={`thumbnail-${index}`}>
                <Thumbnail
                  src={element}
                  isSelected={selectedImages.includes(index)}
                  key={element}
                  onSelect={() => handleSelect(index)}
                  onPreview={() => handlePreview(index)}
                />
              </Col>
            ))}
        </Row>{' '}
      </Spin>
      <Row className={styles.imageSelectionButtonContainer}>
        <Button
          className={styles.approveFieldFinalStep}
          disabled={!selectedImages.length}
          onClick={() => handleSubmit()}
          loading={isSubmitting}
        >
          {!isSubmitting && (
            <span style={{ padding: '0 3px 0 0' }}>
              <SendSvg />
            </span>
          )}
          {translation('submit_selection')}{' '}
        </Button>
      </Row>
      <ImageEdit
        src={images[previewImg]}
        isVisible={togglePreview}
        okText={intl.formatMessage({ id: 'save_changes' })}
        isSelected={selectedImages.includes(previewImg)}
        onSelect={handlePreviewSelect}
        onClose={handlePreviewClose}
      />
    </div>
  );
};

export default MandatoryImageSelection;
