import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { thunkLogin } from '../store/authenticationActions';

const Authentication: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(thunkLogin());
  }, [dispatch]);
  return <> {children} </>;
};

export default Authentication;
