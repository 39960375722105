import { FC, useEffect, useRef, useState } from 'react';
import { Table, TableColumnsType, Button, Input, Space } from 'antd';
import { WorkType } from '../../types';
import translation from '../../../../i18n/translation';
import { LeftSquareTwoTone, SearchOutlined } from '@ant-design/icons';

interface Props {
  availableWorkTypes: any;
  loading?: boolean;
  selectedGroup?: string | undefined;
  handleUpdateWorkTypesList: (arg0: string, arg1: WorkType[]) => void;
  usedWorkTypes?: WorkType[] | [] | undefined;
}

const AllWorkTypesTable: FC<Props> = ({
  availableWorkTypes,
  handleUpdateWorkTypesList,
  loading,
  usedWorkTypes,
}) => {
  const [workTypes, setWorkTypes] = useState([]);

  useEffect(() => {
    if (availableWorkTypes !== undefined) {
      let indexedFormsObj: any = {};

      if (
        availableWorkTypes?.length > 0 &&
        (usedWorkTypes?.length === 0 || !usedWorkTypes?.length)
      ) {
        setWorkTypes(availableWorkTypes);
      } else if (
        availableWorkTypes?.length > 0 &&
        !!usedWorkTypes?.length &&
        usedWorkTypes?.length > 0
      ) {
        usedWorkTypes.forEach((item: any) => {
          if (!indexedFormsObj[item.id]) {
            indexedFormsObj[item.id] = {
              ...item,
            };
          }
        });
        //set them to filtered available
        setWorkTypes(
          availableWorkTypes.filter(
            (form: WorkType) => !indexedFormsObj[form.id]
          )
        );
      }
    }
  }, [availableWorkTypes, usedWorkTypes]);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              (handleReset(clearFilters),
              handleSearch(selectedKeys, confirm, dataIndex))
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
          marginRight: '8px',
          fontSize: '16px',
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text: any) => text,
  });

  const columns: TableColumnsType<WorkType> = [
    {
      title: 'Move',
      width: '70px',
      key: 'operation',
      render: (value, record) => (
        <Button
          type="link"
          style={{ width: '100%', fontSize: '20px', height: 'auto' }}
          onClick={() => {
            handleUpdateWorkTypesList('add-new-form', [record]);
          }}
        >
          <LeftSquareTwoTone />
        </Button>
      ),
    },
    {
      title: translation('name'),
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
  ];
  if (!workTypes) {
    return null;
  }
  return (
    <Table
      size="small"
      showHeader
      sticky
      columns={columns}
      dataSource={workTypes}
      rowKey={(record) => record.id}
      pagination={false}
      scroll={{
        y: 300,
      }}
      loading={loading}
    />
  );
};

export default AllWorkTypesTable;
