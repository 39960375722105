export const ChecksSvg = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M7 12.5L12 17.5L22 7.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.5L17 7.5M2 12.5L7 17.5L2 12.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
