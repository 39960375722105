import React from 'react';

export const InstallationPLCaseMTWIcon: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g id="Frame" clipPath="url(#clip0_5371_166011)">
      <path
        id="Vector"
        d="M3.33333 3.16699H6L8 5.16699H12.6667C13.0203 5.16699 13.3594 5.30747 13.6095 5.55752C13.8595 5.80756 14 6.1467 14 6.50033V11.8337C14 12.1873 13.8595 12.5264 13.6095 12.7765C13.3594 13.0265 13.0203 13.167 12.6667 13.167H3.33333C2.97971 13.167 2.64057 13.0265 2.39052 12.7765C2.14048 12.5264 2 12.1873 2 11.8337V4.50033C2 4.1467 2.14048 3.80756 2.39052 3.55752C2.64057 3.30747 2.97971 3.16699 3.33333 3.16699Z"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5371_166011">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
