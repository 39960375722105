import { FC } from 'react';
import { IMetaData } from '../../../constants/types';
import { Table } from 'antd';

interface Props {
  list: IMetaData[];
  loading: boolean;
}

const columns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    filters: [
      {
        text: 'SF',
        value: 'SF',
      },
      {
        text: 'WT',
        value: 'WT',
      },
      {
        text: 'AT',
        value: 'AT',
      },
    ],
    onFilter: (value: any, record: any) => record.key.indexOf(value) === 0,
    sorter: (a: any, b: any) => a.key.localeCompare(b.key),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

const MetaDataTable: FC<Props> = ({ list, loading }) => (
  <Table
    sticky
    loading={loading}
    size="small"
    dataSource={list}
    columns={columns}
    scroll={{
      scrollToFirstRowOnChange: true,
      y: 'calc(100vh - 300px)',
    }}
    pagination={false}
  />
);

export default MetaDataTable;
