import { AnyAction } from 'redux';
import ApiRepository from '../../../services/api/apiRepository';
import { CheckedInUsersActions } from './types';

const actions = {
  fetchCheckedInUsersAsync(id: string, onlyActive = true) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      dispatch({
        type: CheckedInUsersActions.FETCH_CHECKED_IN_USERS_START,
      });
      try {
        dispatch({
          type: CheckedInUsersActions.FETCH_CHECKED_IN_USERS_SUCCESS,
          payload: await ApiRepository.getCheckedInUsersByExternalId(
            id,
            onlyActive
          ),
        });
      } catch (error: any) {
        dispatch({
          type: CheckedInUsersActions.FETCH_CHECKED_IN_USERS_FAILED,
          payload: error,
        });
      }
    };
  },

  resetState() {
    return {
      type: CheckedInUsersActions.RESET_STATE,
    };
  },
};

export default actions;
