export enum DelayReasonLogActions {
  FETCH_DELAY_REASON_LOG_START = 'FETCH_DELAY_REASON_LOG_START',
  FETCH_DELAY_REASON_LOG_SUCCESS = 'FETCH_DELAY_REASON_LOG_SUCCESS',
  FETCH_DELAY_REASON_LOG_FAILED = 'FETCH_DELAY_REASON_LOG_FAILED',
  RESET_STATE = 'RESET_STATE',
}

export interface DelayReasonLogState {
  loadingDelayReasonLog: boolean;
  delayReasonLog: DelayReasonChangesObject[];
  error: any;
}

export type DelayReasonResponseObject = {
  id: string;
  collection: string;
  documentId: string;
  changes: DelayReasonChangesObject[];
};

export type DelayReasonChangesObject = {
  field: string;
  previousValue: string;
  newValue: string;
  timestamp: string;
  updatedBy: { name: string; email: string };
};
