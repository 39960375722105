import { AnyAction } from 'redux';
import ApiRepository from '../../services/api/apiRepository';
import { AppointmentTypesActionsTypes } from './types';

const actions = {
  fetchAppointmentTypesAndGroupsAsync() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type:
            AppointmentTypesActionsTypes.FETCH_APPOINTMENT_TYPES_GROUPS_SUCCESS,
          payload: await refreshAllAppointmentTypesData(),
        });
      } catch (error: any) {
        dispatch({
          type: AppointmentTypesActionsTypes.FETCH_APPOINTMENT_TYPES_FAILED,
          payload: error,
        });
      }
    };
  },

  setLoading(loading: boolean) {
    return {
      type: AppointmentTypesActionsTypes.SET_LOADING,
      payload: loading,
    };
  },

  fetchSelectedAppointmentTypeAsync(id: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const {
          groups,
          appointmentTypes,
        } = await refreshAllAppointmentTypesData();
        dispatch({
          type:
            AppointmentTypesActionsTypes.FETCH_SELECTED_APPOINTMENT_TYPE_GROUP_SUCCESS,
          payload: { id, groups, appointmentTypes },
        });
      } catch (error) {
        dispatch({
          type: AppointmentTypesActionsTypes.FETCH_APPOINTMENT_TYPES_FAILED,
          payload: error,
        });
      }
    };
  },

  fetchAllWorkTypesAsync() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const workTypesResponse = await ApiRepository.getAllWorkTypes();
        dispatch({
          type: AppointmentTypesActionsTypes.FETCH_ALL_WORK_TYPES_SUCCESS,
          payload: workTypesResponse,
        });
      } catch (error) {
        dispatch({
          type: AppointmentTypesActionsTypes.FETCH_ALL_WORK_TYPES_FAILED,
          payload: error,
        });
      }
    };
  },

  resetState() {
    return {
      type: AppointmentTypesActionsTypes.RESET_APPOINTMENT_TYPES_GROUPS,
    };
  },
};

const refreshAllAppointmentTypesData = async () => {
  //actions.resetState();
  const groupsDataRequest = ApiRepository.getWorktypeGroupsData();
  const appointmentTypesDataRequest = ApiRepository.getAppointmentTypesData();

  const groupsResponse = await groupsDataRequest;
  const appointmentTypesResponse = await appointmentTypesDataRequest;

  return {
    groups: groupsResponse?.response,
    appointmentTypes: appointmentTypesResponse?.response,
  };
};

export default actions;
