import { AnyAction } from 'redux';
import { AppInfoBannerState, appInfoBannerTypes } from '../../constants/types';

const initialState: AppInfoBannerState = {
  text: '',
};

export const appInfoBannerReducer = (
  state = initialState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case appInfoBannerTypes.GET_APP_INFO_DATA:
      const { response } = payload;
      return {
        ...state,
        text: response,
        error: null,
      };
    default:
      return state;
  }
};
