import { AnyAction } from 'redux';
import { WorkOrderState, WorkOrderActionTypes } from './types';

const initialState: WorkOrderState = {
  loading: false,
  error: null,
  workOrder: {},
};

export const workOrderReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case WorkOrderActionTypes.GET_WORKORDER_DATA:
      let { response } = payload;
      return {
        ...state,
        workOrder: {
          ...response,
          appointments: response?.appointments.reverse(),
        },
        error: null,
        loading: false,
      };

    case WorkOrderActionTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case WorkOrderActionTypes.GET_WORKORDER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
