import React from 'react';

export const WorkOrderMTWIcon: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g id="Frame" clipPath="url(#clip0_5371_165990)">
      <path
        id="Vector"
        d="M2.66663 5.16699V4.50033C2.66663 4.1467 2.8071 3.80756 3.05715 3.55752C3.3072 3.30747 3.64634 3.16699 3.99996 3.16699H5.33329"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M2.66663 11.833V12.4997C2.66663 12.8533 2.8071 13.1924 3.05715 13.4425C3.3072 13.6925 3.64634 13.833 3.99996 13.833H5.33329"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M10.6666 3.16699H12C12.3536 3.16699 12.6927 3.30747 12.9428 3.55752C13.1928 3.80756 13.3333 4.1467 13.3333 4.50033V5.16699"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M10.6666 13.833H12C12.3536 13.833 12.6927 13.6925 12.9428 13.4425C13.1928 13.1924 13.3333 12.8533 13.3333 12.4997V11.833"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M3.33337 7.83301H4.00004V9.16634H3.33337V7.83301Z"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M6.66663 7.83301V9.16634"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M9.33337 7.83301H10V9.16634H9.33337V7.83301Z"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_8"
        d="M12.6666 7.83301V9.16634"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5371_165990">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
