import { createRoot } from 'react-dom/client';
import './global.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';

// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

moment.updateLocale('en-gb', {
  week: {
    dow: 1,
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
