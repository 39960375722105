import React from 'react';

const ParallelAppointmentSvg: React.FC = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <rect x="0.5" width="16" height="16" rx="3" fill="#FAAD14" />
    <path
      d="M4 5.5H4.974C5.9305 5.5 6.8265 5.9665 7.375 6.75C7.64522 7.13604 8.00456 7.45123 8.42253 7.66883C8.8405 7.88644 9.30478 8.00004 9.776 8H13"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10.5H4.975C5.44594 10.5 5.90992 10.3864 6.32757 10.1688C6.74521 9.95115 7.10418 9.63598 7.374 9.25C7.64382 8.86402 8.00279 8.54885 8.42043 8.33123C8.83808 8.11362 9.30206 7.99999 9.773 8H12.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 9.5L13 8L11.5 6.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ParallelAppointmentSvg;
