import { Breadcrumb, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import translation from '../../i18n/translation';
import { appointmentsDetails } from '../../router/routes';

interface BreadcrumNavigationProps {
  page: string;
  appointmentId?: string;
  customerName?: string;
  worktypeName?: string;
  workorderId?: string;
}

interface BreadcrumbItem {
  onClick?: () => void;
  title: string | React.ReactNode;
}

type BreadcrumbItems = BreadcrumbItem[];

const { Link, Text } = Typography;

const BreadcrumNavigation = ({
  page,
  appointmentId,
  customerName,
  worktypeName,
  workorderId,
}: BreadcrumNavigationProps) => {
  const navigate = useNavigate();

  const goToPage = () => {
    navigate({
      pathname: `/${page}`,
      search: localStorage.getItem(`/${page}-SearchParams`) ?? '',
    });
  };

  const gotToAppointmentDetailsPage = () => {
    navigate({
      pathname: `${appointmentsDetails.pathWithParams(page, appointmentId!)}`,
      search:
        localStorage.getItem(`${appointmentsDetails.path}-SearchParams`) ?? '',
    });
  };

  const breadcrumbItems: BreadcrumbItems = [
    {
      onClick: goToPage,
      title: translation(page),
    },
  ];

  if (worktypeName) {
    breadcrumbItems.push({
      onClick: gotToAppointmentDetailsPage,
      title: customerName,
    });
    breadcrumbItems.push({
      title: worktypeName,
    });
  } else if (customerName) {
    breadcrumbItems.push({
      title: customerName,
    });
  } else if (workorderId) {
    breadcrumbItems.push({
      title: translation('workorder'),
    });
  }

  const itemRender = (route: any) =>
    route.onClick ? (
      <Link onClick={route.onClick}>{route.title}</Link>
    ) : (
      <Text>{route.title}</Text>
    );

  return <Breadcrumb items={breadcrumbItems} itemRender={itemRender} />;
};

export default BreadcrumNavigation;
