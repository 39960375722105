export const PhotoSvg = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M15.5 8.5H15.51"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 4.5H7.5C5.84315 4.5 4.5 5.84315 4.5 7.5V17.5C4.5 19.1569 5.84315 20.5 7.5 20.5H17.5C19.1569 20.5 20.5 19.1569 20.5 17.5V7.5C20.5 5.84315 19.1569 4.5 17.5 4.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 15.5L8.5 11.5C8.95606 11.0611 9.47339 10.8301 10 10.8301C10.5266 10.8301 11.0439 11.0611 11.5 11.5L16.5 16.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 14.5L15.5 13.5C15.9561 13.0611 16.4734 12.8301 17 12.8301C17.5266 12.8301 18.0439 13.0611 18.5 13.5L20.5 15.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
