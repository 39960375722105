import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Typography, Space, Button, Popover, Spin } from 'antd';
import TimeLogActions from './actions';
import styles from './timeLog.module.scss';
import translation from '../../../i18n/translation';
import { IAppointment } from '../../../constants/types';
import { SwapRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { TimeLogReqBody, TimeLogState } from './types';
import ApiRepository from '../../../services/api/apiRepository';
import { IFeatures } from '../../../store/rolesAndPrivileges';
import AccessControl from '../../../components/accessControl';
import ChangeLog from '../detail-drawer/components/changeLog';
import { showNotificationMessage } from '../../../utils/notification';

const { Text } = Typography;

interface Props extends PropsWithChildren {
  selectedField: IAppointment;
  setSelectedAppointment: (arg0: IAppointment) => void;
  handleUpdateTableData: (reloadCache?: boolean) => void;
  hasWriteAccess: boolean;
}

const TimeLogPopover: FC<Props> = ({
  setSelectedAppointment,
  handleUpdateTableData,
  selectedField,
  children,
  hasWriteAccess,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const timeLogState: TimeLogState = useSelector(
    (state: RootState) => state.timeLogData
  );

  const [isVisible, setIsVisible] = useState(false);
  const [isTimeChanged, setIsTimeChanged] = useState(false);

  const handleClickChange = (visible: boolean) => {
    setIsVisible(visible);
  };

  const handleButtonClick = (event: any) => {
    proceedToDrawer(event);
    setIsVisible(false);
  };

  useEffect(() => {
    if (selectedField && isVisible) {
      setIsTimeChanged(false);
      const { externalId } = selectedField;
      if (externalId) {
        dispatch(TimeLogActions.fetchTimeLogAsync(externalId));
      }
    }
  }, [dispatch, selectedField, isVisible]);

  const proceedToDrawer = (event: MouseEvent) => {
    if (!selectedField.isGroupHeader) {
      setSelectedAppointment(selectedField);
      event.preventDefault();
      event.stopPropagation();
    }
  };
  useEffect(() => {
    if (isTimeChanged) handleUpdateTableData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeChanged]);

  const handleSubmitTimeChangeLog = async (timeLog: TimeLogReqBody) => {
    if (!!selectedField?.externalId) {
      try {
        await ApiRepository.createNewTimeLog(selectedField.externalId, timeLog);
        dispatch(TimeLogActions.fetchTimeLogAsync(selectedField.externalId));
        setIsTimeChanged(true);
        return {
          error: null,
          loading: false,
        };
      } catch (error: any) {
        showNotificationMessage({
          message: intl.formatMessage({
            id: error.errorKey ?? 'error_fetch_apointments',
          }),
          error: error,
        });
        return {
          error: intl.formatMessage({ id: 'save_new_time_log_error' }),
          loading: false,
        };
      }
    }
  };

  const showScheduledTimeLog = (i: number | string, log: any) => {
    return (
      <Space
        size={4}
        direction="vertical"
        style={{ minWidth: '250px', width: '100%' }}
        key={i}
      >
        <Space
          size={4}
          direction="vertical"
          className={
            i === 'init' ? styles.initialDateContainer : styles.dateContainer
          }
        >
          <Text
            className={styles.sectionHeader}
            type={i !== 'init' ? 'secondary' : undefined}
          >
            {translation('scheduled')}
          </Text>
          <Space>
            <Text strong type={i !== 'init' ? 'secondary' : undefined}>
              Start:{' '}
            </Text>
            <Text type={i !== 'init' ? 'secondary' : undefined}>
              {intl
                .formatDate(log?.newStartDate, {
                  weekday: 'short',
                })
                .toUpperCase()}{' '}
              {intl.formatTime(log.newStartDate, {
                timeStyle: 'short',
                hour12: false,
              })}
            </Text>
          </Space>
          <Space>
            <Text strong type={i !== 'init' ? 'secondary' : undefined}>
              {translation('end')}:{' '}
            </Text>
            <Text type={i !== 'init' ? 'secondary' : undefined}>
              {intl
                .formatDate(log?.newEndDate, {
                  weekday: 'short',
                })
                .toUpperCase()}{' '}
              {intl.formatTime(log.newEndDate, {
                timeStyle: 'short',
                hour12: false,
              })}
            </Text>
          </Space>
          <Text className={styles.cancellationComment}>
            {intl.formatDate(log.logDate, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}{' '}
            {intl.formatTime(log.logDate, {
              timeStyle: 'short',
              hour12: false,
            })}
          </Text>
        </Space>

        <AccessControl featureKey={IFeatures.progressWriteDate}>
          <Space style={{ width: '300px', justifyContent: 'end' }}>
            <Button
              type="link"
              className={styles.editingButton}
              onClick={handleButtonClick}
            >
              {translation('open_in_drawer')} <SwapRightOutlined />
            </Button>
          </Space>
        </AccessControl>
      </Space>
    );
  };

  return (
    <Popover
      placement="right"
      title={translation('appointment_time_log')}
      onOpenChange={handleClickChange}
      open={isVisible}
      trigger="click"
      content={
        timeLogState?.loadingTimeLog ? (
          <Spin size="large" className={styles.spin} />
        ) : timeLogState?.error || timeLogState?.timeLog?.length === 0 ? (
          hasWriteAccess ? (
            <div style={{ minWidth: '250px' }}>
              <ChangeLog
                key="change-log-0"
                isLatest={true}
                editMode={true}
                handleEditMode={undefined}
                handleSubmit={handleSubmitTimeChangeLog}
                timeLog={{
                  newStartDate: selectedField?.appointmentDate,
                  newEndDate: selectedField?.appointmentEndDate,
                  logDate: selectedField?.createdAt,
                }}
                appointment={null}
                isPopover={true}
              />
              <Space style={{ width: '300px', justifyContent: 'end' }}>
                <Button
                  type="link"
                  className={styles.editingButton}
                  onClick={handleButtonClick}
                >
                  {translation('open_in_drawer')} <SwapRightOutlined />
                </Button>
              </Space>
            </div>
          ) : (
            showScheduledTimeLog('init', {
              newStartDate: selectedField?.appointmentDate,
              newEndDate: selectedField?.appointmentEndDate,
              logDate: selectedField?.createdAt,
            })
          )
        ) : (
          <Space size={4} direction="vertical" style={{ width: '300px' }}>
            {timeLogState?.timeLog.map((log, i) => {
              if (hasWriteAccess && i === 0) {
                return (
                  <ChangeLog
                    key={`${selectedField?.externalCaseId}-log`}
                    isLatest={true}
                    editMode={true}
                    handleEditMode={undefined}
                    handleSubmit={handleSubmitTimeChangeLog}
                    timeLog={log}
                    appointment={null}
                    isPopover={true}
                  />
                );
              } else if (log?.oldEndDate || log?.oldStartDate) {
                return (
                  <Space
                    size={4}
                    direction="vertical"
                    key={i}
                    className={
                      i !== 0
                        ? styles.startEndContainer
                        : styles.firstStartEndContainer
                    }
                  >
                    <Space>
                      <Text type={i !== 0 ? 'secondary' : undefined} strong>
                        Start:{' '}
                      </Text>

                      {!!log?.oldStartDate &&
                        log?.oldStartDate !== log?.newStartDate && (
                          <Space className={styles.verticalAlignment}>
                            {moment(log?.oldStartDate).weekday() ===
                              moment(log?.newStartDate).weekday() && (
                              <Text type={i !== 0 ? 'secondary' : undefined}>
                                {intl
                                  .formatDate(log?.oldStartDate, {
                                    weekday: 'short',
                                  })
                                  .toUpperCase()}{' '}
                              </Text>
                            )}
                            <Text className={styles.canceledTime}>
                              {moment(log?.oldStartDate).weekday() !==
                              moment(log?.newStartDate).weekday()
                                ? intl
                                    .formatDate(log?.oldStartDate, {
                                      weekday: 'short',
                                    })
                                    .toUpperCase()
                                : ''}{' '}
                              {intl.formatTime(log.oldStartDate, {
                                timeStyle: 'short',
                                hour12: false,
                              })}
                            </Text>
                            <SwapRightOutlined />
                          </Space>
                        )}
                      <Text type={i !== 0 ? 'secondary' : undefined}>
                        {!log?.oldStartDate ||
                        log?.oldStartDate === log?.newStartDate ||
                        moment(log?.oldStartDate).weekday() !==
                          moment(log?.newStartDate).weekday()
                          ? intl
                              .formatDate(log?.newStartDate, {
                                weekday: 'short',
                              })
                              .toUpperCase()
                          : ''}{' '}
                        {intl.formatTime(log.newStartDate, {
                          timeStyle: 'short',
                          hour12: false,
                        })}
                      </Text>
                    </Space>

                    <Space>
                      <Text strong type={i !== 0 ? 'secondary' : undefined}>
                        {translation('end')}:{' '}
                      </Text>

                      {!!log?.oldEndDate &&
                        log?.oldEndDate !== log?.newEndDate && (
                          <Space className={styles.verticalAlignment}>
                            {moment(log?.oldEndDate).weekday() ===
                              moment(log?.newEndDate).weekday() && (
                              <Text type={i !== 0 ? 'secondary' : undefined}>
                                {intl
                                  .formatDate(log?.oldEndDate, {
                                    weekday: 'short',
                                  })
                                  .toUpperCase()}{' '}
                              </Text>
                            )}
                            <Text className={styles.canceledTime}>
                              {moment(log?.oldEndDate).weekday() !==
                              moment(log?.newEndDate).weekday()
                                ? intl
                                    .formatDate(log?.oldEndDate, {
                                      weekday: 'short',
                                    })
                                    .toUpperCase()
                                : ''}{' '}
                              {intl.formatTime(log.oldEndDate, {
                                timeStyle: 'short',
                                hour12: false,
                              })}
                            </Text>
                            <SwapRightOutlined />
                          </Space>
                        )}
                      <Text type={i !== 0 ? 'secondary' : undefined}>
                        {!log?.oldEndDate ||
                        log?.oldEndDate === log?.newEndDate ||
                        moment(log?.oldEndDate).weekday() !==
                          moment(log?.newEndDate).weekday()
                          ? intl
                              .formatDate(log?.newEndDate, {
                                weekday: 'short',
                              })
                              .toUpperCase()
                          : ''}{' '}
                        {intl.formatTime(log.newEndDate, {
                          timeStyle: 'short',
                          hour12: false,
                        })}
                      </Text>
                    </Space>

                    <Text
                      className={
                        i !== 0
                          ? styles.cancellationComment
                          : styles.newestCancellationComment
                      }
                    >
                      {log?.comment}
                    </Text>
                  </Space>
                );
              } else {
                return showScheduledTimeLog(i, log);
              }
            })}
          </Space>
        )
      }
    >
      {children}
    </Popover>
  );
};

export default TimeLogPopover;
