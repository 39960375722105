import React from 'react';

export const AddFilterIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M14.5734 1.60938H1.427C0.989496 1.60938 0.716282 2.08616 0.935925 2.46652L5.0895 9.52723V13.8237C5.0895 14.1397 5.34307 14.3951 5.65735 14.3951H10.3431C10.6574 14.3951 10.9109 14.1397 10.9109 13.8237V9.52723L15.0663 2.46652C15.2841 2.08616 15.0109 1.60938 14.5734 1.60938ZM9.63235 13.1094H6.36807V10.3237H9.63414V13.1094H9.63235ZM9.80378 8.88438L9.63414 9.1808H6.36628L6.19664 8.88438L2.65557 2.89509H13.3449L9.80378 8.88438Z"
      fill="black"
      fillOpacity="0.45"
    />
    <path
      d="M13.143 10.5137C13.143 10.3875 13.2453 10.2852 13.3716 10.2852H14.0573C14.1835 10.2852 14.2859 10.3875 14.2859 10.5137V14.628C14.2859 14.7543 14.1835 14.8566 14.0573 14.8566H13.3716C13.2453 14.8566 13.143 14.7543 13.143 14.628V10.5137Z"
      fill="black"
      fillOpacity="0.45"
    />
    <path
      d="M11.6573 13.1423C11.531 13.1423 11.4287 13.04 11.4287 12.9137V12.228C11.4287 12.1018 11.531 11.9994 11.6573 11.9994H15.7716C15.8978 11.9994 16.0001 12.1018 16.0001 12.228V12.9137C16.0001 13.04 15.8978 13.1423 15.7716 13.1423H11.6573Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);
