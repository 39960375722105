import { FC, useState } from 'react';
import { Carousel, Image } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

type Props = {
  imageUrls: string[];
  visible: boolean;
  currentIndex: number;
  onVisibleChange: () => void;
  onUpdateCommentImageList?: (arg0: any) => void;
  isEditable?: boolean;
  numOfCommentImages?: number;
};

type CustomArrowProps = {
  currentSlide?: number;
  slideCount?: number;
};

const NextArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return <RightOutlined {...props} />;
};

const LeftArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return <LeftOutlined {...props} />;
};

const CarouselSlider: FC<Props> = ({
  imageUrls,
  currentIndex,
  visible,
  onVisibleChange,
}: Props) => {
  const [currentImage, setCurrentImage] = useState<number>(currentIndex);

  return (
    <Carousel
      arrows
      initialSlide={currentImage}
      prevArrow={<LeftArrow />}
      nextArrow={<NextArrow />}
      style={{ display: 'none' }}
    >
      <Image.PreviewGroup
        preview={{
          current: currentImage,
          visible: visible,
          onVisibleChange,
          onChange: (current, prev) => setCurrentImage(current),
        }}
      >
        {imageUrls?.map((imageUrl, i: number) => {
          return (
            <Image
              key={`carousel-preview-image-${i}`}
              placeholder
              src={imageUrl}
            />
          );
        })}
      </Image.PreviewGroup>
    </Carousel>
  );
};

export default CarouselSlider;
