import AppLayout from '../../components/appLayout';
import { RequestAccessPage } from 'craftos-ui';

const Page = () => {
  return (
    <AppLayout hideApps>
      <RequestAccessPage />
    </AppLayout>
  );
};

export default Page;
