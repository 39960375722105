import { FC, useMemo } from 'react';
import { Input, Form, Space, InputNumber, Typography, Tooltip } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

interface FormItemReqAnswersCountProps extends FieldItemProps {
  isPublishedVersion?: boolean;
}

const FormItemReqAnswersCount: FC<FormItemReqAnswersCountProps> = ({
  editMode,
  fieldChanges,
  isPublishedVersion,
}) => {
  const answers = Form.useWatch('answers');
  const requiredAnswerCount = Form.useWatch('requiredAnswerCount');

  const publishedItemValues = useMemo(
    () => ({
      requiredAnswerCount: fieldChanges?.requiredAnswerCount.publishedItem,
    }),
    [fieldChanges]
  );

  return answers?.length || isPublishedVersion ? (
    <Form.Item
      name="dummyName"
      style={{ marginBottom: 0 }}
      label={
        fieldChanges?.requiredAnswerCount.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('required_answers_tooltip')}>
              <span>{translation('required_answers')} </span>{' '}
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemReqAnswersCount isPublishedVersion />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('required_answers_tooltip')}>
            <span>{translation('required_answers')} </span>{' '}
          </Tooltip>
        )
      }
      rules={[{ required: true, validator: async () => {} }]}
    >
      <Input.Group>
        <Space>
          <Form.Item
            label="Min"
            name={['requiredAnswerCount', 'min']}
            rules={[
              {
                required: true,
                message: 'Insert the minimum required answers',
              },
              {
                validator: async (_, min) => {
                  if (min > answers?.length || min > requiredAnswerCount?.max) {
                    return Promise.reject(
                      new Error("Min can't be higher then answers count or max")
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              disabled={!editMode}
              placeholder="Min"
              min={1}
              max={requiredAnswerCount?.max}
            />
          </Form.Item>
          <Form.Item
            label="Max"
            name={['requiredAnswerCount', 'max']}
            rules={[
              {
                required: true,
                message: 'Insert the maximum required answers',
              },
              {
                validator: async (_, max) => {
                  if (max > answers?.length) {
                    return Promise.reject(
                      new Error("Max can't be higher then answers count")
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              disabled={!editMode}
              placeholder="Max"
              min={1}
              max={answers?.length}
            />
          </Form.Item>
        </Space>
      </Input.Group>
    </Form.Item>
  ) : (
    <></>
  );
};

export default FormItemReqAnswersCount;
