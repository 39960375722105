import React from 'react';

export const AccountManagerMTWIcon: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g id="Frame" clipPath="url(#clip0_5371_166025)">
      <path
        id="Vector"
        d="M2 8.5C2 9.28793 2.15519 10.0681 2.45672 10.7961C2.75825 11.5241 3.20021 12.1855 3.75736 12.7426C4.31451 13.2998 4.97595 13.7417 5.7039 14.0433C6.43185 14.3448 7.21207 14.5 8 14.5C8.78793 14.5 9.56815 14.3448 10.2961 14.0433C11.0241 13.7417 11.6855 13.2998 12.2426 12.7426C12.7998 12.1855 13.2417 11.5241 13.5433 10.7961C13.8448 10.0681 14 9.28793 14 8.5C14 7.71207 13.8448 6.93185 13.5433 6.2039C13.2417 5.47595 12.7998 4.81451 12.2426 4.25736C11.6855 3.70021 11.0241 3.25825 10.2961 2.95672C9.56815 2.65519 8.78793 2.5 8 2.5C7.21207 2.5 6.43185 2.65519 5.7039 2.95672C4.97595 3.25825 4.31451 3.70021 3.75736 4.25736C3.20021 4.81451 2.75825 5.47595 2.45672 6.2039C2.15519 6.93185 2 7.71207 2 8.5Z"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M6 7.16699C6 7.69743 6.21071 8.20613 6.58579 8.58121C6.96086 8.95628 7.46957 9.16699 8 9.16699C8.53043 9.16699 9.03914 8.95628 9.41421 8.58121C9.78929 8.20613 10 7.69743 10 7.16699C10 6.63656 9.78929 6.12785 9.41421 5.75278C9.03914 5.37771 8.53043 5.16699 8 5.16699C7.46957 5.16699 6.96086 5.37771 6.58579 5.75278C6.21071 6.12785 6 6.63656 6 7.16699Z"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M4.11206 13.0663C4.27707 12.5171 4.61471 12.0358 5.07489 11.6936C5.53508 11.3515 6.09329 11.1668 6.66673 11.167H9.33339C9.90757 11.1668 10.4665 11.3519 10.927 11.6948C11.3875 12.0378 11.725 12.5202 11.8894 13.0703"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5371_166025">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
