export enum StatusChangeLogActions {
  FETCH_STATUS_CHANGE_LOG_START = 'FETCH_STATUS_CHANGE_LOG_START',
  FETCH_STATUS_CHANGE_LOG_SUCCESS = 'FETCH_STATUS_CHANGE_LOG_SUCCESS',
  FETCH_STATUS_CHANGE_LOG_FAILED = 'FETCH_STATUS_CHANGE_LOG_FAILED',
  RESET_STATE = 'RESET_STATE',
}

export interface StatusChangeLogState {
  loadingStatusChangeLog: boolean;
  statusChangeLog: any[];
  error: any;
}

export type StatusChangeLogObject = {
  oldStatus?: string | null;
  newStatus: string | null;
  comment: string | null;
  logDate: string;
  userInfo: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
  };
};

export interface StatusChangeLogReqBody {
  newStatus: string;
  oldStatus?: string;
  comment?: string;
}
