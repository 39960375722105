import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Avatar, Typography, Divider } from 'antd';
import {
  HomeOutlined,
  PhoneOutlined,
  FullscreenOutlined,
  FolderOpenOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { LocationIcon } from '../../../../components/icons';
import { RootState } from '../../../../store';
import translation from '../../../../i18n/translation';
import styles from '../detailDrawer.module.scss';
import { config } from '../../../../config';
import { IAppointment } from '../../../../constants/types';

const { Text, Link } = Typography;
interface Props {
  selectedAppointment?: IAppointment | null;
}
const WorkOrder: FC<Props> = ({ selectedAppointment }) => {
  const workOrderDetail = useSelector(
    (state: RootState) => state.detailDrawer.workOrderDetail
  );

  return (
    <>
      <Row>
        <Col flex="none">
          <Avatar
            icon={<HomeOutlined style={{ fontSize: '22px' }} />}
            className={styles.avatar}
            style={{ backgroundColor: '#FF6A00' }}
          />
        </Col>
        <Col flex="auto">
          <Text strong>{translation('customer_information')}</Text>
          <div className={styles.workOrderContentLine}>
            <Text className={styles.greyText}>{translation('address')}</Text>
            <div className={styles.center}>
              <LocationIcon />
              <Link
                href={`http://maps.google.com/maps?q=${workOrderDetail?.customer?.address?.address1}`}
                target="_blank"
                style={{
                  marginLeft: '8px',
                }}
              >
                {workOrderDetail?.customer?.address?.address1}
              </Link>
            </div>
          </div>
          <div className={styles.workOrderContentLine}>
            <Text className={styles.greyText}>
              {translation('phone_number')}
            </Text>
            <div className={styles.center}>
              <PhoneOutlined
                rotate={90}
                className={styles.greyText}
                style={{ fontSize: '16px' }}
              />
              <Text
                className={styles.greyText}
                style={{
                  marginLeft: '6px',
                }}
              >
                {workOrderDetail?.customer?.address?.telephone}
              </Text>
            </div>
          </div>
          <Divider className={styles.divider} />
        </Col>
      </Row>
      <Row>
        <Col flex="none">
          <Avatar
            icon={<FullscreenOutlined style={{ fontSize: '22px' }} />}
            className={styles.avatar}
            style={{ backgroundColor: '#AFCA42' }}
          />
        </Col>
        <Col flex="auto">
          <Text strong>Salesforce links</Text>
          <div className={styles.workOrderContentLine}>
            <Text className={styles.greyText}>Fulfilment Case</Text>

            <Link
              id="ga4-detail-drawer-sf-link"
              href={`${config.sfIntegrationUrl}/r/Case/${selectedAppointment?.additionalInformation?.fullfillmentCaseId}/view`}
              target="_blank"
            >
              {selectedAppointment?.additionalInformation?.fullfillmentCaseId}
            </Link>
          </div>
          <div className={styles.workOrderContentLine}>
            <Text className={styles.greyText}>Work order</Text>
            <Link
              id="ga4-detail-drawer-sf-link"
              href={`${config.sfIntegrationUrl}/r/WorkOrder/${workOrderDetail?.workOrderId}/view`}
              target="_blank"
            >
              {workOrderDetail?.workOrderId}
            </Link>
          </div>
          {workOrderDetail?.plCaseId && (
            <div className={styles.workOrderContentLine}>
              <Text className={styles.greyText}>PL Case</Text>
              <Link
                id="ga4-detail-drawer-sf-link"
                href={`${config.sfIntegrationUrl}/r/Case/${workOrderDetail?.plCaseId}/view`}
                target="_blank"
              >
                {workOrderDetail?.plCaseId}
              </Link>
            </div>
          )}
          <Divider className={styles.divider} />
        </Col>
      </Row>
      <Row>
        <Col flex="none">
          <Avatar
            icon={<FolderOpenOutlined style={{ fontSize: '22px' }} />}
            className={styles.avatar}
            style={{ backgroundColor: '#AA3EFF' }}
          />
        </Col>
        <Col flex="auto">
          <Text strong>{translation('documents')}</Text>
          {workOrderDetail?.files &&
            workOrderDetail?.files.map((file: any) => (
              <div key={file.fileName} className={styles.workOrderContentLine}>
                <Link href={file.url} target="_blank">
                  <FileOutlined /> {file.fileName}
                </Link>
              </div>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default WorkOrder;
