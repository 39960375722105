import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { LOCALES } from './locales';
import messages from './messages';
import { RootState } from '../store';

const Provider = ({ children }: any) => {
  const currentLanguage = useSelector(
    (state: RootState) => state.changeLanguage.currentLanguage
  );
  return (
    <IntlProvider
      locale={currentLanguage}
      textComponent={Fragment}
      messages={messages[currentLanguage]}
      defaultLocale={LOCALES.GERMAN}
    >
      {children}
    </IntlProvider>
  );
};

export default Provider;
