import React from 'react';
import styles from './imageSelector.module.scss';
import ClassNames from 'classnames';

interface IThumbnail {
  src: string;
  isSelected?: boolean;
  onSelect?: React.MouseEventHandler<HTMLElement>;
  onPreview?: React.MouseEventHandler<HTMLElement>;
}

const Thumbnail: React.FC<IThumbnail> = ({
  src,
  isSelected = false,
  onSelect,
  onPreview,
}) => {
  return (
    <div className={styles.thumbnail}>
      <img
        src={src}
        className={styles.image}
        onClick={onPreview}
        alt={'thumbnail img'}
      />
      <div
        onClick={onSelect}
        className={ClassNames(styles.radio, {
          [styles.selected]: isSelected,
        })}
      />
    </div>
  );
};

export default Thumbnail;
