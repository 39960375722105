import React from 'react';

export const MenuFoldLeftMTWIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g id="MenuFold">
      <rect width="14" height="14" fill="white" />
      <path
        id="Vector"
        d="M5.37524 5.90625H12.8752C12.944 5.90625 13.0002 5.85 13.0002 5.78125V4.90625C13.0002 4.8375 12.944 4.78125 12.8752 4.78125H5.37524C5.30649 4.78125 5.25024 4.8375 5.25024 4.90625V5.78125C5.25024 5.85 5.30649 5.90625 5.37524 5.90625ZM5.25024 9.09375C5.25024 9.1625 5.30649 9.21875 5.37524 9.21875H12.8752C12.944 9.21875 13.0002 9.1625 13.0002 9.09375V8.21875C13.0002 8.15 12.944 8.09375 12.8752 8.09375H5.37524C5.30649 8.09375 5.25024 8.15 5.25024 8.21875V9.09375ZM13.1252 1.5H0.875244C0.806494 1.5 0.750244 1.55625 0.750244 1.625V2.5C0.750244 2.56875 0.806494 2.625 0.875244 2.625H13.1252C13.194 2.625 13.2502 2.56875 13.2502 2.5V1.625C13.2502 1.55625 13.194 1.5 13.1252 1.5ZM13.1252 11.375H0.875244C0.806494 11.375 0.750244 11.4313 0.750244 11.5V12.375C0.750244 12.4438 0.806494 12.5 0.875244 12.5H13.1252C13.194 12.5 13.2502 12.4438 13.2502 12.375V11.5C13.2502 11.4313 13.194 11.375 13.1252 11.375ZM0.803369 7.10781L3.24556 9.03125C3.33618 9.10313 3.47056 9.03906 3.47056 8.92344V5.07656C3.47056 4.96094 3.33774 4.89688 3.24556 4.96875L0.803369 6.89219C0.786948 6.90496 0.77366 6.92131 0.764519 6.93999C0.755378 6.95867 0.750627 6.9792 0.750627 7C0.750627 7.0208 0.755378 7.04133 0.764519 7.06001C0.77366 7.0787 0.786948 7.09505 0.803369 7.10781Z"
        fill="#3E7EFF"
      />
    </g>
  </svg>
);
