import { FC, useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Layout, message, FormInstance } from 'antd';
import ApiRepository from './../../services/api/apiRepository';
import {
  fetchFormAsync,
  fetchPublishedFormAsync,
  fetchMetadataAsync,
  resetState,
} from './actions';
import styles from './formBuilderDetail.module.scss';
import * as routes from '../../router/routes';
import {
  FormChild,
  EditMode,
  TreeDataNode,
  FormType,
  FormDetailModals,
  FormDetailModalsState,
  FlatList,
  FormDetailParams,
} from './types';
import {
  generateTreeData,
  reverseToFormChildren,
  addNewField,
  deleteField,
  hasDependenciesUnderneath,
  hasDependantAbove,
  normalizeFormValues,
  hasDependant,
} from './utils';

import AppLayout from '../../components/appLayout';
import TreeMenu from './components/TreeMenu';
import FormDetail from './components/FormDetail';
import AddNewFieldModal from './components/AddNewFieldModal';
import FormHeader from './components/FormHeader';
import DeleteFieldModal from './components/DeleteFieldModal';
import EditFormModal from './components/EditFormModal';

const { Sider, Content } = Layout;

const FormBuilderDetail: FC = () => {
  const navigate = useNavigate();
  const { formId, id, fieldId }: FormDetailParams = useParams();
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [currentFlatList, setCurrentFlatList] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<FormChild>();
  const [showModal, setShowModal] = useState<FormDetailModalsState>({
    newField: false,
    deleteField: false,
    editForm: false,
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [editMode, setEditMode] = useState<EditMode>({
    treeMenu: false,
    formDetail: false,
  });

  const handleMessage = (msg: string) => {
    message.error(msg);
  };

  const handleEditMode = (key: keyof EditMode, mode: boolean) => {
    if (
      key === 'treeMenu' &&
      mode === false &&
      form &&
      form.children?.length > 0
    ) {
      setTreeData(generateTreeData(form.children));
    }
    setEditMode({ ...editMode, [key]: mode });
  };

  const openModal = useCallback((target: keyof FormDetailModalsState) => {
    setShowModal((prevState) => ({ ...prevState, [target]: true }));
  }, []);

  const closeModal = useCallback((target: keyof FormDetailModalsState) => {
    setShowModal((prevState) => ({ ...prevState, [target]: false }));
  }, []);

  const handleOpenDeleteFieldModal = () => {
    if (flatList && selectedNode) {
      if (hasDependant(flatList, selectedNode.id)) {
        message.error(
          'Unable to delete. This field has dependents. To delete please delete the dependencies first',
          5
        );
      } else {
        openModal(FormDetailModals.deleteField);
      }
    }
  };

  const handleSave = async (form: FormType, updatedFlatList: FlatList) => {
    if (form) {
      setSaveLoading(true);
      try {
        const newFormChildren = reverseToFormChildren(
          treeData,
          updatedFlatList
        );
        const newForm: FormType = { ...form, children: newFormChildren };
        const res: any = await ApiRepository.updateForm<FormType>(newForm);
        const { id, formId } = res.response;
        if (form.status === 'Draft') {
          dispatch(fetchFormAsync(id));
        } else {
          navigate({
            pathname: routes.formBuilderDetail.pathWithParams(
              formId,
              id,
              fieldId || '0'
            ),
          });
        }
        setSaveLoading(false);
        setEditMode({
          treeMenu: false,
          formDetail: false,
        });
      } catch (error: any) {
        setSaveLoading(false);
        if (error?.message) {
          message.error(error?.message);
        }
      }
    }
  };

  const handleNewField = async (formInstance: FormInstance) => {
    if (form) {
      setSaveLoading(true);
      try {
        const values = await formInstance.validateFields();
        const normalizedValues = normalizeFormValues(values);
        const newFormChildren = addNewField(
          form.children,
          selectedNode?.id,
          normalizedValues
        );
        const newForm: FormType = { ...form, children: newFormChildren };
        const res: any = await ApiRepository.updateForm<FormType>(newForm);
        const { id, formId } = res.response;
        if (form.status === 'Draft') {
          dispatch(fetchFormAsync(id));
        } else {
          navigate({
            pathname: routes.formBuilderDetail.pathWithParams(
              formId,
              id,
              fieldId || '0'
            ),
          });
        }
        setSaveLoading(false);
        closeModal(FormDetailModals.newField);
      } catch (error: any) {
        setSaveLoading(false);
        if (error?.message) {
          message.error(error?.message);
        }
        if (error?.errorFields) {
          for (let err of error.errorFields) {
            message.error(err.errors[0]);
          }
        }
      }
    }
  };

  const handleSwitchSelectToNewField = (list: FlatList) => {
    const newElement: any = [...list].find(
      (item: any) => !currentFlatList.get(item[0])
    );
    if (!!newElement && !!newElement[0]) {
      navigate({
        pathname: routes.formBuilderDetail.pathWithParams(
          formId!,
          id!,
          newElement[0]
        ),
      });
      setCurrentFlatList(list);
    }
  };

  const handleDeleteField = async () => {
    if (form && selectedNode) {
      setSaveLoading(true);
      try {
        const newFormChildren = deleteField(form.children, selectedNode.id);
        const newForm: FormType = { ...form, children: newFormChildren };
        const res: any = await ApiRepository.updateForm<FormType>(newForm);
        const { id, formId } = res.response;
        dispatch(fetchFormAsync(id));
        navigate({
          pathname: routes.formBuilderDetail.pathWithParams(formId, id, '0'),
        });
        setSaveLoading(false);
        closeModal(FormDetailModals.deleteField);
      } catch (error: any) {
        setSaveLoading(false);
        if (error?.message) {
          message.error(error?.message);
        }
      }
    }
  };

  const handleDrop = (info: any) => {
    if (!flatList || !form) return;
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (
      data: TreeDataNode[],
      key: string,
      callback: (item: TreeDataNode, index: number, arr: TreeDataNode[]) => any
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    //Checking if drop position is inside a Section, if not, returns an error
    if (!info.dropToGap && flatList.get(dropKey)?.type !== 'Section')
      return handleMessage(
        'Error! You can only move a field inside a section!'
      );

    //Checking if field has dependencies underneath
    if (hasDependenciesUnderneath(flatList, dragKey, dropKey, dropPosition))
      return handleMessage(
        'Error! You can not move a field above his dependencies'
      );

    //Checking if field has dependants above
    if (hasDependantAbove(flatList, dragKey, dropKey))
      return handleMessage(
        'Error! You can not move a field underneath his dependants'
      );

    const data = [...treeData];
    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.children || []).length > 0 && // Has children
      info.node.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: any;
      let i: any;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setTreeData(data);
  };

  useEffect(() => {
    if (form) {
      setTreeData(generateTreeData(form.children));
    }
  }, [form]);

  useEffect(() => {
    if (form && flatList && form.children?.length > 0) {
      if (fieldId === '0') {
        const nodeId = form?.children?.[0].id || '0';
        navigate(routes.formBuilderDetail.pathWithParams(formId!, id!, nodeId));
      } else {
        setSelectedNode(flatList.get(fieldId!));
      }
    }
  }, [form, fieldId, flatList, formId, id, navigate]);

  useEffect(() => {
    if (flatList?.size) {
      if (
        currentFlatList === null ||
        (currentFlatList?.size && currentFlatList?.size > flatList?.size)
      ) {
        setCurrentFlatList(flatList);
      } else {
        handleSwitchSelectToNewField(flatList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatList, currentFlatList]);

  useEffect(() => {
    if (id) dispatch(fetchFormAsync(id));

    return () => {
      dispatch(resetState());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (formId && formId !== '0') dispatch(fetchPublishedFormAsync(formId));
  }, [dispatch, formId]);

  useEffect(() => {
    dispatch(fetchMetadataAsync());
  }, [dispatch]);

  return (
    <AppLayout>
      <Layout style={{ height: '100%' }}>
        <FormHeader
          editMode={editMode}
          openEditFormModal={() => openModal(FormDetailModals.editForm)}
        />
        <Layout className={styles.outerLayout}>
          <Sider width={500} className={styles.wrapper} theme="light">
            <TreeMenu
              selectedNode={selectedNode}
              editMode={editMode}
              treeData={treeData}
              saveLoading={saveLoading}
              openNewFieldModal={() => openModal(FormDetailModals.newField)}
              handleEditMode={handleEditMode}
              handleDrop={handleDrop}
              handleSave={handleSave}
            />
          </Sider>
          <Content className={styles.wrapper} style={{ marginLeft: '16px' }}>
            <FormDetail
              selectedNode={selectedNode}
              editMode={editMode}
              saveLoading={saveLoading}
              openDeleteFieldModal={handleOpenDeleteFieldModal}
              handleEditMode={handleEditMode}
              handleSave={handleSave}
            />
          </Content>
        </Layout>
      </Layout>

      <AddNewFieldModal
        visible={showModal.newField}
        saveLoading={saveLoading}
        hideModal={() => closeModal(FormDetailModals.newField)}
        handleNewField={handleNewField}
      />

      <DeleteFieldModal
        selectedNode={selectedNode}
        visible={showModal.deleteField}
        saveLoading={saveLoading}
        hideModal={() => closeModal(FormDetailModals.deleteField)}
        handleDeleteField={handleDeleteField}
      />

      {showModal.editForm && (
        <EditFormModal
          visible={showModal.editForm}
          saveLoading={saveLoading}
          hideModal={() => closeModal(FormDetailModals.editForm)}
          handleSave={handleSave}
        />
      )}
    </AppLayout>
  );
};

export default FormBuilderDetail;
