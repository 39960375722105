import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Layout, Form, Input, Button, Spin } from 'antd';
import AppointmentTypesActions from '../actions';
import styles from './appointmentTypeDetail.module.scss';
import {
  AppointmentTypeDetailParams,
  AppointmentTypeFile,
  WorkType,
  WorkTypesGroup,
} from '../types';

import AppLayout from '../../../components/appLayout';
import AppointmentTypeHeader from './components/AppointmentTypeHeader';
import { PlusOutlined } from '@ant-design/icons';
import AllWorkTypesTable from './components/AllWorkTypesTable';
import GroupWorkTypesTable from './components/GroupWorkTypesTable';
import UploadedFiles from './components/UploadedFiles';
import GroupDetailsModal from './GroupDetailsModal';
import GroupCardsContainer from './components/GroupCardsContainer';
import { isEqual } from './utils';
import translation from '../../../i18n/translation';

const { Content } = Layout;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {},
  wrapperCol: {},
};

const AppointmentTypeDetail: FC = () => {
  const params: AppointmentTypeDetailParams = useParams();
  const dispatch = useDispatch();

  const appointmentTypesState = useSelector(
    (state: RootState) => state.appointmentTypes
  );

  useEffect(() => {
    dispatch(AppointmentTypesActions.fetchAllWorkTypesAsync());
    if (params.id && params.id !== 'create-new') {
      dispatch(
        AppointmentTypesActions.fetchSelectedAppointmentTypeAsync(params.id)
      );
    }

    return () => {
      dispatch(AppointmentTypesActions.resetState());
    };
  }, [dispatch, params.id]);

  const initialState = useMemo(() => appointmentTypesState.appointmentType, [
    appointmentTypesState.appointmentType,
  ]);

  const [formObject] = Form.useForm();
  const formWorkTypes = formObject.getFieldValue('workTypes');
  const preloadedFiles = formObject.getFieldValue('files');

  useEffect(() => {
    if (
      !appointmentTypesState.loading &&
      appointmentTypesState.appointmentType
    ) {
      formObject.setFieldsValue(initialState);
      setAppointmentTypeName(initialState?.name ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypesState.appointmentType, appointmentTypesState.loading]);

  const onReset = () => {
    formObject.resetFields();
    setIsSaveButtonActive(false);
    setIsChange(false);
    setSelectedGroupValue('');
  };

  const compareStates = useCallback(() => {
    const current = formObject.getFieldsValue(true);
    if (isEqual(initialState, current)) {
      setIsSaveButtonActive(false);
    } else {
      setIsSaveButtonActive(true);
    }
  }, [formObject, initialState]);

  const [selectedGroupValue, setSelectedGroupValue] = useState<string>('');
  const [selectedUpdateGroup, setSelectedUpdateGroup] = useState<
    WorkTypesGroup | undefined
  >(undefined);
  const [displayWorkTypes, setDisplayWorkTypes] = useState<
    WorkType[] | undefined
  >(undefined);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [displayFiles, setDisplayFiles] = useState<AppointmentTypeFile[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const [appointmentTypeName, setAppointmentTypeName] = useState<string>('');

  const showModal = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
    setSelectedUpdateGroup(undefined);
  };
  // The following function changes only group name, description or status:
  const handleChangesForGroup = (changeType: string, group: WorkTypesGroup) => {
    setIsSaveButtonActive(true);

    const currentState = formObject.getFieldsValue(true);
    if (changeType === 'create-new') {
      const newFormStateObject = {
        ...currentState,
        groups: !!formObject.getFieldValue('groups')
          ? [...formObject.getFieldValue('groups'), group]
          : [group],
      };
      formObject.setFieldsValue(newFormStateObject);
      setIsChange(true);
    } else if (changeType === 'delete-selected') {
      //if group is new and not in db:
      if (!group?.id) {
        const newFormStateObject = {
          ...currentState,
          groups: formObject
            .getFieldValue('groups')
            .filter(
              (existingGroup: WorkTypesGroup) =>
                existingGroup.id !== group.id ||
                existingGroup?.temporaryId !== group?.temporaryId
            ),
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      } // if group is from the initial state and we have to keep the id:
      const newFormStateObject = {
        ...currentState,
        groups: formObject
          .getFieldValue('groups')
          .map((existingGroup: WorkTypesGroup) => {
            if (
              existingGroup.id !== group.id ||
              existingGroup?.temporaryId !== group?.temporaryId
            ) {
              return existingGroup;
            } else {
              return { ...existingGroup, status: 'deleted' };
            }
          }),
      };
      formObject.setFieldsValue(newFormStateObject);
      setIsChange(true);
    } else if (changeType === 'update-selected') {
      const newFormStateObject = {
        ...currentState,
        groups: [
          ...formObject
            .getFieldValue('groups')
            .map((existingGroup: WorkTypesGroup) => {
              if (
                existingGroup.id !== group.id ||
                existingGroup?.temporaryId !== group?.temporaryId
              ) {
                return existingGroup;
              } else {
                return group;
              }
            }),
        ],
      };
      setSelectedUpdateGroup(undefined);
      setSelectedGroupValue('');
      formObject.setFieldsValue(newFormStateObject);
      setIsChange(true);
    }
  };

  // The following function manipulates the list of WorkTypes (on Group or AT):
  const handleUpdateWorkTypesList = (
    changeType: string,
    updatedWorkTypes: WorkType[]
  ) => {
    setIsSaveButtonActive(true);

    const currentState = formObject.getFieldsValue(true);
    if (!!selectedGroupValue) {
      //selecting Group which needs it's workTypes updated:
      if (changeType === 'change-order') {
        const updatedGroups = formObject
          .getFieldValue('groups')
          .map((existingGroup: WorkTypesGroup) => {
            return existingGroup.name !== selectedGroupValue
              ? existingGroup
              : { ...existingGroup, workTypes: updatedWorkTypes };
          });
        const newFormStateObject = {
          ...currentState,
          groups: updatedGroups,
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      } else if (changeType === 'add-new-form') {
        const updatedGroups = formObject
          .getFieldValue('groups')
          .map((existingGroup: WorkTypesGroup) => {
            return existingGroup.name !== selectedGroupValue
              ? existingGroup
              : {
                  ...existingGroup,
                  workTypes: existingGroup?.workTypes?.length
                    ? [...existingGroup.workTypes, ...updatedWorkTypes]
                    : [...updatedWorkTypes],
                };
          });
        const newFormStateObject = {
          ...currentState,
          groups: updatedGroups,
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      } else if (changeType === 'delete-form') {
        const updatedGroups = formObject
          .getFieldValue('groups')
          .map((existingGroup: WorkTypesGroup) => {
            return existingGroup.name !== selectedGroupValue
              ? existingGroup
              : {
                  ...existingGroup,
                  workTypes: existingGroup?.workTypes?.filter(
                    (form: WorkType) => form.id !== updatedWorkTypes[0].id
                  ),
                };
          });
        const newFormStateObject = {
          ...currentState,
          groups: updatedGroups,
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      }
    } else {
      // updating the forms for the Appointment Type:
      if (changeType === 'change-order') {
        const newFormStateObject = {
          ...currentState,
          workTypes: updatedWorkTypes,
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      } else if (changeType === 'add-new-form') {
        const newFormStateObject = {
          ...currentState,
          workTypes: formObject.getFieldValue('workTypes')?.length
            ? [...formObject.getFieldValue('workTypes'), ...updatedWorkTypes]
            : [...updatedWorkTypes],
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      } else if (changeType === 'delete-form') {
        const newFormStateObject = {
          ...currentState,
          workTypes: formObject
            .getFieldValue('workTypes')
            .filter((form: WorkType) => form.id !== updatedWorkTypes[0].id),
        };
        formObject.setFieldsValue(newFormStateObject);
        setIsChange(true);
      }
    }
  };
  const handleUpdateFileList = (
    changeType: string,
    updatedFile: AppointmentTypeFile
  ) => {
    const currentState = formObject.getFieldsValue(true);
    let updatedFileList = !!formObject?.getFieldValue('files')
      ? formObject.getFieldValue('files')
      : [];
    if (changeType !== 'add-new-file') {
      updatedFileList = updatedFileList.map((existingFile: any) => {
        if (
          changeType === 'update-file' &&
          existingFile.url === updatedFile.url
        ) {
          return { ...existingFile, fileName: updatedFile.label };
        } else if (
          changeType === 'delete-file' &&
          existingFile.url === updatedFile.url
        ) {
          return { ...existingFile, status: 'deleted' };
        } else {
          return existingFile;
        }
      });
    } else {
      updatedFileList.push({
        fileName: updatedFile.label,
        url: updatedFile.url,
        originalFileName: updatedFile.originalFileName,
        status: updatedFile.status === 'deleted' ? 'deleted' : '',
      });
    }

    formObject.setFieldsValue({
      ...currentState,
      files: [...updatedFileList],
    });
    getPreloadedFilesToDisplay(updatedFileList);
    compareStates();
  };

  const selectGroupWorkTypesToDisplay = useCallback(
    (_: string | undefined) => {
      if (
        formObject
          .getFieldValue('groups')
          ?.filter((item: WorkTypesGroup) => item.name === selectedGroupValue)
          .length
      ) {
        setDisplayWorkTypes(
          formObject
            .getFieldValue('groups')
            .filter(
              (item: WorkTypesGroup) => item.name === selectedGroupValue
            )[0].workTypes
        );
        setIsChange(false);
      } else {
        setIsChange(false);
      }
    },
    [formObject, selectedGroupValue]
  );
  const getPreloadedFilesToDisplay = useCallback(
    (files: any) => {
      const newArr = files.map((item: any, i: number) => {
        return {
          uid: i,
          url: item.url,
          name: item.fileName,
          label: item.fileName,
          status: item?.status ?? 'done',
        };
      });
      setDisplayFiles(newArr);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentTypesState.appointmentType?.files]
  );

  useEffect(() => {
    if (selectedGroupValue === '') {
      setDisplayWorkTypes(formObject.getFieldValue('workTypes'));
      setIsChange(false);
      compareStates();
    } else {
      selectGroupWorkTypesToDisplay(selectedGroupValue);
      compareStates();
    }
    setAppointmentTypeName(formObject.getFieldValue('name'));
  }, [
    selectedGroupValue,
    selectGroupWorkTypesToDisplay,
    formObject,
    formWorkTypes,
    isChange,
    compareStates,
  ]);

  useEffect(() => {
    if (
      formObject?.getFieldValue('files')?.length &&
      formObject?.getFieldValue('files')?.length > 0
    ) {
      getPreloadedFilesToDisplay(formObject.getFieldValue('files'));
    }
  }, [formObject, preloadedFiles, getPreloadedFilesToDisplay]);

  const handleFormChange = () => {
    setIsSaveButtonActive(formObject.isFieldsTouched());
    setAppointmentTypeName(formObject.getFieldValue('name'));
  };

  return (
    <AppLayout>
      <Layout>
        <AppointmentTypeHeader
          title={
            params.id !== 'create-new'
              ? appointmentTypeName
              : 'New Appointment Type'
          }
          appointmentType={appointmentTypesState.appointmentType}
          onReset={onReset}
          isSaveButtonActive={isSaveButtonActive}
          formObject={formObject}
        />
        <Layout className={styles.outerLayout}>
          {!!appointmentTypesState.loading ? (
            <Spin size="large" className={styles.spin} />
          ) : (
            <Content style={{ margin: '0px' }}>
              <Form
                onValuesChange={handleFormChange}
                {...formItemLayout}
                form={formObject}
                initialValues={initialState ?? {}}
                labelWrap
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingBottom: '16px',
                  }}
                >
                  {/* Name and Description column: */}
                  <div className={styles.detailBlock} style={{ width: '41%' }}>
                    <Form.Item
                      key="name"
                      name="name"
                      label="Name"
                      className={styles.formItem}
                      rules={[
                        {
                          required: true,
                          message: translation('provide_group_name'),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      key="desc"
                      name="description"
                      label="Description"
                      className={styles.formItem}
                    >
                      <TextArea rows={5} />
                    </Form.Item>
                  </div>

                  {/* File Upload column: */}
                  <div
                    className={styles.detailBlock}
                    style={{ width: '32.5%' }}
                  >
                    <Form.Item
                      key="files"
                      //name="files"
                      //valuePropName="files"
                      //getValueFromEvent={normFile}
                      style={{ width: '100%' }}
                    >
                      <UploadedFiles
                        defaultFileList={
                          !!displayFiles?.length ? displayFiles : undefined
                        }
                        formObject={formObject}
                        handleUpdateFileList={handleUpdateFileList}
                      />
                    </Form.Item>
                  </div>

                  {/* External Id and Groups count column: */}
                  <div className={styles.detailBlock} style={{ width: '24%' }}>
                    <Form.Item
                      key="extType"
                      name="externalType"
                      label="External Id (SalesForce Worktype)"
                      className={styles.formItem}
                      rules={[
                        {
                          required: true,
                          //validations stringCompare (unique and active)
                          message: translation('provide_external_id'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const check = appointmentTypesState.appointmentTypes?.filter(
                              (item) =>
                                item.externalType === value &&
                                item.id !== formObject.getFieldValue('id')
                            );
                            if (!value || check?.length === 0) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error('The External Id must be unique')
                            );
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <div
                      style={{
                        display: 'flex',
                        height: '62%',
                        width: '100%',
                        background: '#FFF',
                      }}
                    >
                      <div key="adGroup" className={styles.additionalInfo}>
                        <p className={styles.number}>
                          {formObject.getFieldValue('groups')?.length &&
                          formObject.getFieldValue('groups').length > 0
                            ? formObject
                                .getFieldValue('groups')
                                .filter(
                                  (item: WorkTypesGroup) =>
                                    item?.status !== 'deleted'
                                ).length
                            : '0'}
                        </p>
                        <p className={styles.title}>Groups</p>
                      </div>
                      <div key="adWT" className={styles.additionalInfo}>
                        <p className={styles.number}>
                          {formObject.getFieldValue('workTypes')?.length &&
                          formObject.getFieldValue('workTypes')?.length > 0
                            ? formObject.getFieldValue('workTypes').length
                            : '0'}
                        </p>
                        <p className={styles.title}>Work Types</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>

              <div className={styles.flex}>
                <h3 style={{ margin: '0 16px 0 0', padding: '0px 10px' }}>
                  Groups
                </h3>
              </div>

              {/* Section for AT WorkTypes Count and Groups WorkTypes Count - Hoverable Cards: */}
              <div
                className={styles.flex}
                style={{
                  justifyContent: 'start',
                  margin: '16px 0',
                }}
              >
                <div
                  className={
                    !!selectedGroupValue
                      ? styles.groupsBlock
                      : `${
                          styles.groupsBlock + ' ' + styles.highlightGroupOption
                        }`
                  }
                  onClick={() => {
                    setSelectedGroupValue('');
                  }}
                >
                  <p className={styles.groupName} style={{ width: '110px' }}>
                    ALL
                  </p>
                  <p className={styles.number}>
                    {formObject.getFieldValue('workTypes')?.length || '0'}
                  </p>
                  <p className={styles.countedItems}>Work Types</p>
                </div>

                <div
                  className={styles.flex}
                  style={{
                    paddingLeft: '16px',
                    maxWidth: '90%',
                  }}
                >
                  <GroupCardsContainer
                    groups={formObject.getFieldValue('groups')}
                    handleUpdateWorkTypesList={handleUpdateWorkTypesList}
                    setSelectedGroupValue={setSelectedGroupValue}
                    formObject={formObject}
                    loading={appointmentTypesState.loading}
                    setSelectedUpdateGroup={setSelectedUpdateGroup}
                    handleChangesForGroup={handleChangesForGroup}
                    showModal={showModal}
                    selectedGroupValue={selectedGroupValue}
                  />
                  <Button className={styles.addGroupButton} onClick={showModal}>
                    <PlusOutlined
                      style={{ color: '#FFFFFF', fontSize: '29px' }}
                    />
                  </Button>
                </div>
              </div>

              {/* Section for Group WorkTypes and All Available WorkTypes Tables */}
              <div
                className={styles.flex}
                style={{
                  justifyContent: 'space-between',
                  margin: '16px 0',
                  alignItems: 'start',
                }}
              >
                <div
                  className={styles.flex}
                  style={{
                    width: '58%',
                    minHeight: '320px',
                    borderRadius: '4px',
                    background: '#FFF',
                    padding: '16px',
                    alignItems: 'start',
                  }}
                >
                  <GroupWorkTypesTable
                    loading={appointmentTypesState.loading}
                    groups={formObject.getFieldValue('groups')}
                    workTypes={displayWorkTypes}
                    handleUpdateWorkTypesList={handleUpdateWorkTypesList}
                  />
                </div>

                <div
                  className={styles.flex}
                  style={{
                    width: '41%',
                    minHeight: '100px',
                    borderRadius: '4px',
                    background: '#FFF',
                    padding: '16px',
                    alignItems: 'start',
                  }}
                >
                  <AllWorkTypesTable
                    availableWorkTypes={
                      !!selectedGroupValue
                        ? formObject.getFieldValue('workTypes')
                        : appointmentTypesState.workTypes
                    }
                    loading={appointmentTypesState.loading}
                    handleUpdateWorkTypesList={handleUpdateWorkTypesList}
                    usedWorkTypes={displayWorkTypes}
                  />
                </div>
              </div>

              {isModalVisible && (
                <GroupDetailsModal
                  visible={isModalVisible}
                  handleChangesForGroup={handleChangesForGroup}
                  setSelectedUpdateGroup={setSelectedUpdateGroup}
                  selectedUpdateGroup={selectedUpdateGroup}
                  hideModal={hideModal}
                />
              )}
            </Content>
          )}
        </Layout>
      </Layout>
    </AppLayout>
  );
};

export default AppointmentTypeDetail;
