import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Space, Progress } from 'antd';
import * as routes from '../../../../router/routes';
import { LinkMTWIcon } from '../../../../components/icons/linkMTW';
import styles from './groupProgress.module.scss';
import translation from '../../../../i18n/translation';
import { MTWorkOrderParams } from '../..';

const { Text, Link } = Typography;

interface Props {
  appointmentId?: string;
  group?: any;
}

const GroupProgress: FC<Props> = ({ appointmentId = '', group }) => {
  const params: MTWorkOrderParams = useParams();
  return (
    <div className={styles.flexProgressWrapper}>
      <div className={styles.workItemsProgressContainer}>
        <Text>{group?.name}</Text>
        {group?.groupId && (
          <Space>
            <Text type="secondary">
              {group?.totalApprovedPackages}/{group?.totalPackages}{' '}
              {translation('work_types')}
            </Text>
            <Link
              href={`${routes.appointmentsDetails.pathWithParams(
                params?.page!,
                appointmentId
              )}?group=${group?.groupId}`}
              className={styles.flexCenter}
              target="_blank"
            >
              <LinkMTWIcon />
            </Link>
          </Space>
        )}
      </div>
      {typeof group?.totalPackages === 'number' &&
        typeof group?.totalApprovedPackages === 'number' && (
          <Link
            href={`${routes.appointmentsDetails.pathWithParams(
              params?.page!,
              appointmentId
            )}?group=${group?.groupId}`}
            disabled={!group?.groupId}
            target="_blank"
          >
            <Progress
              strokeColor={
                group?.totalApprovedPackages === group?.totalPackages
                  ? '#64D59F'
                  : '#FFC700'
              }
              percent={parseInt(
                String(
                  (parseInt(group?.totalApprovedPackages ?? '') /
                    group?.totalPackages) *
                    100
                )
              )}
              showInfo={false}
            />
          </Link>
        )}
    </div>
  );
};

export default GroupProgress;
