import { AnyAction } from 'redux';
import ApiRepository from '../../../services/api/apiRepository';
import { StatusChangeLogActions } from './types';

const actions = {
  fetchStatusChangeLogAsync(externalId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      dispatch({
        type: StatusChangeLogActions.FETCH_STATUS_CHANGE_LOG_START,
      });
      try {
        dispatch({
          type: StatusChangeLogActions.FETCH_STATUS_CHANGE_LOG_SUCCESS,
          payload: await ApiRepository.getStatusChangeLogByExternalId(
            externalId
          ),
        });
      } catch (error: any) {
        dispatch({
          type: StatusChangeLogActions.FETCH_STATUS_CHANGE_LOG_FAILED,
          payload: error,
        });
      }
    };
  },

  resetState() {
    return {
      type: StatusChangeLogActions.RESET_STATE,
    };
  },
};

export default actions;
