import React from 'react';

export const LinkMTWIcon: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g id="Frame" clipPath="url(#clip0_5371_166003)">
      <path
        id="Vector"
        d="M7.99996 4.5H3.99996C3.64634 4.5 3.3072 4.64048 3.05715 4.89052C2.8071 5.14057 2.66663 5.47971 2.66663 5.83333V12.5C2.66663 12.8536 2.8071 13.1928 3.05715 13.4428C3.3072 13.6929 3.64634 13.8333 3.99996 13.8333H10.6666C11.0202 13.8333 11.3594 13.6929 11.6094 13.4428C11.8595 13.1928 12 12.8536 12 12.5V8.5"
        stroke="#3E7EFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M7.33337 9.16699L13.3334 3.16699"
        stroke="#3E7EFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M10 3.16699H13.3333V6.50033"
        stroke="#3E7EFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5371_166003">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
