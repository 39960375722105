import { Dispatch } from 'redux';
import { FormBuilderDetailActions } from './types';
import ApiRepository from '../../services/api/apiRepository';

export const fetchFormStart = () => ({
  type: FormBuilderDetailActions.FETCH_FORM_START,
});

export const fetchFormAsync = (id: string) => async (dispatch: Dispatch) => {
  dispatch(fetchFormStart());
  try {
    const response = await ApiRepository.getFormDetail(id);
    dispatch({
      type: FormBuilderDetailActions.FETCH_FORM_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FormBuilderDetailActions.FETCH_FORM_FAILED,
      payload: error,
    });
  }
};

export const fetchPublishedFormStart = () => ({
  type: FormBuilderDetailActions.FETCH_PUBLISHED_FORM_START,
});

export const fetchPublishedFormAsync = (formId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(fetchPublishedFormStart());
  try {
    const response = await ApiRepository.getPublishedFormDetail(formId);
    dispatch({
      type: FormBuilderDetailActions.FETCH_PUBLISHED_FORM_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FormBuilderDetailActions.FETCH_PUBLISHED_FORM_FAILED,
      payload: error,
    });
  }
};

export const fetchMetadataAsync = () => async (dispatch: Dispatch) => {
  try {
    const response = await ApiRepository.getMetadataList();
    dispatch({
      type: FormBuilderDetailActions.FETCH_METADATA_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FormBuilderDetailActions.FETCH_METADATA_FAILED,
      payload: error,
    });
  }
};

export const resetState = () => ({
  type: FormBuilderDetailActions.RESET_STATE,
});
