import { AnyAction } from 'redux';
import {
  approvalPackageTypes,
  approvalPackageState,
} from '../../constants/types';

const initialState: approvalPackageState = {
  loading: false,
  error: null,
  assignment: {},
  isUnassigned: false,
  userHistory: [],
  userHistoryLoading: false,
  packageInfo: {},
};

const approvalPackageReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case approvalPackageTypes.GET_APPROVAL_PACKAGE_QUEUE:
      return {
        ...state,
        error: null,
        assignment: payload.response,
        isUnassigned: false,
      };

    case approvalPackageTypes.SET_APPROVAL_PACKAGE_LOADING:
      return {
        ...state,
        loading: payload,
        isUnassigned: false,
      };

    case approvalPackageTypes.SET_APPROVAL_PACKAGE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        isUnassigned: false,
      };

    case approvalPackageTypes.UNASSIGN_APPROVAL_PACKAGE:
      return {
        ...state,
        loading: false,
        assignment: {},
        isUnassigned: payload,
      };

    case approvalPackageTypes.SET_DEFAULT_VALUES:
      return {
        loading: false,
        error: null,
        assignment: {},
        isUnassigned: false,
        packageInfo: {},
      };

    case approvalPackageTypes.GET_APPROVAL_PACKAGE_INFO:
      return {
        ...state,
        loading: false,
        error: null,
        packageInfo: payload.response,
        isUnassigned: false,
      };

    case approvalPackageTypes.GET_USER_APPROVAL_PACKAGE_HISTORY:
      return {
        ...state,
        loading: false,
        error: null,
        userHistory: payload.response.userApprovalPackageInfo,
        userHistoryLoading: false,
      };

    case approvalPackageTypes.SET_USER_APPROVAL_PACKAGE_HISTORY_LOADING:
      return {
        ...state,
        userHistoryLoading: true,
      };

    case approvalPackageTypes.RESET_USER_APPROVAL_PACKAGE_HISTORY:
      return {
        ...state,
        userHistoryLoading: false,
        userHistory: [],
      };

    default:
      return state;
  }
};

export default approvalPackageReducer;
