import { FC } from 'react';
import MetaDataForm from './metaDataForm';
import { Modal, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { createMetaData } from '../actions';

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
}

const CreateMetaDataModal: FC<Props> = ({ showModal, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const resetModal = () => {
    handleCloseModal();
    form.resetFields();
  };

  const handleCreateMetadata = async () => {
    try {
      const { key, description } = await form.validateFields();
      dispatch(createMetaData(key, description));
      resetModal();
    } catch (error) {}
  };

  return (
    <Modal
      open={showModal}
      title="Create new Metadata"
      onCancel={resetModal}
      okText="Create"
      onOk={handleCreateMetadata}
    >
      <MetaDataForm form={form} />
    </Modal>
  );
};

export default CreateMetaDataModal;
