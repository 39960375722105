import React from 'react';

const UserAvatarCircle: React.FC = () => (
  <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none">
    <rect width="18px" height="18px" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM12 20C10.1819 20.0027 8.41756 19.3834 7 18.245V15C7 13.895 7.887 13 9 13H15C16.105 13 17 13.885 17 15V18.245C15.63 19.343 13.892 20 12 20Z"
      fill="#5F5F68"
    />
  </svg>
);

export default UserAvatarCircle;
