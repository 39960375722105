import { AuthenticationState } from '../constants/types';
import { SET_ROLES_LIST, SET_TOKEN } from '../constants/actionTypes';
import { AnyAction } from 'redux';
import { getUserPrivileges } from './rolesAndPrivileges';
import { User } from 'craftos-ui';

export const initialState: AuthenticationState = {
  token: undefined,
  loginUser: new User({}),
  privileges: {},
  rolesList: {},
};

export const authenticationReducer = (
  state = initialState,
  action: AnyAction
): AuthenticationState => {
  const { type, payload } = action;
  switch (type) {
    case SET_TOKEN:
      let { token, user } = payload;
      return {
        ...state,
        token,
        loginUser: new User(user),
      };
    case SET_ROLES_LIST:
      let { rolesList } = payload;
      rolesList = rolesList.reduce(
        (result: any, role: any) => ({
          ...result,
          [role.policy ? role.policy : role.accessControl]: role,
        }),
        {}
      );
      return {
        ...state,
        rolesList,
        privileges: getUserPrivileges(state.loginUser!, rolesList),
      };
    default:
      return state;
  }
};
