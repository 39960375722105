import { FC } from 'react';

interface Props {
  style?: object;
}

export const StopWatchIconSmall: FC<Props> = ({ style }) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" style={style}>
    <path
      d="M6.5 0.5H3.5V1.5H6.5V0.5ZM4.5 7H5.5V4H4.5V7ZM8.515 3.695L9.225 2.985C9.01 2.73 8.775 2.49 8.52 2.28L7.81 2.99C7.035 2.37 6.06 2 5 2C2.515 2 0.5 4.015 0.5 6.5C0.5 8.985 2.51 11 5 11C7.49 11 9.5 8.985 9.5 6.5C9.5 5.44 9.13 4.465 8.515 3.695ZM5 10C3.065 10 1.5 8.435 1.5 6.5C1.5 4.565 3.065 3 5 3C6.935 3 8.5 4.565 8.5 6.5C8.5 8.435 6.935 10 5 10Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
  </svg>
);
