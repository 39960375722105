import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate, useIntl } from 'react-intl';
import { RootState } from '../../store';
import {
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Layout,
  Checkbox,
  Divider,
  Button,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import translation from '../../i18n/translation';
import * as routes from '../../router/routes';
import { fetchFormsAsync } from './actions';
import AppLayout from '../../components/appLayout';
import Search from '../../components/search';
import NewFormModal from './NewFormModal';
import { Form, Sorter, FormStatus } from './types';
import styles from './formBuilderTable.module.scss';
import MetaDataModal from './metaDataModal/metaDataModal';

const columns: TableColumnsType<Form> = [
  {
    title: 'Id',
    dataIndex: 'formHandle',
    width: '140px',
    key: 'formHandle',
  },
  {
    title: translation('name'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: translation('status'),

    dataIndex: 'status',
    key: 'status',
  },
  {
    title: translation('formbuilder_work_type'),
    dataIndex: ['workType', 'name'],
    key: 'workType',
  },
  {
    title: translation('date_created'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (date: string) => <FormattedDate value={date} />,
  },
  {
    title: translation('date_updated'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (date: string) => <FormattedDate value={date} />,
  },
  {
    title: translation('updated_by'),
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    render: (updatedBy: any) => <p>{updatedBy?.name}</p>,
  },
];

const getQueryStringValue = (
  locationSearch: string,
  target: string,
  defaultValue: string | number
) => {
  let value = defaultValue;
  locationSearch
    .slice(1)
    .split('&')
    .forEach((str) => {
      const searchQuery = str.split('=');
      if (searchQuery[0] === target) value = searchQuery[1];
    });
  return value;
};

const getSorter = (sorter: Sorter) => {
  if (!sorter?.column) return '';
  const sortOrder = sorter.order === 'ascend' ? '-' : '';
  return `${sortOrder}${sorter.columnKey}`;
};

const FormBuilderTable: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.formBuilderTable.loadingForms
  );
  const forms = useSelector((state: RootState) => state.formBuilderTable.forms);
  const totalCount = useSelector(
    (state: RootState) => state.formBuilderTable.totalCount
  );
  const [pageNumber, setPageNumber] = useState<number>(
    +getQueryStringValue(location.search, 'pageNumber', 1)
  );
  const [pageSize, setPageSize] = useState<number>(
    +getQueryStringValue(location.search, 'pageSize', 10)
  );
  const [sortedBy, setSortedBy] = useState<string>(
    getQueryStringValue(location.search, 'sortedBy', '') as string
  );
  const [status, setStatus] = useState<FormStatus>(
    getQueryStringValue(location.search, 'status', '') as FormStatus
  );
  const [search, setSearch] = useState(
    getQueryStringValue(location.search, 'search', '') as string
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMetaDataModalVisible, setIsMetaDataModalVisible] = useState(false);

  const pagination: TablePaginationConfig = {
    total: totalCount,
    pageSize: pageSize,
    current: pageNumber,
    showSizeChanger: true,
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showMetaDataModal = () => {
    setIsMetaDataModalVisible(true);
  };

  const hideMetaDataModal = () => {
    setIsMetaDataModalVisible(false);
  };

  const handleNewFormSuccess = () => {
    dispatch(
      fetchFormsAsync({ pageNumber, pageSize, sortedBy, status, search })
    );
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    setPageNumber(pagination.current || 1);
    setPageSize(pagination.pageSize || 10);
    setSortedBy(getSorter(sorter));
  };

  const handleStatusChange = (event: CheckboxChangeEvent) => {
    if (event.target.name === status) {
      setStatus('');
    } else {
      setStatus(event.target.value as FormStatus);
    }
  };

  useEffect(() => {
    dispatch(
      fetchFormsAsync({ pageNumber, pageSize, sortedBy, status, search })
    );
  }, [dispatch, pageNumber, pageSize, sortedBy, status, search]);

  useEffect(() => {
    const sortedByQuery = sortedBy ? `&sortedBy=${sortedBy}` : '';
    const statusQuery = status ? `&status=${status}` : '';
    const searchQuery = search ? `&search=${search}` : '';

    navigate({
      pathname: location.pathname,
      search:
        `?pageNumber=${pageNumber}&pageSize=${pageSize}` +
        sortedByQuery +
        statusQuery +
        searchQuery,
    });
  }, [
    navigate,
    location.pathname,
    pageNumber,
    pageSize,
    sortedBy,
    status,
    search,
  ]);

  return (
    <AppLayout>
      <Layout className={styles.contentLayout}>
        <Layout.Content className={styles.wrapper}>
          <div className={styles.headerContainer}>
            <div className={styles.filtersContainer}>
              <Checkbox
                name="draft"
                checked={status === 'draft'}
                onChange={handleStatusChange}
                value={'draft'}
              >
                {intl.formatMessage({ id: 'draft' })}
              </Checkbox>
              <Checkbox
                name="published"
                checked={status === 'published'}
                onChange={handleStatusChange}
                value={'published'}
              >
                {intl.formatMessage({ id: 'published' })}
              </Checkbox>
              <Divider type="vertical" className={styles.divider} />
              <div>
                <Search
                  onSearch={(val) => setSearch(val)}
                  placeholder={intl.formatMessage({ id: 'form_name' })}
                  defaultValue={search}
                />
              </div>
            </div>
            <div className={styles.headerButtons}>
              <Button type="default" onClick={showMetaDataModal}>
                Metadata Repository
              </Button>
              <Button type="primary" onClick={showModal}>
                {intl.formatMessage({ id: 'add_new_form' })}
              </Button>
            </div>
          </div>
          <div className={styles.tableContent}>
            <Table
              size="small"
              showHeader
              sticky
              columns={columns}
              dataSource={forms}
              rowKey={(record) => record.id}
              pagination={pagination}
              loading={loading}
              onChange={handleTableChange}
              onRow={(form) => {
                return {
                  onClick: () => {
                    const formId = form.status === 'Draft' ? form.formId : '0';
                    const id = form.id;
                    navigate(
                      routes.formBuilderDetail.pathWithParams(formId, id)
                    );
                  },
                };
              }}
            />
          </div>
        </Layout.Content>
      </Layout>
      {isModalVisible && (
        <NewFormModal
          visible={isModalVisible}
          handleNewFormSuccess={handleNewFormSuccess}
          hideModal={hideModal}
        />
      )}
      {isMetaDataModalVisible && (
        <MetaDataModal
          visible={isMetaDataModalVisible}
          hideModal={hideMetaDataModal}
        />
      )}
    </AppLayout>
  );
};

export default FormBuilderTable;
