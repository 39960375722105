import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Row, Divider, Typography } from 'antd';
import BaseLogin from '../../components/baseLogin';
import styles from './signup.module.scss';

const SignUp = () => {
  const { Text, Link } = Typography;
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 12 },
    },
  };
  return (
    <BaseLogin>
      <Form
        name="normal_login"
        className={styles.loginForm}
        initialValues={{ remember: true }}
        layout="vertical"
        requiredMark="optional"
        {...layout}
      >
        <Button
          type="primary"
          className={styles.loginFormButton}
          style={{ width: '100%' }}
        >
          REGISTER WITH ENPAL ACCOUNT
        </Button>

        <Divider plain>OR</Divider>

        <Row justify="center" align="middle" style={{ marginBottom: '10px' }}>
          <Text strong>Register using an E-Mail address</Text>
        </Row>

        <Form.Item
          name="username"
          label={<Text strong>Email address</Text>}
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email address"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={<Text strong>Password</Text>}
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirm-password"
          label={<Text strong>Confirm password</Text>}
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Enter your password again"
          />
        </Form.Item>

        <Form.Item>
          <Checkbox>
            <Text>
              I agree to Enpal’s &nbsp;
              <Link>terms of use</Link>&nbsp; by the registration.
            </Text>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Row justify="center" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginFormButton}
            >
              REGISTER
            </Button>
          </Row>
        </Form.Item>

        <Divider />

        <Row justify="center" align="middle">
          <Text strong>
            Already have an account?&nbsp;
            <Link>Log in</Link>
          </Text>
        </Row>
      </Form>
    </BaseLogin>
  );
};

export default SignUp;
