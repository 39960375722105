import React from 'react';

export const LongArrowMTWIcon: React.FC = () => (
  <svg width="50" height="8" viewBox="0 0 50 8" fill="none">
    <path
      id="Line 41"
      d="M49.3536 4.35355C49.5488 4.15829 49.5488 3.84171 49.3536 3.64645L46.1716 0.464466C45.9763 0.269204 45.6597 0.269204 45.4645 0.464466C45.2692 0.659728 45.2692 0.976311 45.4645 1.17157L48.2929 4L45.4645 6.82843C45.2692 7.02369 45.2692 7.34027 45.4645 7.53553C45.6597 7.7308 45.9763 7.7308 46.1716 7.53553L49.3536 4.35355ZM0 4.5H49V3.5H0V4.5Z"
      fill="black"
    />
  </svg>
);
