import en from './en-US';
import de from './de-DE';
import fr from './fr-FR';

const messages: any = {
  ...en,
  ...de,
  ...fr,
};

export default messages;
