import { FC } from 'react';

export const StopWatchIcon: FC = () => (
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none">
    <path
      d="M13.25 0.083252H6.75V2.24992H13.25V0.083252ZM8.91667 14.1666H11.0833V7.66659H8.91667V14.1666ZM17.6158 7.00575L19.1542 5.46742C18.6883 4.91492 18.1792 4.39492 17.6267 3.93992L16.0883 5.47825C14.4092 4.13492 12.2967 3.33325 10 3.33325C4.61583 3.33325 0.25 7.69909 0.25 13.0833C0.25 18.4674 4.605 22.8333 10 22.8333C15.395 22.8333 19.75 18.4674 19.75 13.0833C19.75 10.7866 18.9483 8.67409 17.6158 7.00575ZM10 20.6666C5.8075 20.6666 2.41667 17.2758 2.41667 13.0833C2.41667 8.89075 5.8075 5.49992 10 5.49992C14.1925 5.49992 17.5833 8.89075 17.5833 13.0833C17.5833 17.2758 14.1925 20.6666 10 20.6666Z"
      fill="white"
    />
  </svg>
);
