import React, { FC, useEffect, useState } from 'react';
import { Table, TableColumnsType, Button, Tag } from 'antd';
import { WorkType, WorkTypesGroup } from '../../types';
import translation from '../../../../i18n/translation';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { asignGroupsForWorkTypes, getTagColor } from '../utils';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 }
    )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'grab', color: '#999' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface Props {
  workTypes: any;
  loading?: boolean;
  groups?: WorkTypesGroup[];
  handleUpdateWorkTypesList: (arg0: string, arg1: WorkType[]) => void;
}

const GroupWorkTypesTable: FC<Props> = ({
  workTypes,
  groups,
  handleUpdateWorkTypesList,
  loading,
}) => {
  const [dataSource, setDataSource] = useState<
    { id: string; name: string; description: string; key: string }[]
  >([]);
  const [displayWorkTypes, setDisplayWorkTypes] = useState<WorkType[]>([]);

  useEffect(() => {
    if (!!workTypes) {
      setDisplayWorkTypes(asignGroupsForWorkTypes(groups ?? [], workTypes));
    } else {
      setDisplayWorkTypes([]);
    }
  }, [groups, workTypes]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((wt) => wt.key === active.id);
      const overIndex = dataSource.findIndex((wt) => wt.key === over?.id);
      let newData = arrayMove(dataSource.slice(), activeIndex, overIndex);
      handleUpdateWorkTypesList('change-order', newData);
      setDataSource(newData);
    }
  };

  const columns: TableColumnsType<{
    id: string;
    name: string;
    description: string;
    key: string;
  }> = [
    {
      title: 'Sort',
      width: '70px',
      key: 'sort',
      dataIndex: 'sort',
    },
    {
      title: translation('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Groups',
      dataIndex: 'groups',
      key: 'groups',
      width: '250px',
      render: (value) => {
        return value?.length > 0 ? (
          value.map((item: string, i: number) => {
            return (
              <Tag key={i} color={getTagColor(item)}>
                {item}
              </Tag>
            );
          })
        ) : (
          <p> </p>
        );
      },
    },
    {
      title: 'Actions',
      width: '75px',
      dataIndex: 'actions',
      key: 'delete',
      render: (value, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            fontSize: '16px',
            height: 'auto',
            padding: '7px 0px',
          }}
          onClick={() => handleUpdateWorkTypesList('delete-form', [record])}
        >
          Delete
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (displayWorkTypes?.length > 0) {
      setDataSource(
        displayWorkTypes.map((item: WorkType, i: number) => {
          return { ...item, key: i.toString() };
        })
      );
    } else {
      setDataSource([]);
    }
  }, [workTypes, displayWorkTypes]);

  if (!displayWorkTypes) return null;

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((wt) => wt.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          size="small"
          showHeader
          sticky
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="key"
          scroll={{
            y: 300,
          }}
          loading={loading}
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

export default GroupWorkTypesTable;
