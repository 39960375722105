import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Typography, Spin, Space, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import translation from '../../../i18n/translation';
import { RootState } from '../../../store';
import { fetchWorkOrderDetailAsync, resetState } from './actions';
import styles from './detailDrawer.module.scss';
import * as routes from '../../../router/routes';
// import { getTimeDifference } from './utils';

import SectionCollapse from './components/sectionCollapse';
import Appointment from './components/appointment';
import WorkOrder from './components/workOrder';
import {
  AppointmentType,
  IAppointment,
  NotificationType,
} from '../../../constants/types';
import { SnippetsOutlined } from '@ant-design/icons';
import { showNotificationMessage } from '../../../utils/notification';
import ChangeLog from './components/changeLog';
import TimeLogActions from '../time-log-popover/actions';
import { TimeLogReqBody } from '../time-log-popover/types';
import ApiRepository from '../../../services/api/apiRepository';
import AccessControl from '../../../components/accessControl';
import { IFeatures } from '../../../store/rolesAndPrivileges';
import StatusChangeLog from '../status-log-history';
import StatusChangeLogActions from '../status-log-history/actions';
import moment from 'moment';
import CheckinLog from './components/checkinLog';
import DelayReasonLog from '../delay-log-history';
import Notes from './components/notes';

const { Text, Link } = Typography;

interface Props {
  selectedAppointment: IAppointment | null;
  showChangeTimeLogs?: boolean;
  rootPageRoute: string;
  appointmentStatusList?: string[];
  scrollTo?: 'notes' | 'delayedReason';
  onClose: (reloadCache: boolean) => void;
}

const DetailDrawer: FC<Props> = ({
  selectedAppointment,
  showChangeTimeLogs = false,
  rootPageRoute,
  appointmentStatusList,
  scrollTo,
  onClose,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const noteRef = useRef<null | HTMLDivElement>(null);
  const delayedReasonRef = useRef<null | HTMLDivElement>(null);
  const REFS_CONSTANT: any = {
    notes: noteRef,
    delayedReason: delayedReasonRef,
    default: null,
  };
  const detailDrawerState = useSelector(
    (state: RootState) => state.detailDrawer
  );
  const [open, setOpen] = useState(false);
  const timeLogState = useSelector((state: RootState) => state.timeLogData);
  const [editAppointment, setEditAppointment] = useState(false);
  const [editNotesActive, setEditNotesActive] = useState(false);
  const [scrollTarget, setScrollTarget] = useState<
    'notes' | 'delayedReason' | undefined
  >(undefined);
  const [
    shouldReloadProgressApiCache,
    setshouldReloadProgressApiCache,
  ] = useState<boolean>(false);

  const handleEditMode = () => {
    setEditAppointment(!editAppointment);
  };

  const executeScroll = (scrollTo: string) => {
    const ref = REFS_CONSTANT[scrollTo];
    if (ref?.current) {
      ref.current.scrollIntoView();
      setScrollTarget(undefined);
    }
  };

  const handleSubmitTimeChangeLog = async (timeLog: TimeLogReqBody) => {
    if (!!detailDrawerState?.appointmentDetail?.externalId) {
      try {
        await ApiRepository.createNewTimeLog(
          detailDrawerState.appointmentDetail.externalId,
          timeLog
        );
        dispatch(
          TimeLogActions.fetchTimeLogAsync(
            detailDrawerState.appointmentDetail.externalId
          )
        );
        handleEditMode();
        setshouldReloadProgressApiCache(true);
        return {
          error: null,
          loading: false,
        };
      } catch (error: any) {
        showNotificationMessage({
          message: intl.formatMessage({
            id: error.errorKey ?? 'error_fetch_apointments',
          }),
          error: error,
        });
        return {
          error: intl.formatMessage({ id: 'save_new_time_log_error' }),
          loading: false,
        };
      }
    }
  };

  const loadData = () => {
    if (selectedAppointment) {
      const {
        externalId,
        externalCaseId,
        appointmentType,
      } = selectedAppointment;
      if (externalId && externalCaseId) {
        dispatch(resetState());
        dispatch(TimeLogActions.resetState());
        dispatch(StatusChangeLogActions.resetState());
        dispatch(
          fetchWorkOrderDetailAsync(
            externalId,
            externalCaseId,
            appointmentType?.name ?? 'Montage'
          )
        );
        dispatch(TimeLogActions.fetchTimeLogAsync(externalId));
      }
    }
  };
  const refreshAppointmentDetailData = () => {
    if (selectedAppointment?.externalId) {
      dispatch(
        fetchWorkOrderDetailAsync(
          selectedAppointment?.externalId,
          detailDrawerState?.workOrderDetail?.externalCaseId,
          selectedAppointment?.appointmentType?.name ?? 'Montage'
        )
      );
      setshouldReloadProgressApiCache(true);
    }
  };

  useEffect(() => {
    if (!!selectedAppointment) {
      setEditAppointment(showChangeTimeLogs);
      if (!open) {
        setOpen(true);
        setshouldReloadProgressApiCache(false);
      }
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment]);

  useEffect(() => {
    setScrollTarget(scrollTo);
    setEditNotesActive(scrollTo === 'notes');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    if (scrollTarget && detailDrawerState?.workOrderDetail?.notes?.length) {
      executeScroll(scrollTarget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTarget, detailDrawerState?.workOrderDetail]);

  const handleOnClose = () => {
    setOpen(false);

    dispatch(resetState());
    dispatch(TimeLogActions.resetState());
    onClose(shouldReloadProgressApiCache);
  };

  return (
    <Drawer
      title={
        <Space>
          <Text style={{ fontSize: '18px' }}>
            {detailDrawerState?.workOrderDetail?.customer?.name}
          </Text>
          <Button
            icon={<SnippetsOutlined />}
            type="link"
            onClick={async () => {
              const clipboardText = `${detailDrawerState?.workOrderDetail?.customer?.name}\n${detailDrawerState?.workOrderDetail?.externalCaseId}\n${detailDrawerState?.workOrderDetail?.customer?.address?.address1}\n${detailDrawerState?.workOrderDetail?.customer?.address?.telephone}`;
              await window.navigator.clipboard.writeText(clipboardText);
              showNotificationMessage({
                message: '',
                description: intl.formatMessage({ id: 'escalate_notify' }),
                notificationType: NotificationType.info,
                duration: 2,
              });
            }}
          />
        </Space>
      }
      extra={
        /*      
                <Link
                  href={`${routes.workorder.pathWithParams(
                    rootPageRoute,
                    detailDrawerState?.workOrderDetail?.externalCaseId ?? '',
                    selectedAppointment?.appointmentType?.name ?? ''
                  )}`}
                  target="_blank"
                >
                  {detailDrawerState?.workOrderDetail?.externalCaseId}
                </Link> 
                */
        <Text type="secondary">
          {detailDrawerState?.workOrderDetail?.externalCaseId}
        </Text>
      }
      width={670}
      headerStyle={{
        padding: '16px',
        background: 'var(--color-white)',
      }}
      drawerStyle={{
        background: 'var(--color-gray-4)',
      }}
      bodyStyle={{
        padding: '16px 8px',
      }}
      onClose={handleOnClose}
      open={open}
      closable={false}
      destroyOnClose
    >
      {detailDrawerState?.loadingAppointmentDetail ? (
        <div className={styles.loaderContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionHeader}>
              <Text strong>{translation('current_appointment')}</Text>
            </div>
            <Appointment
              appointment={detailDrawerState?.appointmentDetail}
              selectedAppointment={selectedAppointment}
              appointmentStatusList={appointmentStatusList}
              isPlannerLinkVisible={true}
              refreshData={refreshAppointmentDetailData}
              isLast={detailDrawerState?.isLast}
            />
          </div>
          <div className={styles.sectionContainer}>
            <SectionCollapse
              title={intl.formatMessage({ id: 'time_change_log' })}
              defaultOpen={editAppointment}
              action={
                <AccessControl featureKey={IFeatures.progressWriteDate}>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    style={{ padding: 0, margin: 0, height: '22px' }}
                    onClick={handleEditMode}
                  >
                    {' '}
                    {translation('change_start_end')}
                  </Button>
                </AccessControl>
              }
            >
              {timeLogState?.loadingTimeLog ? (
                <Spin size="large" className={styles.spin} />
              ) : timeLogState?.error || timeLogState?.timeLog?.length === 0 ? (
                <ChangeLog
                  key="00000"
                  isLatest={true}
                  editMode={editAppointment}
                  handleEditMode={handleEditMode}
                  handleSubmit={handleSubmitTimeChangeLog}
                  timeLog={{
                    newStartDate:
                      detailDrawerState?.appointmentDetail?.appointmentDate,
                    newEndDate:
                      detailDrawerState?.appointmentDetail?.appointmentEndDate,
                    logDate: selectedAppointment?.createdAt,
                  }}
                  appointment={detailDrawerState.appointmentDetail}
                />
              ) : (
                timeLogState.timeLog.map((el, i) => (
                  <ChangeLog
                    key={i}
                    isLatest={i === 0}
                    editMode={i === 0 ? editAppointment : false}
                    handleEditMode={i === 0 ? handleEditMode : undefined}
                    handleSubmit={
                      i === 0 ? handleSubmitTimeChangeLog : undefined
                    }
                    timeLog={el}
                    appointment={detailDrawerState.appointmentDetail}
                  />
                ))
              )}
            </SectionCollapse>
          </div>
          {detailDrawerState?.appointmentDetail?.externalId && (
            <div className={styles.sectionContainer}>
              <StatusChangeLog
                appointmentExternalId={
                  detailDrawerState?.appointmentDetail?.externalId
                }
              />
            </div>
          )}
          {detailDrawerState?.appointmentDetail?.id && (
            <div className={styles.sectionContainer}>
              <CheckinLog
                appointmentId={detailDrawerState?.appointmentDetail?.id}
              />
            </div>
          )}
          <div className={styles.sectionContainer}>
            <SectionCollapse
              title={`${intl.formatMessage({ id: 'work_orders_details' })}`}
              defaultOpen
              /*               action={
                <Link
                  href={`${routes.workorder.pathWithParams(
                    rootPageRoute,
                    selectedAppointment?.externalCaseId ?? '',
                    selectedAppointment?.appointmentType?.name ?? ''
                  )}`}
                  target="_blank"
                >
                  <Button>{translation('workorder')}</Button>
                </Link>
              } */
            >
              <WorkOrder selectedAppointment={selectedAppointment} />
            </SectionCollapse>
          </div>
          {detailDrawerState?.appointments.length > 0 && (
            <div className={styles.sectionContainer}>
              <SectionCollapse
                title={`${intl.formatMessage({ id: 'all_appointments' })}`}
                defaultOpen
              >
                {detailDrawerState?.appointments.map(
                  (appointment: AppointmentType, i) => (
                    <Appointment
                      key={i}
                      appointment={appointment}
                      withDivider={
                        i !== detailDrawerState?.appointments.length - 1
                      }
                      isParallel={
                        (moment(
                          detailDrawerState?.appointmentDetail
                            ?.appointmentEndDate
                        ).isAfter(appointment?.appointmentDate) &&
                          moment(appointment?.appointmentEndDate).isAfter(
                            detailDrawerState?.appointmentDetail
                              ?.appointmentDate
                          )) ||
                        moment(
                          detailDrawerState?.appointmentDetail
                            ?.appointmentEndDate
                        ).isSame(appointment?.appointmentDate) ||
                        moment(appointment?.appointmentEndDate).isSame(
                          detailDrawerState?.appointmentDetail?.appointmentDate
                        )
                      }
                      isLast={appointment?.isLast}
                      refreshData={refreshAppointmentDetailData}
                    />
                  )
                )}
              </SectionCollapse>
            </div>
          )}
          {selectedAppointment?.appointmentType?.name === 'Montage' &&
            selectedAppointment?.delayedEndReason &&
            detailDrawerState?.appointmentDetail && (
              <div className={styles.sectionContainer} ref={delayedReasonRef}>
                <DelayReasonLog
                  appointmentDetail={detailDrawerState?.appointmentDetail}
                />
              </div>
            )}
          <div className={styles.sectionContainer} ref={noteRef}>
            <Notes
              notes={detailDrawerState?.workOrderDetail?.notes}
              selectedAppointment={selectedAppointment}
              editModeActive={editNotesActive}
              fetchAppointmentData={() => {
                setshouldReloadProgressApiCache(true);
                loadData();
                setScrollTarget('notes');
                setEditNotesActive(false);
              }}
            />
          </div>
        </>
      )}
    </Drawer>
  );
};

export default DetailDrawer;
