import { AnyAction } from 'redux';
import {
  AnswerCounts,
  appointmentsDetailsTypes,
  AppointmentsDetailsState,
  IStatus,
  IWorkTypeGroup,
  IWorkType,
  AppointmentType,
  Group,
  IAdditionalInfo,
} from '../../constants/types';

export const additionalInfosDict: any = {
  SF_SwitchOnApproval: 'Anlage Anschalten',
  SF_Hooktype: 'Dachhakentyp',
  SF_Modules: 'Anzahl der Module',
  WT_PvNennleistung: 'PV-Nennleistung',
  WT_Hersteller: 'Module - Hersteller & Typ',
  WT_AnzahlStrings: 'Anzahl Strings',
  WT_Strinplan: 'Stringplan',
  WT_FotoTypenschildWR: 'Foto Typenschild WR',
  WT_Speichergröße: 'Speichergröße',
  WT_PotischieneUKErdung: 'Potischiene UK Erdung',
  WT_ACMKabelPotischiene:
    '[ACM] Alle Kabel an Potentialausgleichschiene angeschlossen',
  WT_DCTiefenerderHauserdung: '[DC] Foto Tiefenerder/Hauserdung',
  WT_EMGDCTiefenerderHauserdung: '[DC-EMG] Foto Tiefenerder/Hauserdung',
  WT_ACMTiefenerderHauserdung: '[ACM] Foto Tiefenerder/Hauserdung',
  WT_ACWechselrichterKabel:
    '[AC] Sind alle AC-Kabel und DC-Strings an den Wechselrichter angeschlossen?',
  WT_DCWechselrichterKabel:
    '[DC] Sind alle AC-Kabel und DC-Strings an den Wechselrichter angeschlossen?',
  WT_Netzform: 'Welche Netzform liegt vor?',
  WT_Wallbox: 'Wallbox verbaut',
  SF_SpecialBuildingConditions: 'Besonderheiten Bau',
  WT_AnzahlVModule: 'Anzahl verbauter Module (WR Konfi)',
};

const DEFAULT_WORKTYPE_GROUP: IWorkTypeGroup[] = [
  {
    id: 'all',
    name: 'All',
  },
];

const initialState: AppointmentsDetailsState = {
  loading: false,
  error: null,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
  },
  relatedAppointments: [],
  answersData: [],
  formRevisions: {},
  reportGenerating: false,
  worktypeGroups: DEFAULT_WORKTYPE_GROUP,
  worktypes: [],
  additionalInfos: [],
};

const getStatusFromAnswerCount = (answerCounts: any): IStatus => {
  let status = IStatus.open;

  if (answerCounts.rejected > 0) {
    status = IStatus.rejected;
  } else if (
    answerCounts.open !== answerCounts.total &&
    answerCounts.invisible !== answerCounts.total
  ) {
    if (
      answerCounts.approvalPending <= 0 &&
      answerCounts.openApprovable <= 0 &&
      answerCounts.openRequired <= 0
    ) {
      status = IStatus.approved;
    } else if (
      (answerCounts.resubmitted > 0 ||
        answerCounts.inReview > 0 ||
        answerCounts.submitted > 0) &&
      answerCounts.openApprovable <= 0
    ) {
      status = IStatus.submitted;
    }
  }

  return status;
};

const updateWorktypeStatus = (worktypes: any): IWorkType[] => {
  return worktypes.map((worktype: any) => ({
    ...worktype,
    key: worktype.formRevision.workType.id,
    value: worktype.formRevision.workType.id,
    label: worktype.formRevision.name,
    title: worktype.formRevision.workType.name,
    status: getStatusFromAnswerCount(worktype.answerCounts),
  }));
};

const generateAnswerCounts = (worktypes: IWorkType[]) => {
  let answerCounts = {
    total: 0,
    open: 0,
    submitted: 0,
    resubmitted: 0,
    inReview: 0,
    approved: 0,
    rejected: 0,
    approvalPending: 0,
    openApprovable: 0,
    autoApproved: 0,
    openRequired: 0,
    questions: 0,
  };

  let status = IStatus.approved;

  worktypes.forEach((wt: IWorkType) => {
    (Object.keys(answerCounts) as Array<keyof AnswerCounts>).forEach(
      (key: keyof AnswerCounts) => {
        answerCounts[key] += wt.answerCounts![key] ?? 0;
      }
    );
  });

  status = getStatusFromAnswerCount(answerCounts);
  return { answerCounts, status };
};

const getStatusWeights = (status: string) => {
  switch (status) {
    case IStatus.rejected:
      return 5;
    case IStatus.inReview:
      return 4;
    case IStatus.reSubmitted:
      return 3;
    case IStatus.submitted:
      return 2;
    case IStatus.open:
      return 1;
    case IStatus.approved:
      return 0;
    default:
      return -1;
  }
};

const getAllGroupsStatus = (groups: Group[]) => {
  groups.sort((group1: Group, group2: Group) => {
    return getStatusWeights(group1.status) > getStatusWeights(group2.status)
      ? -1
      : 1;
  });

  return { status: groups[0]?.status };
};

const handleAdditionalInfos = (additionalInfos: IAdditionalInfo[]) => {
  let indexedMetadataKeyObj: any = {};
  additionalInfos.forEach((item: IAdditionalInfo) => {
    if (!indexedMetadataKeyObj[item.key]) {
      indexedMetadataKeyObj[item.key] = item;
    }
  });
  let arr: IAdditionalInfo[] = [];
  Object.keys(additionalInfosDict).forEach((item: string) => {
    if (indexedMetadataKeyObj[item]?.value) {
      arr.push({
        ...indexedMetadataKeyObj[item],
        title: additionalInfosDict[item],
      });
    }
  });
  return arr;
};

const initWorktypeGroupsData = (
  groups: any[],
  worktypes: IWorkType[],
  appointmentDetails: AppointmentType
): IWorkTypeGroup[] => {
  let workTypeGroup = [...DEFAULT_WORKTYPE_GROUP, ...groups].map(
    (group: any) => {
      let worktypeData =
        group.workTypes && group.workTypes.length
          ? worktypes.filter((wt: IWorkType) =>
              group.workTypes?.find((gwt: IWorkType) => gwt.id === wt.key)
            )
          : [...worktypes];

      let groupStatus = appointmentDetails.groupStatus?.find(
        (grp: any) => grp.groupId === group.id
      );

      if (group.id === 'all') {
        groupStatus = appointmentDetails.groupStatus
          ? {
              ...group,
              ...getAllGroupsStatus(appointmentDetails.groupStatus),
            }
          : {};
      }

      return {
        ...group,
        worktypes: worktypeData,
        ...generateAnswerCounts(worktypeData),
        ...groupStatus,
      };
    }
  );

  return workTypeGroup;
};

const appointmentsDetailsReducer = (
  state = initialState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA:
      return {
        ...state,
        userData: payload.response,
        error: null,
        loading: false,
      };

    case appointmentsDetailsTypes.GET_RELATED_APPOINTMENTS_DATA:
      const {
        appointmentDetails: userData,
        relatedAppointments,
        groups,
        worktypes,
      } = payload;
      const worktypesData: IWorkType[] = updateWorktypeStatus(worktypes);
      const worktypeGroups: IWorkTypeGroup[] = initWorktypeGroupsData(
        groups,
        worktypesData,
        userData
      );
      return {
        ...state,
        userData,
        relatedAppointments,
        worktypeGroups,
        worktypes: worktypesData,
        error: null,
        loading: false,
      };

    case appointmentsDetailsTypes.SET_DEFAULT_APPOINTMENTS_DETAILS_DATA:
      return {
        ...state,
        userData: undefined,
        answersData: [],
        formRevisions: {},
        worktypeGroups: DEFAULT_WORKTYPE_GROUP,
      };

    case appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_ANSWERS_DATA:
      const { response, pagination } = payload;
      return {
        ...state,
        answersData: response,
        pagination: {
          currentPage: pagination.CurrentPage,
          pageSize: pagination.PageSize,
          totalCount: pagination.TotalCount,
        },
        error: null,
        loading: false,
      };
    case appointmentsDetailsTypes.GET_FORM_REVISIONS_DATA:
      return {
        ...state,
        formRevisions: { ...state.formRevisions, ...payload },
        error: null,
        loading: false,
      };
    case appointmentsDetailsTypes.SET_LOADING_DETAILS:
      return {
        ...state,
        loading: payload,
      };

    case appointmentsDetailsTypes.SET_DEFAULT_PAGINATION:
      return {
        ...state,
        pagination: { ...initialState.pagination },
      };
    case appointmentsDetailsTypes.GET_APPOINTMENTS_DETAILS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case appointmentsDetailsTypes.GET_RELATED_APPOINTMENTS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case appointmentsDetailsTypes.SET_GENERATE_REPORT:
      return {
        ...state,
        reportGenerating: payload,
      };

    case appointmentsDetailsTypes.RESET_APPOINTMENTS_DETAILS_ERRORS:
      return {
        ...state,
        error: null,
      };

    case appointmentsDetailsTypes.RESET_APPOINTMENTS_DETAILS_STATE:
      return {
        loading: false,
        error: null,
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalCount: 0,
        },
        relatedAppointments: [],
        answersData: [],
        formRevisions: {},
        reportGenerating: false,
        worktypeGroups: DEFAULT_WORKTYPE_GROUP,
        worktypes: [],
        additionalInfos: [],
      };

    case appointmentsDetailsTypes.GET_ADDITIONAL_INFOS:
      return {
        ...state,
        additionalInfos: handleAdditionalInfos(payload.response),
      };

    default:
      return state;
  }
};

export default appointmentsDetailsReducer;
