import { FC } from 'react';

interface Props {
  style?: object;
  size?: string | number;
  onClick?: any;
}

export const WifiIconNotActiveSvg: FC<Props> = ({
  style,
  size = '22',
  onClick,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 26"
    fill="none"
    style={style}
    onClick={onClick}
  >
    <g filter="url(#filter0_d_4498_138001)">
      <rect x="0.5" width="24" height="24" rx="12" fill="#F5F5F5" />
      <path
        d="M12.5 16H12.5067"
        stroke="#E2E2E4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6143 14.1147C11.1143 13.6148 11.7925 13.334 12.4996 13.334C13.2067 13.334 13.8849 13.6148 14.3849 14.1147"
        stroke="#E2E2E4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.72852 12.2282C9.22376 11.7329 9.81172 11.3401 10.4588 11.072C11.1059 10.804 11.7994 10.666 12.4998 10.666C13.2003 10.666 13.8938 10.804 14.5409 11.072C15.188 11.3401 15.7759 11.7329 16.2712 12.2282"
        stroke="#E2E2E4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.84375 10.3435C9.96775 7.21883 15.0331 7.21883 18.1771 10.3435"
        stroke="#E2E2E4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="#D9D9D9" />
    </g>
    <defs>
      <filter
        id="filter0_d_4498_138001"
        x="0.5"
        y="0"
        width="24"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4498_138001"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4498_138001"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
