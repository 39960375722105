import { FC, useEffect, useMemo } from 'react';
import { Switch, Form, Typography, Tooltip } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemIsPrefill: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
  isPublishedItem,
}) => {
  const formInstance = Form.useFormInstance();
  const metadata = Form.useWatch('metadata');
  const publishedItemValues = useMemo(
    () => ({
      isPrefill: fieldChanges?.isPrefill.publishedItem,
    }),
    [fieldChanges]
  );

  const handleChange = (value: boolean) => {
    if (value) formInstance.setFieldsValue({ approvalRequired: false });
  };

  useEffect(() => {
    formInstance.validateFields(['isPrefill']);
  }, [metadata?.defaultValueKey, formInstance]);

  return (
    <Form.Item
      name="isPrefill"
      valuePropName="checked"
      label={
        fieldChanges?.isPrefill.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('read_only_tooltip')}>
              <span> {translation('read_only')} </span>{' '}
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemIsPrefill />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('read_only_tooltip')}>
            <span> {translation('read_only')} </span>{' '}
          </Tooltip>
        )
      }
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            const metadata = getFieldValue('metadata');
            if (value && !metadata?.defaultValueKey && !isPublishedItem) {
              return Promise.reject(
                new Error('Please add a metadata default value key')
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Switch disabled={!editMode} onChange={handleChange} />
    </Form.Item>
  );
};

export default FormItemIsPrefill;
