import React from 'react';

export const DiplomatMTWIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="Frame" clipPath="url(#clip0_5371_165915)">
      <path
        id="Vector"
        d="M2 6.00033C2 5.6467 2.14048 5.30756 2.39052 5.05752C2.64057 4.80747 2.97971 4.66699 3.33333 4.66699H12.6667C13.0203 4.66699 13.3594 4.80747 13.6095 5.05752C13.8595 5.30756 14 5.6467 14 6.00033V12.0003C14 12.3539 13.8595 12.6931 13.6095 12.9431C13.3594 13.1932 13.0203 13.3337 12.6667 13.3337H3.33333C2.97971 13.3337 2.64057 13.1932 2.39052 12.9431C2.14048 12.6931 2 12.3539 2 12.0003V6.00033Z"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M5.33325 4.66667V3.33333C5.33325 2.97971 5.47373 2.64057 5.72378 2.39052C5.97383 2.14048 6.31296 2 6.66659 2H9.33325C9.68687 2 10.026 2.14048 10.2761 2.39052C10.5261 2.64057 10.6666 2.97971 10.6666 3.33333V4.66667"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M8 8V8.00667"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M2 8.66699C3.86105 9.60478 5.91602 10.0933 8 10.0933C10.084 10.0933 12.1389 9.60478 14 8.66699"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5371_165915">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
