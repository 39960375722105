import { asyncLogin, setToken } from './authenticationActions';
import { MiddlewareAPI, Dispatch, Middleware } from 'redux';

export const jwt: Middleware<Dispatch> = ({ dispatch }: MiddlewareAPI) => {
  return (next) => (action) => {
    if (typeof action === 'function') {
      return asyncLogin()
        .then((data) => {
          return dispatch(setToken(data.tokenBE, data.user));
        })
        .then(() => next(action));
    }

    return next(action);
  };
};
