import { FC, useMemo } from 'react';
import { Form, Input, Tooltip, Typography } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FieldItemAdditionalData: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
}) => {
  const publishedItemValues = useMemo(
    () => ({
      additionalFieldData: fieldChanges?.additionalFieldData.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      name="additionalFieldData"
      label={
        fieldChanges?.additionalFieldData.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('protocol_text_tooltip')}>
              <span>{translation('protocol_text')} </span>{' '}
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FieldItemAdditionalData />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('protocol_text_tooltip')}>
            <span>{translation('protocol_text')} </span>{' '}
          </Tooltip>
        )
      }
    >
      <Input disabled={!editMode} />
    </Form.Item>
  );
};

export default FieldItemAdditionalData;
