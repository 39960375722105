import { Space, Typography } from 'antd';
import styles from './jobDetails.module.scss';
import {
  AcHealingMTWIcon,
  AccountManagerMTWIcon,
  InstallationPLCaseMTWIcon,
  WorkOrderMTWIcon,
} from '../../../../components/icons';
import { LinkMTWIcon } from '../../../../components/icons/linkMTW';
import { config } from '../../../../config';
import translation from '../../../../i18n/translation';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
const { Text, Link } = Typography;

interface CustomerInfoProps {
  workOrder?: any;
}

const JobDetails: React.FC<CustomerInfoProps> = ({ workOrder }) => {
  return (
    <Space direction="vertical" size={0} style={{ width: '100%', padding: 0 }}>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <WorkOrderMTWIcon />
          </div>
          <Text type="secondary">Work order</Text>
        </Space>
        <Space>
          <Text strong>{workOrder?.workOrderId}</Text>
          <Link
            id="ga4-detail-drawer-sf-link"
            href={`${config.sfIntegrationUrl}/r/WorkOrder/${workOrder?.workOrderId}/view`}
            target="_blank"
            className={styles.flexCenter}
          >
            <LinkMTWIcon />
          </Link>
        </Space>
      </div>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <InstallationPLCaseMTWIcon />
          </div>
          <Text type="secondary">Installation PL Case</Text>
        </Space>
        <Space>
          <Text strong> {workOrder?.plCaseId}</Text>
          {workOrder?.plCaseId && (
            <Link
              href={`${config.sfIntegrationUrl}/r/Case/${workOrder?.plCaseId}/view`}
              target="_blank"
              className={styles.flexCenter}
            >
              <LinkMTWIcon />
            </Link>
          )}
        </Space>
      </div>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <AccountManagerMTWIcon />
          </div>
          <Text type="secondary">Account Manager</Text>
        </Space>
        <Text strong>{workOrder?.customer?.account?.ownerName}</Text>
      </div>
      <div className={styles.detailWrapper}>
        <Space>
          <div className={styles.flexCenter}>
            <AcHealingMTWIcon />
          </div>
          <Text type="secondary">{translation('ac_healing_necessary')}</Text>
        </Space>
        <Text style={{ paddingRight: '8px' }}>
          {workOrder?.isAcHealingRequired ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleOutlined style={{ color: '#A9A9A9' }} />
          )}
        </Text>
      </div>
    </Space>
  );
};

export default JobDetails;
