import { FC, useMemo } from 'react';
import { Form, Switch, Tooltip, Typography } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemRequired: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const publishedItemValues = useMemo(
    () => ({
      required: fieldChanges?.required.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      name="required"
      valuePropName="checked"
      label={
        fieldChanges?.required.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('required_tooltip')}>
              <span>{translation('required')} </span>{' '}
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemRequired />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('required_tooltip')}>
            <span>{translation('required')} </span>{' '}
          </Tooltip>
        )
      }
    >
      <Switch disabled={!editMode} />
    </Form.Item>
  );
};

export default FormItemRequired;
