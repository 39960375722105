import { Button } from 'antd';
import translation from '../../i18n/translation';
import { CraftosSvg } from '../icons/craftosSVG';
import style from './errorPage.module.scss';

interface Props {
  title?: string;
  error?: { message?: string };
  additionalText?: string;
  handleRedirect?: () => void;
}

const ErrorPage: React.FC<Props> = ({
  title = '',
  error,
  additionalText = 'error_boundary_text',
  handleRedirect,
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.leftContainer}>
        <div className={style.textContainer}>
          <h2 className={style.p0m0}>Ooops!</h2>
          <h2>{title ? title : translation('an_error_occured')}</h2>
          <p>{translation(additionalText)}.</p>
          <p className={style.secondaryText}>
            {error?.message
              ? error.message
              : translation('error_boundary_title')}
          </p>
          {handleRedirect && (
            <Button
              className={style.backToHomepageButton}
              type="primary"
              onClick={handleRedirect}
            >
              {translation('back_to_homepage')}
            </Button>
          )}
        </div>
      </div>
      <div className={style.rightContainer}>
        <CraftosSvg />
      </div>
    </div>
  );
};

export default ErrorPage;
