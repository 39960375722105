import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Divider,
  Col,
  Typography,
} from 'antd';
import BaseLogin from '../../components/baseLogin';
import styles from './signin.module.scss';

const SignIn = () => {
  const { Text } = Typography;
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 8 },
      lg: { span: 8 },
    },
  };
  return (
    <BaseLogin>
      <Form
        name="normal_login"
        className={styles.loginForm}
        initialValues={{ remember: true }}
        layout="vertical"
        requiredMark="optional"
        {...layout}
      >
        <Button
          type="primary"
          className={styles.loginFormButton}
          style={{ width: '100%' }}
        >
          CONTINUE WITH ENPAL ACCOUNT
        </Button>

        <Divider plain>OR</Divider>

        <Form.Item
          name="username"
          label={<Text strong>Email address</Text>}
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email address"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={<Text strong>Password</Text>}
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <a className={styles.loginFormForgot} href="/">
            Forgot your password?
          </a>
        </Form.Item>

        <Form.Item>
          <Row>
            <Col span={12}>
              <Checkbox>Remember me</Checkbox>
            </Col>

            <Col span={8} offset={4}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.loginFormButton}
                style={{ width: '100%' }}
              >
                Log in
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Divider />

        <Row justify="center" align="middle">
          <Text strong>Don’t have an account?</Text>
          <Button
            type="default"
            className={styles.loginFormButton}
            style={{ width: '100%' }}
          >
            SIGN UP FOR CRAFTOS
          </Button>
        </Row>
      </Form>
    </BaseLogin>
  );
};

export default SignIn;
