import { FC, useEffect, useState } from 'react';
import { CONSTANTS, getTranslation } from 'craftos-ui';
import { Typography } from 'antd';
import styles from './delayReasonItem.module.scss';
const { Text } = Typography;

interface Props {
  delayedEndReason: string;
}

const DelayReasonItem: FC<Props> = ({ delayedEndReason }) => {
  const [reasonArray, setReasonArray] = useState<string[]>([]);
  useEffect(() => {
    if (delayedEndReason) {
      setReasonArray(
        delayedEndReason.split(CONSTANTS.REASON_SEPARATOR_DELIMITER)
      );
    }
  }, [delayedEndReason]);
  return (
    <div className={styles.delayReasonWrapper}>
      <Text>
        {reasonArray[0]?.length > 0 && getTranslation(reasonArray[0]) + '. '}
        {reasonArray[1]?.length > 0 &&
          reasonArray[0] !== reasonArray[1] &&
          getTranslation(reasonArray[1]) + '.'}
      </Text>

      {reasonArray[2]?.length > 0 && (
        <Text>{getTranslation(reasonArray[2])}</Text>
      )}
    </div>
  );
};

export default DelayReasonItem;
