import moment from 'moment';
import { AnyAction } from 'redux';
import { CheckedInUsersActions, CheckedInUsersState } from './types';

const initialState: CheckedInUsersState = {
  loadingCheckedInUsers: false,
  checkedInUsersList: [],
  error: null,
};

export const checkedInUsersReducer = (
  state: CheckedInUsersState = initialState,
  action: AnyAction
): CheckedInUsersState => {
  switch (action.type) {
    case CheckedInUsersActions.FETCH_CHECKED_IN_USERS_START:
      return {
        ...state,
        loadingCheckedInUsers: true,
      };
    case CheckedInUsersActions.FETCH_CHECKED_IN_USERS_SUCCESS:
      return {
        ...state,
        loadingCheckedInUsers: false,
        checkedInUsersList: action.payload.response.sort(
          (checkInA: any, checkInB: any) =>
            moment(checkInB.checkInTime).diff(moment(checkInA.checkInTime))
        ),
      };
    case CheckedInUsersActions.FETCH_CHECKED_IN_USERS_FAILED:
      return {
        ...state,
        loadingCheckedInUsers: false,
        error: action.payload,
      };
    case CheckedInUsersActions.RESET_STATE:
      return {
        loadingCheckedInUsers: false,
        checkedInUsersList: [],
        error: null,
      };
    default:
      return state;
  }
};
