import { AnyAction } from 'redux';
import { DelayReasonLogActions, DelayReasonLogState } from './types';

const initialState: DelayReasonLogState = {
  loadingDelayReasonLog: false,
  delayReasonLog: [],
  error: null,
};

export const delayReasonLogReducer = (
  state: DelayReasonLogState = initialState,
  action: AnyAction
): DelayReasonLogState => {
  switch (action.type) {
    case DelayReasonLogActions.FETCH_DELAY_REASON_LOG_START:
      return {
        ...state,
        loadingDelayReasonLog: true,
      };
    case DelayReasonLogActions.FETCH_DELAY_REASON_LOG_SUCCESS:
      return {
        ...state,
        loadingDelayReasonLog: false,
        delayReasonLog: action.payload.response.changes.reverse() ?? [],
      };
    case DelayReasonLogActions.FETCH_DELAY_REASON_LOG_FAILED:
      return {
        ...state,
        loadingDelayReasonLog: false,
        error: action.payload,
      };
    case DelayReasonLogActions.RESET_STATE:
      return {
        loadingDelayReasonLog: false,
        delayReasonLog: [],
        error: null,
      };
    default:
      return state;
  }
};
