import { FC, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Switch, Form, Select, Typography, Tooltip } from 'antd';
import { RootState } from '../../../store';
import { getOptions } from '../utils';
import { FieldItemProps, Option, FormDetailParams } from '../types';

import PublishedItem from './PublishedItem';
import FormItemAutoValidation from './FormItemAutoValidation';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const approvalTypeDict = {
  Default: 'Four eyes',
  SingleApprover: 'Two eyes',
};

const FormItemApprovalReq: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
}) => {
  const { fieldId }: FormDetailParams = useParams();
  const publishedFlatList = useSelector(
    (state: RootState) => state.formBuilderDetail.publishedFlatList
  );
  const approvalRequired = Form.useWatch('approvalRequired');
  const isPrefill = Form.useWatch('isPrefill');
  const [approvalOptions, setApprovalOptions] = useState<Option[]>([]);

  const publishedItemValues = useMemo(
    () => ({
      approvalRequired: fieldChanges?.approvalRequired.publishedItem,
      approvalType: publishedFlatList?.get(fieldId!)?.['approvalType'],
    }),
    [fieldChanges, publishedFlatList, fieldId]
  );

  useEffect(() => {
    setApprovalOptions(getOptions(approvalTypeDict));
  }, []);

  return (
    <>
      <Form.Item
        name="approvalRequired"
        valuePropName="checked"
        label={
          fieldChanges?.approvalRequired.hasChanges ? (
            <Text mark>
              <Tooltip title={translation('approval_required_tooltip')}>
                <span> {translation('approval_required')} </span>{' '}
              </Tooltip>
              {
                <PublishedItem initialValues={publishedItemValues}>
                  <FormItemApprovalReq />
                </PublishedItem>
              }
            </Text>
          ) : (
            <Tooltip title={translation('approval_required_tooltip')}>
              <span> {translation('approval_required')} </span>{' '}
            </Tooltip>
          )
        }
      >
        <Switch disabled={!editMode || isPrefill} />
      </Form.Item>
      {approvalRequired && (
        <Form.Item
          name="approvalType"
          label={
            <Tooltip
              placement="topLeft"
              title={translation('approval_type_tooltip')}
            >
              <span>{translation('approval_type')} </span>
            </Tooltip>
          }
          rules={[{ required: true, message: 'Select approval type' }]}
        >
          <Select disabled={!editMode} options={approvalOptions} />
        </Form.Item>
      )}
      {approvalRequired && (
        <FormItemAutoValidation
          editMode={editMode}
          fieldChanges={fieldChanges}
        />
      )}
    </>
  );
};

export default FormItemApprovalReq;
