import { LOCALES } from '../locales';

const french = {
  [LOCALES.FRENCH]: {
    all: 'Tout',
    add: 'Ajouter',
    address: 'Adresse',
    appointment: 'Rendez-vous',
    approved: 'Approuvé',
    attention_error: "Erreur d'attention",
    acceptance_criteria: "Critères d'acceptation",
    btn_approve: 'Approuver',
    btn_reject: 'Rejeter',
    btn_startNow: 'Commencez maintenant',
    btn_goBack: 'Retourner',
    btn_escalate: 'Intensifier',
    cancel: 'Annuler',
    create_first_form: 'Créez votre premier formulaire',
    comment_placeholder: '600 caractères maximum',
    collapse_all_sections: 'Réduire toutes les sections',
    change_order: "Change l'ordre",
    copy_form: 'Copier le formulaire',
    create_form: 'Créer un formulaire',
    comment_modal_visible_text:
      "Veuillez écrire à l'artisan pourquoi vous rejetez son travail et ce qu'il doit faire pour corriger l'erreur",
    comment_modal_confirmation_visible_title:
      'Cette étape a déjà été approuvée !',
    comment_modal_confirmation_visible_text:
      "Voulez-vous vraiment rejeter l'étape ? Si oui, veuillez écrire une explication détaillée pour le bricoleur afin qu'il puisse comprendre pourquoi vous changez le statut.",
    delete_form: 'Supprimer le formulaire',
    date_created: 'Date créée',
    date_modified: 'Date modifiée',
    date_updated: 'Date de mise à jour',
    description: 'Description',
    description_optional: 'Description (facultatif)',
    edit_from: 'Modifier à partir de',
    error_notification_description:
      "Très probablement cette forme n'existe pas encore.",
    error_boundary_title: "Nos experts corrigent l'erreur.",
    error_boundary_text: 'Désolé pour le désagrément temporaire',
    expand_all_sections: 'Développer toutes les sections',
    field_name: 'Nom de domaine',
    form_builder: 'Générateur de formulaires',
    generate_report: 'Générer un rapport',
    got_it: "J'ai compris",
    include_archived: 'Inclure archivé',
    index_title: "Titre de l'index*",
    name: 'Nom',
    open: 'Ouvert',
    wo_dashboard: 'Work Order Dashboard',
    question_appointment_take_place: 'Le rendez-vous a eu lieu?*',
    re_submitted: 'Re Soumis',
    rejected: 'Rejeté',
    roof_inside: "Toit à l'intérieur",
    roof_shape: 'Forme de toit ?',
    roof_shape_description:
      'selon “Est-ce que le rendez-vous a eu lieu?“ la valeur est “Oui“',
    save: 'Sauvegarder',
    search: 'Rechercher',
    subsection: 'Sous-section',
    submitted: 'Soumis',
    status: 'Statut',
    total: 'Le total',
    today: "Aujourd'hui",
    work_types: 'Types de travail',
    work_type: 'Type de travail*',
    warning: 'Avertissement',
    stopped: 'Arrêté',
    active: 'Actif',
    closed: 'Fermé',
    case_id: 'Numéro du client',
    enter_customer_name_or_case_id: 'Entrez le nom ou le numéro du client',
    escalate_notify: 'Informations et lien copiés dans le presse-papiers',
    close_appointment: 'Fermer le rendez-vous',
    close_appointment_message:
      'Êtes-vous sûr de vouloir fermer le rendez-vous?',
    reopen_appointment: 'Rouvrir le rendez-vous',
    reopen_appointment_message:
      'Êtes-vous sûr de vouloir rouvrir le rendez-vous ?',
    reset_selection: 'Réinitialiser la sélection',
    select_teams: 'Sélectionner les équipes',
    edit_images: "Images d'édition",
    close: 'Fermer',
    edit_images_confirm_close_message:
      "Êtes-vous sûr de vouloir quitter le mode d'édition ? Toutes les modifications seront perdues.",
    images_selected: 'Images sélectionnées',
    submit_selection: 'Soumettre la sélection',
    image_preview: "Aperçu de l'image",
    select_image: "Sélectionner l'image",
    remove_image_from_selection: "Supprimer l'image de la sélection",
    error: 'Erreur',
    update_images: 'Mise à jour des images',
    zoom: 'Zoom',
    rotation: 'Rotation',
    apply_changes_and_select_image:
      "Appliquer les changements et sélectionner l'image",
    apply_changes: 'Appliquer les changements',
    questions: 'Questions',
  },
};

export default french;
