import { AnyAction } from 'redux';
import { AppointmentTypesActionsTypes, AppointmentTypeParams } from './types';
import { WorkTypesGroup } from './types';

interface AppointmentTypesState {
  loading: boolean;
  appointmentTypes: AppointmentTypeParams[];
  appointmentType?: AppointmentTypeParams | null;
  groups: WorkTypesGroup[];
  workTypes?: any;
  error?: any;
}

const appointmentTypesInitialState: AppointmentTypesState = {
  loading: false,
  appointmentTypes: [],
  appointmentType: null,
  workTypes: null,
  groups: [],
  error: null,
};

const serializeAppointmentTypesAndGroups = ({
  appointmentTypes,
  groups,
}: {
  appointmentTypes: AppointmentTypeParams[];
  groups: any;
}) => {
  appointmentTypes.forEach((obj: AppointmentTypeParams) => {
    obj.groups = groups.filter(
      (item: WorkTypesGroup) => item.appointmentTypeId === obj.id
    );
  });
  return { appointmentTypes, groups };
};

export const appointmentTypesReducer = (
  state: AppointmentTypesState = appointmentTypesInitialState,
  action: AnyAction
): AppointmentTypesState => {
  switch (action.type) {
    case AppointmentTypesActionsTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AppointmentTypesActionsTypes.FETCH_APPOINTMENT_TYPES_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...serializeAppointmentTypesAndGroups(action.payload),
      };
    case AppointmentTypesActionsTypes.FETCH_SELECTED_APPOINTMENT_TYPE_GROUP_SUCCESS:
      let { groups, appointmentTypes } = serializeAppointmentTypesAndGroups(
        action.payload
      );
      return {
        ...state,
        loading: false,
        groups,
        appointmentTypes,
        appointmentType: appointmentTypes.find(
          (ap: any) => ap.id === action.payload.id
        ),
      };
    case AppointmentTypesActionsTypes.FETCH_APPOINTMENT_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AppointmentTypesActionsTypes.FETCH_ALL_WORK_TYPES_SUCCESS:
      const { response: workTypesResponse } = action.payload;
      return {
        ...state,
        workTypes: workTypesResponse,
      };
    case AppointmentTypesActionsTypes.RESET_APPOINTMENT_TYPES_GROUPS:
      return {
        loading: state.loading,
        appointmentTypes: [],
        appointmentType: null,
        workTypes: [],
        groups: [],
        error: null,
      };
    default:
      return state;
  }
};
