import React from 'react';
import { Badge, Tag, Timeline, Typography } from 'antd';
import * as routes from '../../router/routes';
import styles from './fieldDetailsSidebar.module.scss';
import Sidebar from '../../components/sidebar/sidebar';
import { getFormFieldLabel, getStatusColor } from '../../utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { IStatus } from '../../constants/types';
import Title from 'antd/lib/typography/Title';
import translation from '../../i18n/translation';
import SectionCollapse from '../formFieldAnswer/components/sectionCollapse';

interface IFieldDetailsSidebar {
  fieldsList: [];
  worktypeName: string;
  formRevisions: any;
}

const FieldDetailsSidebar: React.FC<IFieldDetailsSidebar> = ({
  fieldsList = [],
  worktypeName,
  formRevisions,
}) => {
  const { Text, Link } = Typography;

  const navigate = useNavigate();
  const params: {
    page?: string;
    appointId?: string;
    fieldId?: string;
    assignmentId?: string;
  } = useParams();

  const generateSectionedTimeline = () => {
    const sectionedFieldObject: any = {};
    fieldsList.forEach((field: any) => {
      const subsectionFieldName = getFormFieldLabel(
        formRevisions,
        field.formFieldId,
        field.formInstance.formRevisionId
      );
      if (!sectionedFieldObject[subsectionFieldName.section]) {
        sectionedFieldObject[subsectionFieldName.section] = [
          {
            subsectionFieldName: { ...subsectionFieldName },
            field: { ...field },
          },
        ];
      } else {
        sectionedFieldObject[subsectionFieldName.section] = [
          ...sectionedFieldObject[subsectionFieldName.section],
          {
            subsectionFieldName: { ...subsectionFieldName },
            field: { ...field },
          },
        ];
      }
    });
    return Object.keys(sectionedFieldObject).map((item, i) => {
      let isSelected = sectionedFieldObject[item].find(
        (timeline: any) => timeline.field.id === params.fieldId
      );
      return (
        <SectionCollapse
          key={i}
          title={item}
          defaultOpen={isSelected}
          style={{
            color: isSelected ? '#000' : 'rgba(0, 0, 0, 0.45)',
          }}
        >
          <Timeline className={styles.timelineContainer}>
            {sectionedFieldObject[item].length &&
              sectionedFieldObject[item].map(
                ({ subsectionFieldName, field }: any) => {
                  return params.fieldId === field.id ? (
                    <Timeline.Item
                      key={field.id}
                      className={styles.selectedItem}
                      dot={
                        <ArrowRightOutlined
                          style={{
                            color: getStatusColor(field.status, true),
                          }}
                        />
                      }
                    >
                      <Text strong className={styles.timelineText}>
                        {subsectionFieldName?.formField?.label}{' '}
                      </Text>
                      {field.status === IStatus.inReview ? (
                        <Tag color="warning">{translation('two_review')}</Tag>
                      ) : null}
                    </Timeline.Item>
                  ) : (
                    <Timeline.Item
                      color={getStatusColor(field.status)}
                      key={field.id}
                      dot={
                        <Badge
                          className={styles.timelineDot}
                          color={getStatusColor(field.status, true)}
                        />
                      }
                    >
                      <Link
                        onClick={() => {
                          if (params.assignmentId) {
                            navigate({
                              pathname: routes.workTypeAssignment.pathWithParams(
                                params.appointId!,
                                params.assignmentId,
                                field.id
                              ),
                            });
                          } else {
                            navigate({
                              pathname: routes.workType.pathWithParams(
                                params.page!,
                                params.appointId!,
                                field.id
                              ),
                            });
                          }
                        }}
                      >
                        <Text type="secondary" className={styles.timelineText}>
                          {subsectionFieldName?.formField?.label}{' '}
                        </Text>
                        {field.status === IStatus.inReview ? (
                          <Tag color="warning">{translation('two_review')}</Tag>
                        ) : null}
                      </Link>
                    </Timeline.Item>
                  );
                }
              )}
          </Timeline>
        </SectionCollapse>
      );
    });
  };

  return (
    <Sidebar width={290} sider="left" breakpoint="sm">
      {worktypeName !== 'MVT' && worktypeName !== 'Separater Zählertausch' ? (
        <div className={styles.container}>
          <Title className={styles.worktypeTitle} level={5}>
            {worktypeName}
          </Title>
          <Timeline className={styles.timelineContainer}>
            {fieldsList &&
              fieldsList.map((field: any) => {
                const subsectionFieldName = getFormFieldLabel(
                  formRevisions,
                  field.formFieldId,
                  field.formInstance.formRevisionId
                );
                return params.fieldId === field.id ? (
                  <Timeline.Item
                    key={field.id}
                    className={styles.selectedItem}
                    dot={
                      <ArrowRightOutlined
                        style={{ color: getStatusColor(field.status, true) }}
                      />
                    }
                  >
                    <Text strong className={styles.timelineText}>
                      {subsectionFieldName?.formField?.label}
                    </Text>
                    {field.status === IStatus.inReview ? (
                      <Tag color="warning">{translation('two_review')}</Tag>
                    ) : null}
                  </Timeline.Item>
                ) : (
                  <Timeline.Item
                    color={getStatusColor(field.status)}
                    key={field.id}
                    dot={
                      <Badge
                        className={styles.timelineDot}
                        color={getStatusColor(field.status, true)}
                      />
                    }
                  >
                    <Link
                      onClick={() => {
                        if (params.assignmentId) {
                          navigate({
                            pathname: routes.workTypeAssignment.pathWithParams(
                              params.appointId!,
                              params.assignmentId,
                              field.id
                            ),
                          });
                        } else {
                          navigate({
                            pathname: routes.workType.pathWithParams(
                              params.page!,
                              params.appointId!,
                              field.id
                            ),
                          });
                        }
                      }}
                    >
                      <Text type="secondary" className={styles.timelineText}>
                        {subsectionFieldName?.formField?.label}
                      </Text>
                      {field.status === IStatus.inReview ? (
                        <Tag color="warning">{translation('two_review')}</Tag>
                      ) : null}
                    </Link>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </div>
      ) : (
        <div className={styles.container}>{generateSectionedTimeline()}</div>
      )}
    </Sidebar>
  );
};

export default FieldDetailsSidebar;
