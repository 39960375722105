export enum FormBuilderTableActions {
  FETCH_FORMS_START = 'FETCH_FORMS_START',
  FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS',
  FETCH_FORMS_FAILED = 'FETCH_FORMS_FAILED',
}

export type FormStatus = 'draft' | 'published' | '';

export interface FetchFormsReqProps {
  pageNumber: number;
  pageSize: number;
  sortedBy: string;
  status: FormStatus;
  search: string;
}

export interface Form {
  formId: string;
  status: string;
  revision: number;
  name: string;
  description: string;
  publishedAt: null;
  formStatus: string;
  formHandle: string;
  workType: WorkType;
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: AtedBy;
  updatedBy: AtedBy;
}

export interface AtedBy {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

export interface WorkType {
  id: string;
  name: string;
  description: string;
}

export interface Sorter {
  column: SorterColumn;
  order: string;
  field: string;
  columnKey: string;
}

export interface SorterColumn {
  title: any;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

export interface WorkType {
  name: string;
  description: string;
  recordStatus: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: AtedBy;
  updatedBy: AtedBy;
}
