import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Space, Typography, Row, Col, Divider } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { RootState } from '../../../../store';
import styles from '../detailDrawer.module.scss';
import NoteForm from './noteForm';
import AccessControl from '../../../../components/accessControl';
import { IFeatures } from '../../../../store/rolesAndPrivileges';
import { useIntl } from 'react-intl';

const { Text } = Typography;

interface Props {
  fetchAppointmentData: () => void;
  note: any;
  showDivider?: boolean;
  showServiceAppointmentType?: boolean;
}

const Note: FC<Props> = ({
  fetchAppointmentData,
  note,
  showDivider = true,
  showServiceAppointmentType = true,
}) => {
  const intl = useIntl();
  const [editMode, setEditMode] = useState(false);
  const userName = useSelector(
    (state: RootState) => state.authentication?.loginUser?.username
  );
  const handleEditMode = (mode: boolean) => {
    setEditMode(mode);
  };

  return (
    <Row wrap={false}>
      <Col flex="auto">
        {showDivider && <Divider style={{ width: '100%', margin: '0' }} />}

        <Col className={styles.notesItemWrapper}>
          <Space style={{ margin: '8px 0', width: '100%', flexWrap: 'wrap' }}>
            <Text type="secondary">{note?.createdBy?.name}</Text>
            <Text disabled>
              {intl.formatDate(note?.createdAt, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}{' '}
              {intl.formatDate(note?.createdAt, {
                timeStyle: 'short',
                hour12: false,
              })}
            </Text>
            {note?.createdAt !== note?.updatedAt && (
              <Text disabled italic>
                {intl.formatMessage({ id: 'edited' })}{' '}
                {intl.formatDate(note?.updatedAt, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}{' '}
                {intl.formatDate(note?.updatedAt, {
                  timeStyle: 'short',
                  hour12: false,
                })}
              </Text>
            )}
            {showServiceAppointmentType && (
              <div className={styles.serviceAppointmentTypeTag}>
                {note?.serviceAppointmentType}
              </div>
            )}
          </Space>
          {editMode ? (
            <NoteForm
              editMode={editMode}
              handleEditMode={handleEditMode}
              updateMode={true}
              fetchAppointmentData={fetchAppointmentData}
              selectedNote={note}
            />
          ) : (
            <Text>{note?.text}</Text>
          )}
        </Col>

        {userName === note?.createdBy?.email && (
          <div>
            <AccessControl featureKey={IFeatures.progressWriteMetadata}>
              <Button
                className={styles.noteEditButton}
                type="link"
                icon={editMode ? <CloseOutlined /> : <EditOutlined />}
                onClick={() => handleEditMode(!editMode)}
              >
                {editMode
                  ? intl.formatMessage({ id: 'close' })
                  : intl.formatMessage({ id: 'edit' })}
              </Button>
            </AccessControl>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Note;
