import { Row, Layout, Image } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import styles from './baselogin.module.scss';

const BaseLogin = (props: any) => {
  return (
    <Layout className={styles.loginLayout}>
      <Header className={styles.headers}>
        <Row justify="center" align="middle">
          <Image width={52} preview={false} src="../logo192.png" />
          <Title level={4} style={{ margin: '0 5px' }}>
            CraftOS
          </Title>
        </Row>
      </Header>
      <Content>
        <Row
          justify="space-around"
          align="middle"
          style={{ minHeight: '80vh' }}
        >
          {props.children}
        </Row>
      </Content>
    </Layout>
  );
};

export default BaseLogin;
