import { FC, useEffect, useState } from 'react';
import { Dropdown, Space } from 'antd';
import StatusTag from '../statusTag';
import {
  IAppointmentStatus,
  AppointmentType,
  IAppointmentStatusType,
} from '../../constants/types';
import translation from '../../i18n/translation';
import { useIntl } from 'react-intl';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { CONSTANTS, ICancellationReasons } from 'craftos-ui';
import { getAppointmentStatusData } from '../../utils/helpers';
import { DownOutlined } from '@ant-design/icons';
import WidgetDelayAndStatusModal from '../widgetDelayAndStatusModal';

const IGNORE_STATUSES = [
  IAppointmentStatus.cancelled,
  IAppointmentStatus.none,
  IAppointmentStatus.dispatched,
];
const IGNORE_WORKORDER_STATUSES = [
  IAppointmentStatus.cancelled,
  IAppointmentStatus.none,
  IAppointmentStatus.dispatched,
  IAppointmentStatus.installationPreparation,
  IAppointmentStatus.onHold,
  IAppointmentStatus.closed,
  IAppointmentStatus.cannotComplete,
];
const CRITICAL_STATUSES = [
  IAppointmentStatus.cancelled,
  IAppointmentStatus.cannotComplete,
  IAppointmentStatus.completed,
  IAppointmentStatus.customerCancellation,
  IAppointmentStatus.inProgress,
  IAppointmentStatus.technicalCancellation,
];

interface Props {
  type: IAppointmentStatusType;
  status?: IAppointmentStatus;
  statusList?: string[];
  appointment?: AppointmentType | null;
  parentNode?: any;
  onSuccess?: ((reloadCache?: boolean) => void) | null;
  readOnly?: boolean;
  showTextForOpenStatuses?: boolean;
}

const onCancelBubble = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const AppointmentStatusTag: FC<Props> = ({
  type,
  status = IAppointmentStatus.none,
  readOnly = false,
  statusList,
  appointment,
  parentNode,
  onSuccess,
  showTextForOpenStatuses = false,
}) => {
  const intl = useIntl();
  const [
    reasonCodeList,
    setReasonCodeList,
  ] = useState<ICancellationReasons | null>(
    appointment?.serviceAppointmentType
      ? CONSTANTS.CANCELLATION_REASON_CODE[
          appointment?.appointmentType?.name
        ] ??
          CONSTANTS.CANCELLATION_REASON_CODE[
            appointment?.serviceAppointmentType
          ] ??
          CONSTANTS.CANCELLATION_REASON_CODE[
            appointment?.serviceAppointmentType?.replace(' Backlog', '')
          ] ??
          CONSTANTS.CANCELLATION_REASON_CODE[
            appointment?.serviceAppointmentType?.replace(' IA', '')
          ] ??
          CONSTANTS.CANCELLATION_REASON_CODE['DC/ACM/AC']
      : null
  );
  const [currentStatus, setCurrentStatus] = useState<IAppointmentStatus>(
    status ? status : IAppointmentStatus.none
  );
  const [newStatus, setNewStatus] = useState<IAppointmentStatus | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!!appointment?.serviceAppointmentType) {
      setReasonCodeList(
        CONSTANTS.CANCELLATION_REASON_CODE[
          appointment?.appointmentType?.name
        ] ??
          CONSTANTS.CANCELLATION_REASON_CODE[
            appointment?.serviceAppointmentType
          ] ??
          CONSTANTS.CANCELLATION_REASON_CODE[
            appointment?.serviceAppointmentType?.replace(' Backlog', '')
          ] ??
          CONSTANTS.CANCELLATION_REASON_CODE[
            appointment?.serviceAppointmentType?.replace(' IA', '')
          ] ??
          CONSTANTS.CANCELLATION_REASON_CODE['DC/ACM/AC']
      );
    }
  }, [appointment]);

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  useEffect(() => {
    if (!modalOpen) {
      setNewStatus(undefined);
    }
  }, [modalOpen]);

  const onChangeStatus = async ({ key }: any) => {
    setNewStatus(key);
    setModalOpen(true);
  };

  const onCancel = () => {
    setCurrentStatus(status);
    setNewStatus(undefined);
    setLoading(false);
    setModalOpen(false);
  };

  const renderStatusTag = (
    status: string,
    showDropdownArrow: boolean = false
  ) => {
    let { statusColorType, i18nKey, tooltipKey } = getAppointmentStatusData(
      status
    );
    return (
      <StatusTag
        id={`ga-${type}-${status}`}
        status={statusColorType ?? 'default'}
        tooltip={tooltipKey ? intl.formatMessage({ id: tooltipKey }) : ''}
        isLoading={loading}
        showDropdownArrow={showDropdownArrow}
      >
        {i18nKey ? translation(i18nKey) : status}
      </StatusTag>
    );
  };

  const makeStatusMenu = (
    value: IAppointmentStatus,
    statusList: string[],
    onSelect?: any
  ) => {
    let items: ItemType[] = [];
    statusList.forEach((st: string) => {
      if (
        st !== value &&
        (type === IAppointmentStatusType.workOrder
          ? !IGNORE_WORKORDER_STATUSES.includes(st as IAppointmentStatus)
          : !IGNORE_STATUSES.includes(st as IAppointmentStatus))
      ) {
        items.push({
          label: renderStatusTag(st),
          key: st,
        });
      }
    });

    return {
      items,
      selectable: true,
      onSelect,
    };
  };

  return (
    <div
      onClick={(e: any) => onCancelBubble(e)}
      style={{
        cursor: statusList && statusList.length ? 'pointer' : 'default',
      }}
    >
      <Dropdown
        disabled={!statusList || !statusList.length || loading || readOnly}
        menu={makeStatusMenu(currentStatus, statusList ?? [], onChangeStatus)}
        getPopupContainer={(trigger: any) =>
          parentNode ? parentNode.current : trigger.parentNode
        }
        trigger={['click']}
      >
        <span>
          {!showTextForOpenStatuses ||
          CRITICAL_STATUSES.includes(currentStatus) ? (
            renderStatusTag(currentStatus, !!statusList && !!statusList?.length)
          ) : (
            <div style={{ width: '100%', padding: '0 6px' }}>
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {currentStatus}
                {!readOnly && !!statusList && !!statusList?.length && (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )}
              </Space>
            </div>
          )}
        </span>
      </Dropdown>
      <WidgetDelayAndStatusModal
        type={type}
        status={status}
        appointment={appointment}
        onSuccess={onSuccess}
        currentStatus={currentStatus}
        newStatus={newStatus}
        modalOpen={modalOpen}
        cannotCompleteReasonCodeList={reasonCodeList}
        setModalOpen={setModalOpen}
        onCancel={(reloadCache: boolean) => {
          if (reloadCache && onSuccess) onSuccess(true);
          onCancel();
        }}
        delayReasonIsRequired={
          type !== IAppointmentStatusType.workOrder &&
          appointment?.appointmentType?.name === 'Montage' &&
          appointment?.isDelayedEnd &&
          !appointment?.delayedEndReason
        }
      />
    </div>
  );
};

export default AppointmentStatusTag;
