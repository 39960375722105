import { FC } from 'react';
import { IStatus } from '../../constants/types';
import { getStatusColor } from '../../utils/helpers';

interface Props {
  style?: object;
  size?: string | number;
  onClick?: any;
  status?: IStatus;
}

export const VideoSvg: FC<Props> = ({
  style,
  size = '22',
  status = IStatus.open,
}) => (
  <svg width={size} height={size} viewBox="0 0 25 26" fill="none" style={style}>
    <g filter="url(#filter0_d_4498_137907)">
      <rect
        x="0.5"
        width="24"
        height="24"
        rx="12"
        fill={status === IStatus.open ? 'white' : getStatusColor(status)}
      />
      <path
        d="M13.875 11.0837L15.9618 10.0405C16.0317 10.0056 16.1093 9.98914 16.1873 9.99265C16.2653 9.99617 16.3411 10.0196 16.4076 10.0606C16.474 10.1017 16.5289 10.159 16.5669 10.2272C16.605 10.2954 16.625 10.3722 16.625 10.4503V13.5504C16.625 13.6285 16.605 13.7053 16.5669 13.7735C16.5289 13.8417 16.474 13.8991 16.4076 13.9401C16.3411 13.9812 16.2653 14.0046 16.1873 14.0081C16.1093 14.0116 16.0317 13.9951 15.9618 13.9602L13.875 12.917V11.0837Z"
        fill={status === IStatus.open ? '#161618' : 'white'}
        stroke={status === IStatus.open ? '#161618' : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9583 9.25H9.29167C8.78541 9.25 8.375 9.66041 8.375 10.1667V13.8333C8.375 14.3396 8.78541 14.75 9.29167 14.75H12.9583C13.4646 14.75 13.875 14.3396 13.875 13.8333V10.1667C13.875 9.66041 13.4646 9.25 12.9583 9.25Z"
        fill={status === IStatus.open ? '#161618' : 'white'}
        stroke={status === IStatus.open ? '#161618' : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {status === IStatus.open && (
        <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="#D9D9D9" />
      )}
    </g>
    <defs>
      <filter
        id="filter0_d_4498_137907"
        x="0.5"
        y="0"
        width="24"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4498_137907"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4498_137907"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
