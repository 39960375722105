import React from 'react';

const Qm: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="#47474D"
    />
    <path
      d="M22.9604 21.052C22.5814 20.1544 22.0315 19.339 21.3412 18.6514C20.653 17.9617 19.8377 17.4119 18.9405 17.0322C18.9325 17.0281 18.9244 17.0261 18.9164 17.0221C20.1679 16.1181 20.9816 14.6455 20.9816 12.9842C20.9816 10.2319 18.7517 8.00202 15.9994 8.00202C13.2472 8.00202 11.0173 10.2319 11.0173 12.9842C11.0173 14.6455 11.8309 16.1181 13.0825 17.0241C13.0744 17.0281 13.0664 17.0301 13.0584 17.0342C12.1584 17.4139 11.3508 17.9583 10.6577 18.6534C9.96804 19.3416 9.41818 20.1568 9.03848 21.054C8.66547 21.9324 8.4643 22.8742 8.44585 23.8284C8.44531 23.8498 8.44908 23.8711 8.45691 23.8911C8.46475 23.9111 8.4765 23.9293 8.49148 23.9446C8.50646 23.96 8.52435 23.9722 8.54412 23.9805C8.56389 23.9888 8.58512 23.9931 8.60657 23.9931H9.81192C9.90032 23.9931 9.97063 23.9228 9.97264 23.8364C10.0128 22.2855 10.6356 20.8331 11.7365 19.7322C12.8755 18.5931 14.3883 17.9663 15.9994 17.9663C17.6106 17.9663 19.1233 18.5931 20.2624 19.7322C21.3633 20.8331 21.986 22.2855 22.0262 23.8364C22.0282 23.9248 22.0985 23.9931 22.1869 23.9931H23.3923C23.4137 23.9931 23.435 23.9888 23.4547 23.9805C23.4745 23.9722 23.4924 23.96 23.5074 23.9446C23.5223 23.9293 23.5341 23.9111 23.5419 23.8911C23.5498 23.8711 23.5535 23.8498 23.553 23.8284C23.5329 22.8681 23.334 21.9339 22.9604 21.052ZM15.9994 16.4395C15.0773 16.4395 14.2095 16.0799 13.5566 15.427C12.9037 14.7741 12.5441 13.9063 12.5441 12.9842C12.5441 12.0621 12.9037 11.1942 13.5566 10.5413C14.2095 9.88841 15.0773 9.52881 15.9994 9.52881C16.9215 9.52881 17.7894 9.88841 18.4423 10.5413C19.0952 11.1942 19.4548 12.0621 19.4548 12.9842C19.4548 13.9063 19.0952 14.7741 18.4423 15.427C17.7894 16.0799 16.9215 16.4395 15.9994 16.4395Z"
      fill="white"
    />
    <path
      d="M16 31C7.71573 31 1 24.2843 1 16H-1C-1 25.3888 6.61116 33 16 33V31ZM31 16C31 24.2843 24.2843 31 16 31V33C25.3888 33 33 25.3888 33 16H31ZM16 1C24.2843 1 31 7.71573 31 16H33C33 6.61116 25.3888 -1 16 -1V1ZM16 -1C6.61116 -1 -1 6.61116 -1 16H1C1 7.71573 7.71573 1 16 1V-1Z"
      fill="white"
    />
  </svg>
);

export default Qm;
