import { AnyAction } from 'redux';
import { LOCALES } from '../../i18n';
import { changeLanguage } from './constants';

const initialState = {
  currentLanguage: localStorage.getItem('user-language') ?? LOCALES.GERMAN,
};

const changeLanguageReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case changeLanguage.SET_LANGUAGE:
      return {
        ...state,
        currentLanguage: payload,
      };

    default:
      return state;
  }
};

export default changeLanguageReducer;
