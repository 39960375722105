import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Modal, Form, Input, Select, message, Switch } from 'antd';
import { FormType, FlatList } from '../types';
import { useIntl } from 'react-intl';
import { IRoles } from '../../../store/rolesAndPrivileges';

const { TextArea } = Input;
const { Option } = Select;
const APPROVAL_ROLES = [IRoles.QualityManager, IRoles.ProjectLead];
const DEFAULT_APPROVAL_ROLES = [IRoles.SuperAdmin, IRoles.FulfilmentAdmin];

interface Props {
  visible: boolean;
  saveLoading: boolean;
  hideModal: () => void;
  handleSave: (form: FormType, updatedFlatList: FlatList) => void;
}

const EditFormModal: FC<Props> = ({
  visible,
  saveLoading,
  hideModal,
  handleSave,
}) => {
  const [formInstance] = Form.useForm();
  const intl = useIntl();
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);

  // Remove the Default Roles from the allowedRolesForApproval
  if (form?.allowedRolesForApproval?.length) {
    form.allowedRolesForApproval =
      form.allowedRolesForApproval?.filter(
        (roles: IRoles) => !DEFAULT_APPROVAL_ROLES.includes(roles)
      ) ?? [];
  }

  const handleFormDetailSave = async () => {
    if (form && flatList) {
      try {
        let values = await formInstance.validateFields();
        const newForm = {
          ...form,
          ...values,
          // Include the Default Roles in the allowedRolesForApproval
          allowedRolesForApproval: values.allowedRolesForApproval?.length
            ? [...values.allowedRolesForApproval, ...DEFAULT_APPROVAL_ROLES]
            : [],
        };
        handleSave(newForm, flatList);
        hideModal();
      } catch (error: any) {
        if (error?.errorFields) {
          for (let err of error.errorFields) {
            message.error(err.errors[0]);
          }
        }
      }
    }
  };

  return (
    <Modal
      destroyOnClose
      title={intl.formatMessage({ id: 'edit_form' })}
      open={visible}
      onOk={handleFormDetailSave}
      onCancel={hideModal}
      maskClosable={false}
      okText={intl.formatMessage({ id: 'save' })}
      cancelText={intl.formatMessage({ id: 'cancel_editing' })}
      okButtonProps={{
        loading: saveLoading,
      }}
      cancelButtonProps={{
        disabled: saveLoading,
      }}
      width={600}
    >
      <Form
        form={formInstance}
        layout="vertical"
        initialValues={{
          name: form?.name,
          description: form?.description,
          priority: form?.priority,
          doNotSendToQM: form?.doNotSendToQM ?? false,
          allowedRolesForApproval: form?.allowedRolesForApproval ?? [],
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Insert a name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={intl.formatMessage({ id: 'description' })}
          rules={[{ required: true, message: 'Insert a description' }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item name="priority" label="Priority">
          <Select>
            <Option value="Default">
              {intl.formatMessage({ id: 'default' })}
            </Option>
            <Option value="Priority">Priority</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="doNotSendToQM"
          label="Skip Approval Packages"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item name="allowedRolesForApproval" label="Approval By">
          <Select
            mode="multiple"
            placeholder={`Default: ${IRoles.QualityManager}`}
          >
            {APPROVAL_ROLES.map((key: string) => (
              <Option value={key} key={key}>
                {key}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditFormModal;
