import { FC } from 'react';
import { Typography, Space, Empty, Divider } from 'antd';
import styles from './notes.module.scss';
import { useIntl } from 'react-intl';

const { Text } = Typography;

interface Props {
  notes?: any;
}

const NotesLog: FC<Props> = ({ notes }) => {
  const intl = useIntl();

  const renderNotesItem = (log: any, i: number) => {
    return (
      <Space
        direction="vertical"
        className={styles.notesItemWrapper}
        key={`note-item-${i}`}
      >
        {i !== 0 && <Divider style={{ width: '100%', margin: '0' }} />}
        <Space style={{ marginTop: '8px', width: '100%', flexWrap: 'wrap' }}>
          <Text type="secondary">{log?.updatedBy?.name}</Text>
          <Text disabled>
            {intl.formatDate(log?.updatedAt, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}{' '}
            {intl.formatDate(log?.updatedAt, {
              timeStyle: 'short',
              hour12: false,
            })}
          </Text>
          <div className={styles.serviceAppointmentTypeTag}>
            {log?.serviceAppointmentType}
          </div>
        </Space>
        <Text>{log?.text}</Text>
      </Space>
    );
  };

  return (
    <>
      {notes?.length > 0 ? (
        <div className={styles.notesWrapper}>
          {' '}
          {notes.map((item: any, i: number) => renderNotesItem(item, i))}
        </div>
      ) : (
        <Empty className={styles.iconCentered} description={false} />
      )}
    </>
  );
};

export default NotesLog;
