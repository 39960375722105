import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useState } from 'react';
import { AppointmentTypeFile } from '../../types';
import styles from '../appointmentTypeDetail.module.scss';
import FileUploadModal from './FileUploadModal';
const { Text } = Typography;

const UploadedFiles = ({ defaultFileList, handleUpdateFileList }: any) => {
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);
  const [chosenFile, setChosenFile] = useState<AppointmentTypeFile | undefined>(
    undefined
  );

  const hideModal = () => {
    setIsFileModalVisible(false);
    setChosenFile(undefined);
  };

  return (
    <div
      className={styles.flex}
      style={{ flexDirection: 'column', justifyContent: 'start' }}
    >
      <div
        className={styles.flex}
        style={{ width: '100%', justifyContent: 'start' }}
      >
        <Text style={{ fontWeight: 500, marginRight: '8px', width: '32px' }}>
          Files
        </Text>
        <Button
          ghost
          block
          style={{ border: '0px', color: 'var(--color-blue-2)', width: '46px' }}
          onClick={() => setIsFileModalVisible(true)}
        >
          Add
        </Button>
      </div>
      <div className={styles.preloadedFilesList}>
        {defaultFileList &&
          defaultFileList.map((file: AppointmentTypeFile, i: number) => {
            return (
              <div
                className={styles.flex}
                key={i}
                style={{
                  width: '100%',
                  justifyContent: 'start',
                  background: '#fff',
                  padding: '4px 12px',
                }}
              >
                <a
                  className={styles.download}
                  target="_blank"
                  href={file.url}
                  rel="noreferrer"
                  style={{ width: '86%', maxWidth: '86%', height: '32px' }}
                >
                  <Text
                    ellipsis
                    style={
                      file?.status === 'deleted'
                        ? { color: 'red' }
                        : { maxWidth: '86%' }
                    }
                  >
                    {file?.label}
                  </Text>
                </a>
                {file?.status !== 'deleted' && (
                  <Button
                    ghost
                    block
                    style={{
                      border: '0px',
                      color: 'var(--color-blue-2)',
                      width: '22px',
                      textAlign: 'center',
                      boxShadow: 'none',
                    }}
                    onClick={() => {
                      setChosenFile(file);
                      setIsFileModalVisible(true);
                    }}
                  >
                    <EditFilled />
                  </Button>
                )}
                {file?.status !== 'deleted' && (
                  <Button
                    ghost
                    block
                    style={{
                      border: '0px',
                      color: 'var(--color-blue-2)',
                      width: '22px',
                      textAlign: 'center',
                      boxShadow: 'none',
                    }}
                    onClick={() => handleUpdateFileList('delete-file', file)}
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </div>
            );
          })}
      </div>
      {isFileModalVisible && (
        <FileUploadModal
          file={chosenFile}
          visible={isFileModalVisible}
          hideModal={hideModal}
          handleUpdateFileList={handleUpdateFileList}
        />
      )}
    </div>
  );
};

export default UploadedFiles;
