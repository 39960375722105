import { FC } from 'react';
import { Typography, Space, Col, Tag } from 'antd';
import { CalendarOutlined, TeamOutlined } from '@ant-design/icons';
import styles from '../appointmentDetailsSidebar.module.scss';
import { IAppointmentStatus } from '../../../constants/types';
const { Text } = Typography;

interface Props {
  title: string;
  appointmentDate: string;
  appointmentStatus?: IAppointmentStatus;
  teamName: string;
}

const AppointmentContainer: FC<Props> = ({
  title,
  appointmentDate,
  appointmentStatus,
  teamName,
}) => {
  return (
    <Col className={styles.appointmentCol}>
      <Space direction="vertical" className={styles.teamContainer}>
        <Space>
          <Text type="secondary" strong>
            {title}
          </Text>
          <Tag className={styles.statusText}> {appointmentStatus}</Tag>
        </Space>

        {appointmentDate && (
          <Space>
            <CalendarOutlined style={{ marginRight: '3px' }} />
            <Text type="secondary">{appointmentDate}</Text>
          </Space>
        )}

        <Space>
          <TeamOutlined style={{ marginRight: '3px' }} />
          <Text type="secondary">{teamName}</Text>
        </Space>
      </Space>
    </Col>
  );
};

export default AppointmentContainer;
