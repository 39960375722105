import { Space, Typography } from 'antd';
import styles from './customerInfo.module.scss';
import { LinkMTWIcon } from '../../../../components/icons/linkMTW';
import translation from '../../../../i18n/translation';
const { Text, Link } = Typography;

interface CustomerInfoProps {
  customerInfo?: any;
}

const CustomerInformation: React.FC<CustomerInfoProps> = ({ customerInfo }) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size={0}>
      <div className={styles.infoWrapper}>
        <Text type="secondary">Name</Text>
        <Text strong>{customerInfo?.name}</Text>
      </div>
      <div className={styles.infoWrapper}>
        <Text type="secondary">{translation('phone_number')}</Text>
        <Text strong>{customerInfo?.address?.telephone}</Text>
      </div>
      <div className={styles.infoWrapper}>
        <Text type="secondary">{translation('address')}</Text>
        <Space>
          <Text strong>{customerInfo?.address?.address1}</Text>
          <Link
            href={`http://maps.google.com/maps?q=${customerInfo?.address?.address1}`}
            className={styles.flexCenter}
            target="_blank"
          >
            <LinkMTWIcon />
          </Link>
        </Space>
      </div>
      <div className={styles.infoWrapper}>
        <Text type="secondary">Email</Text>
        <Link
          className={styles.flexCenter}
          onClick={(e) => {
            window.location.href = `mailto:${customerInfo?.email}`;
            e.preventDefault();
          }}
          target="_blank"
        >
          {customerInfo?.email}
        </Link>
      </div>
    </Space>
  );
};

export default CustomerInformation;
