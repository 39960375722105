import { Button, Col, Modal, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import translation from '../../i18n/translation';
import styles from './imageSelector.module.scss';
import { useIntl } from 'react-intl';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Thumbnail from './thumbnail';
import { EditedImage, FetchOptions } from '../../constants/types';

import { store } from '../../store';
import { config } from '../../config';
import ImageEdit from './imageEdit';
import { showNotificationMessage } from '../../utils/notification';

interface IImageSelector {
  isVisible: boolean;
  images: Array<string>;
  onClose: (isSubmited: boolean) => void;
  onSubmit?: () => void;
  appointmentId?: string;
  formInstanceId?: string;
  formFieldId?: string;
  externalCaseId: string;
}

const ImageSelector: React.FC<IImageSelector> = ({
  isVisible,
  onClose,
  images,
  appointmentId,
  formInstanceId,
  formFieldId,
  externalCaseId,
}) => {
  const { confirm } = Modal;
  const intl = useIntl();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [editedImages, setEditedImages] = useState<EditedImage[]>([]);

  const [togglePreview, setTogglePreview] = useState(false);
  const [previewImg, setPreviewImg] = useState<number>(-1);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState<string>();

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (error)
      notification.error({
        message: (
          <span>
            {intl.formatMessage({
              id: 'error',
            })}
          </span>
        ),
        description: <span>{error}</span>,
      });
  }, [error, intl]);

  const showConfirm = () => {
    if (!!selectedImages.length) {
      confirm({
        title: intl.formatMessage({
          id: 'close',
        }),
        icon: <ExclamationCircleOutlined />,
        content: intl.formatMessage({
          id: 'edit_images_confirm_close_message',
        }),
        async onOk() {
          handleClose(false);
        },
      });
    } else {
      handleClose(false);
    }
  };

  const handleSelect = (index: number) => {
    var find = selectedImages.indexOf(index);
    let newImagesArr;
    if (find > -1) {
      newImagesArr = [...selectedImages];
      newImagesArr.splice(find, 1);
    } else {
      newImagesArr = [...selectedImages, index];
    }
    setSelectedImages(newImagesArr);
  };

  const handleClose = (isSubmited: boolean) => {
    onClose(isSubmited);
    setSelectedImages([]);
    setEditedImages([]);
    setIsSubmitting(false);
    setError(undefined);
  };

  const handlePreview = (index: number) => {
    setTogglePreview(true);
    setPreviewImg(index);
  };

  const handlePreviewSelect = (newImage?: string) => {
    if (newImage) {
      images[previewImg] = newImage;
      let newEditedImages = [
        ...editedImages.filter((img) => img.position !== previewImg),
      ];
      newEditedImages.push({ position: previewImg, newUrl: newImage });
      setEditedImages(newEditedImages);
      if (!selectedImages.includes(previewImg)) {
        handleSelect(previewImg);
      }
    } else {
      handleSelect(previewImg);
    }
    setTogglePreview(false);
  };

  const handlePreviewClose = () => {
    setTogglePreview(false);
    setPreviewImg(-1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (editedImages.length) await handleUpload();

    const payload = {
      formInstanceId: formInstanceId,
      appointmentId: appointmentId,
      formFieldId: formFieldId,
      uploads: [...selectedImages].map((item) => images[item]),
      qmSubmission: true,
    };

    const opt: FetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
      body: JSON.stringify(payload),
    };

    try {
      const resp = await fetch(`${config.apiUrl}/Forms/answers`, opt);
      const result = await resp.json();
      if (resp.status !== 201) {
        setIsSubmitting(false);
        onClose(false);

        showNotificationMessage({
          message: intl.formatMessage({
            id: 'error_post_comment',
          }),
          error: {
            message: result?.message ?? result?.errors[''],
            code: resp.status,
          },
        });
      } else {
        handleClose(true);
      }
    } catch (err: any) {
      setError(err);
    }
  };

  const handleUpload = async () => {
    let formData = new FormData();

    for (const image of editedImages) {
      let blob = await fetch(image.newUrl).then((r) => r.blob());
      var blobUrl = images[image.position].split('/');
      var blobName = `${blobUrl[blobUrl.length - 1]}`.split('.')[0];
      var blobExtention = blob.type.split('/').pop();
      formData.append(`file`, blob, `${blobName}.${blobExtention}`);
    }

    const opt = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
      body: formData,
    };

    try {
      const resp = await fetch(
        `${config.apiUrl}/Files?container=${externalCaseId}`,
        opt
      );
      const result = await resp.json();
      if (resp.status !== 200) {
        setError(result.message);
      } else {
        result.forEach((file: any) => {
          const blobName = file.fileName
            .slice(file.fileName.indexOf('_'))
            .slice(1)
            .split('.')[0];
          const index = images.findIndex((element) =>
            element.includes(blobName)
          );
          images[index] = file.url;
        });
        setEditedImages([]);
      }
    } catch (err: any) {
      setError(err);
    }
  };

  return (
    <>
      <Modal
        className={styles.modal}
        title={translation('edit_images')}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => showConfirm()}
        open={isModalVisible}
        bodyStyle={{
          height: `calc(100vh - 124px)`,
          width: '100%',
          overflowY: 'scroll',
        }}
        width={'100%'}
        footer={
          <div className={styles.container}>
            <div className={styles.modalFooter}>
              <div>
                <strong>
                  {selectedImages.length} {translation('images_selected')}
                </strong>
              </div>
              <Button
                size={'large'}
                shape="round"
                disabled={!selectedImages.length}
                type={'primary'}
                className={styles.submitButton}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
              >
                {translation('submit_selection')}
              </Button>
            </div>
          </div>
        }
      >
        <div className={styles.container}>
          <Row gutter={[16, 16]}>
            {images.map((element, index) => (
              <Col xs={{ span: 6 }} lg={{ span: 4 }} key={`thumbnail-${index}`}>
                <Thumbnail
                  src={element}
                  isSelected={selectedImages.includes(index)}
                  key={element}
                  onSelect={() => handleSelect(index)}
                  onPreview={() => handlePreview(index)}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Modal>
      <ImageEdit
        src={images[previewImg]}
        isVisible={togglePreview}
        okText={intl.formatMessage({ id: 'save_changes' })}
        isSelected={selectedImages.includes(previewImg)}
        onSelect={handlePreviewSelect}
        onClose={handlePreviewClose}
      />
    </>
  );
};

export default ImageSelector;
