import { FC, useEffect, useState } from 'react';
import {
  Typography,
  Collapse,
  Spin,
  Space,
  Row,
  Col,
  Divider,
  Avatar,
  Empty,
} from 'antd';
import StatusChangeLogActions from './actions';
import styles from './statusChangeLog.module.scss';
import { DownOutlined, SwapRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { StatusChangeLogState } from './types';
import cNames from 'classnames';
import { IAppointmentStatusType } from '../../../constants/types';
import { useIntl } from 'react-intl';
import { CONSTANTS, getTranslation } from 'craftos-ui';
import { getIcon } from '../../../components/statusTag';
import { getAppointmentStatusData } from '../../../utils/helpers';
import AppointmentStatusTag from '../../../components/appointmentStatusTag';

const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
  appointmentExternalId: string;
}

const StatusChangeLog: FC<Props> = ({ appointmentExternalId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const statusChangeLogState: StatusChangeLogState = useSelector(
    (state: RootState) => state.statusChangeLogData
  );

  const [isOpen, setIsOpen] = useState(false);

  const onHandleGetStatusLogs = () => {
    dispatch(
      StatusChangeLogActions.fetchStatusChangeLogAsync(appointmentExternalId)
    );
  };

  useEffect(() => {
    if (isOpen && appointmentExternalId) {
      onHandleGetStatusLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const renderStatusLogItem = (log: any, i: number) => {
    let avatarColor = getAppointmentStatusData(log?.newStatus)?.statusColorType;
    return (
      <Row key={`appointment-status-change-${i}`}>
        {i !== 0 && <Divider className={styles.divider} />}
        <Col style={{ marginRight: '16px' }}>
          <Avatar
            icon={getIcon(`${avatarColor}`)}
            className={cNames(styles.avatar, {
              [`ant-tag-${avatarColor}`]: true,
            })}
          />
        </Col>
        <Col flex="auto" style={{ maxWidth: '550px' }}>
          <div className={styles.changeLogContentLine}>
            <Space size="middle" align="center">
              <Text strong>
                {intl.formatDate(log?.logDate, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </Text>
              <Text type="secondary">
                {intl.formatTime(log?.logDate, {
                  timeStyle: 'short',
                  hour12: false,
                })}
              </Text>
              <Text type="secondary">{log?.userInfo?.name}</Text>
            </Space>
          </div>

          <div className={styles.changeLogContentLine}>
            <Row align="middle" style={{ margin: '8px 0' }}>
              <AppointmentStatusTag
                type={IAppointmentStatusType.serviceAppointment}
                status={log?.oldStatus}
              />{' '}
              <SwapRightOutlined style={{ margin: '0 8px' }} />
              <AppointmentStatusTag
                type={IAppointmentStatusType.serviceAppointment}
                status={log?.newStatus}
              />{' '}
            </Row>
          </div>

          <div
            className={styles.changeLogContentLine}
            style={{ maxWidth: '500px' }}
          >
            <Text type="secondary" style={{ fontSize: '13px' }}>
              {log?.comment
                ? getTranslation(log?.comment).replaceAll(
                    CONSTANTS.REASON_SEPARATOR_DELIMITER,
                    ' - '
                  )
                : null}
            </Text>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Collapse
      key="StatusChangeCollapse"
      ghost
      collapsible="header"
      className={styles.collapse}
      activeKey={isOpen ? 'drawer-status-change-log-panel-1' : undefined}
      onChange={() => setIsOpen(!isOpen)}
    >
      <Panel
        showArrow={false}
        header={
          <>
            <Text className={styles.panelHeader}>
              {intl.formatMessage({ id: 'status_change_log' })}
            </Text>
            <DownOutlined rotate={isOpen ? 180 : 0} />
          </>
        }
        key="drawer-status-change-log-panel-1"
      >
        {statusChangeLogState.loadingStatusChangeLog ? (
          <Spin className={styles.spin} />
        ) : statusChangeLogState.statusChangeLog.length > 0 ? (
          statusChangeLogState.statusChangeLog.map((item, i) =>
            renderStatusLogItem(item, i)
          )
        ) : (
          <Empty description={false} />
        )}
      </Panel>
    </Collapse>
  );
};

export default StatusChangeLog;
