import { AnyAction } from 'redux';
import { FormBuilderTableActions, Form } from './types';
import { IMetaData } from '../../constants/types';
import { metaDataTypes } from '../../constants/types';

interface FormBuilderTableState {
  loadingForms: boolean;
  forms: Form[];
  totalCount: number;
  error: any;
}

const initialState: FormBuilderTableState = {
  loadingForms: false,
  forms: [],
  totalCount: 0,
  error: null,
};

interface MetaDataState {
  metaDataList: IMetaData[];
  loading: boolean;
  submitted: boolean;
}

const metaDataInitialState: MetaDataState = {
  metaDataList: [],
  loading: false,
  submitted: false,
};

export const formBuilderTableReducer = (
  state: FormBuilderTableState = initialState,
  action: AnyAction
): FormBuilderTableState => {
  switch (action.type) {
    case FormBuilderTableActions.FETCH_FORMS_START:
      return {
        ...state,
        loadingForms: true,
      };
    case FormBuilderTableActions.FETCH_FORMS_SUCCESS:
      const { response, pagination } = action.payload;
      return {
        ...state,
        loadingForms: false,
        forms: response,
        totalCount: pagination.TotalCount,
      };
    case FormBuilderTableActions.FETCH_FORMS_FAILED:
      return {
        ...state,
        loadingForms: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const metaDataReducer = (
  state: MetaDataState = metaDataInitialState,
  action: AnyAction
) => {
  const { type, payload } = action;
  switch (type) {
    case metaDataTypes.GET_META_DATA:
      return {
        ...state,
        metaDataList: payload.response,
        loading: false,
        submitted: false,
      };
    case metaDataTypes.POST_META_DATA:
      return {
        ...state,
        metaDataList: payload.response,
        submitted: true,
      };
    case metaDataTypes.SET_META_DATA_LOADING:
      return {
        ...state,
        loading: payload,
        submitted: false,
      };
    case metaDataTypes.GET_META_DATA_FAIL:
      return {
        ...state,
        error: payload,
        submitted: false,
      };
    default:
      return state;
  }
};
