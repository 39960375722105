import React from 'react';

export const FileMTWIcon: React.FC = () => (
  <svg width="120" height="80" viewBox="0 0 120 80" fill="none">
    <rect width="120" height="80" rx="4" fill="#F2F2F3" />
    <path
      d="M22 15C22 13.8954 22.8954 13 24 13H95C96.1046 13 97 13.8954 97 15V80H22V15Z"
      fill="white"
    />
    <rect x="28" y="32" width="64" height="16" rx="2" fill="#E2E2E4" />
    <rect x="28" y="52" width="64" height="16" rx="2" fill="#E2E2E4" />
    <path
      d="M28 74C28 72.8954 28.8954 72 30 72H90C91.1046 72 92 72.8954 92 74V80H28V74Z"
      fill="#E2E2E4"
    />
    <mask
      id="mask0_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_5487_6029)">
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L34.1907 18.75L32.9526 17Z"
        fill="#4285F4"
      />
    </g>
    <mask
      id="mask1_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_5487_6029)">
      <path
        d="M33.1699 19.7803L35.9242 22.5603V19.9996L33.1699 19.7803Z"
        fill="url(#paint0_linear_5487_6029)"
      />
    </g>
    <mask
      id="mask2_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_5487_6029)">
      <path
        d="M29.9811 25H33.9432V24.5H29.9811V25ZM29.9811 26H32.9526V25.5H29.9811V26ZM29.9811 22.5V23H33.9432V22.5H29.9811ZM29.9811 24H33.9432V23.5H29.9811V24Z"
        fill="#F1F1F1"
      />
    </g>
    <mask
      id="mask3_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_5487_6029)">
      <path
        d="M32.9526 17V19.25C32.9526 19.6644 33.2851 20 33.6955 20H35.9242L32.9526 17Z"
        fill="#A1C2FA"
      />
    </g>
    <mask
      id="mask4_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_5487_6029)">
      <path
        d="M28.7429 17C28.3343 17 28 17.3375 28 17.75V17.8125C28 17.4 28.3343 17.0625 28.7429 17.0625H32.9526V17H28.7429Z"
        fill="white"
        fillOpacity="0.2"
      />
    </g>
    <mask
      id="mask5_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_5487_6029)">
      <path
        d="M35.1812 27.9375H28.7429C28.3343 27.9375 28 27.6 28 27.1875V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V27.1875C35.9241 27.6 35.5898 27.9375 35.1812 27.9375Z"
        fill="#1A237E"
        fillOpacity="0.2"
      />
    </g>
    <mask
      id="mask6_5487_6029"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="28"
      y="17"
      width="8"
      height="11"
    >
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_5487_6029)">
      <path
        d="M33.6955 20C33.2851 20 32.9526 19.6644 32.9526 19.25V19.3125C32.9526 19.7269 33.2851 20.0625 33.6955 20.0625H35.9242V20H33.6955Z"
        fill="#1A237E"
        fillOpacity="0.1"
      />
      <path
        d="M32.9526 17H28.7429C28.3343 17 28 17.3375 28 17.75V27.25C28 27.6625 28.3343 28 28.7429 28H35.1812C35.5898 28 35.9241 27.6625 35.9241 27.25V20L32.9526 17Z"
        fill="url(#paint1_radial_5487_6029)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5487_6029"
        x1="34.5472"
        y1="20.019"
        x2="34.5472"
        y2="22.5607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A237E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_5487_6029"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.251 17.2174) scale(12.7775 12.8255)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
