import React from 'react';

export const AcHealingMTWIcon: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g id="Frame" clipPath="url(#clip0_5371_166035)">
      <path
        id="Vector"
        d="M9.33337 8.5V8.50667"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M6.66663 8.5V8.50667"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M8 7.16699V7.17366"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M8 9.83301V9.83967"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M2.99995 8.83303L8.33328 3.4997C8.95212 2.88086 9.79144 2.5332 10.6666 2.5332C11.5418 2.5332 12.3811 2.88086 12.9999 3.4997C13.6188 4.11854 13.9664 4.95787 13.9664 5.83303C13.9664 6.7082 13.6188 7.54753 12.9999 8.16637L7.66661 13.4997C7.04777 14.1185 6.20845 14.4662 5.33328 14.4662C4.45811 14.4662 3.61878 14.1185 2.99995 13.4997C2.38111 12.8809 2.03345 12.0415 2.03345 11.1664C2.03345 10.2912 2.38111 9.45187 2.99995 8.83303Z"
        stroke="#47474D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5371_166035">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
