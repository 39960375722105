import { IUser } from 'craftos-ui';
import { getEnv } from '../utils/environment';

export enum IRoles {
  SuperAdmin = 'SuperAdmin',
  EnpalDefault = 'EnpalDefault',
  QualityManager = 'QualityManager',
  ExternalProjectLead = 'ExternalProjectLead',
  ProjectLead = 'ProjectLead',
  BranchLead = 'BranchLead',
  FulfilmentViewer = 'FulfilmentViewer',
  Scheduler = 'Scheduler',
  SchedulerAdmin = 'SchedulerAdmin',
  FulfilmentAdmin = 'FulfilmentAdmin',
  UserManager = 'UserManager',
}

export enum IFeatures {
  appointmentRead = 'Fulfilment:Appointment:Read',
  appointmentClose = 'Fulfilment:Appointment:Close',

  progressAccess = 'Fulfilment:Progress:Access',
  progressGroupsAppointmentsOnly = 'Fulfilment:Progress:ListOnlyAppointmentsOfGroup',
  progressWriteDate = 'Fulfilment:Appointment:DateChange',
  progressWriteStatus = 'Fulfilment:Appointment:StatusChange',
  progressWriteMetadata = 'Fulfilment:Metadata:Write',

  fieldDetailUpdateImage = 'Fulfilment:Answer:UpdateImages',
  fieldDetailComment = 'Fulfilment:Answer:Comment',
  fieldDetailApproval = 'Fulfilment:Answer:Approve',
  fieldDetailReopen = 'Fulfilment:Answer:Reopen',

  approvalPackagesAccess = 'Fulfilment:ApprovalPackages:Access',

  progressBypassStatusChangeValidation = 'Fulfilment:Appointment:BypassStatusChangeValidation',

  formBuilderAccess = 'Fulfilment:FormBuilder:Access',

  adminAccess = 'Fulfilment:Admin:Access',

  SuperAdminAccess = 'Fulfilment:SuperAdmin',
}

export const DEFAULT_ROLES = [IRoles.FulfilmentViewer];

export const getUserPrivileges = (
  user: IUser,
  rolesList: any
): { [key: string]: boolean } => {
  let privileges: { [key: string]: boolean } = {};

  let roles = getEnv() === 'production' ? user.roles : user.testRoles;
  if (!roles || !roles.length) {
    roles = DEFAULT_ROLES;
  }

  privileges[IFeatures.SuperAdminAccess] = roles.includes(IRoles.SuperAdmin);

  for (let key of Object.keys(rolesList)) {
    const list = rolesList[key].roles;
    privileges[key] = roles.some((role: string) => list.includes(role));
  }

  return privileges;
};
