import { FC } from 'react';
import { WorkType, WorkTypesGroup } from '../../types';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import styles from '../appointmentTypeDetail.module.scss';
import { Button, Popover, Spin, Tag, Typography } from 'antd';

interface Props {
  loading?: boolean;
  groups?: [WorkTypesGroup] | [] | undefined;
  formObject: any;
  handleUpdateWorkTypesList: (arg0: string, arg1: WorkType[]) => void;
  setSelectedGroupValue: any;
  setSelectedUpdateGroup: any;
  handleChangesForGroup: any;
  showModal: any;
  selectedGroupValue: string | undefined;
}

const GroupCardsContainer: FC<Props> = ({
  groups,
  handleUpdateWorkTypesList,
  setSelectedGroupValue,
  formObject,
  loading,
  setSelectedUpdateGroup,
  handleChangesForGroup,
  showModal,
  selectedGroupValue,
}) => {
  //useEffect(() => {}, [groups]);

  const { Text } = Typography;

  return (
    <div
      className={styles.flex}
      style={{
        padding: '20px 0',
        maxWidth: '90%',
        overflow: 'auto',
        whiteSpace: 'nowrap',
      }}
    >
      {!!loading ? (
        <Spin />
      ) : (
        formObject.getFieldValue('groups')?.map((item: any, i: number) => {
          return (
            <div
              key={i + 100}
              className={styles.flex}
              style={{ flexDirection: 'column' }}
            >
              <Popover
                content={
                  <div className={styles.flex}>
                    {item?.status === 'deleted' ? (
                      <Tag color="volcano">Deleted</Tag>
                    ) : (
                      <>
                        <Button
                          type="text"
                          ghost
                          className={styles.groupActionOption}
                          onClick={() => {
                            setSelectedUpdateGroup(item);
                            showModal();
                          }}
                        >
                          <EditFilled />
                        </Button>

                        <Button
                          type="text"
                          ghost
                          className={styles.groupActionOption}
                          onClick={() =>
                            handleChangesForGroup('delete-selected', item)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </>
                    )}
                  </div>
                }
                placement="topRight"
                overlayInnerStyle={{
                  marginBottom: '-4px',
                  marginRight: '-16px',
                  backgroundColor: 'transparent',
                  padding: 0,
                  boxShadow: 'none',
                }}
                overlayStyle={{
                  marginRight: '-16px',
                  backgroundColor: 'transparent',
                  padding: 0,
                  zIndex: '1',
                }}
              >
                <Button
                  key={i}
                  className={
                    item?.status === 'deleted'
                      ? `${
                          styles.workTypesCountButton +
                          ' ' +
                          styles.deletedGroup
                        }`
                      : selectedGroupValue !== item.name
                      ? styles.workTypesCountButton
                      : `${
                          styles.workTypesCountButton +
                          ' ' +
                          styles.highlightGroupOption
                        }`
                  }
                  onClick={() => setSelectedGroupValue(item.name)}
                >
                  <div className={styles.groupsBlock}>
                    <Text ellipsis className={styles.groupName}>
                      {item?.name}{' '}
                    </Text>
                    <p className={styles.number}>
                      {item?.workTypes?.length ? item?.workTypes?.length : 0}
                    </p>
                    <p className={styles.countedItems}>Work Types</p>
                  </div>
                </Button>
              </Popover>
            </div>
          );
        })
      )}
    </div>
  );
};

export default GroupCardsContainer;
