import { Input } from 'antd';

interface SearchProps {
  onSearch: (val: string) => void;
  placeholder: string;
  defaultValue: string | undefined;
}

const Search: React.FC<SearchProps> = ({
  onSearch,
  placeholder,
  defaultValue,
}) => {
  return (
    <Input.Search
      placeholder={placeholder ?? 'input here'}
      enterButton
      allowClear
      defaultValue={defaultValue}
      onSearch={(value) => onSearch(value)}
    />
  );
};

export default Search;
