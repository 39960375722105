import {
  TreeDataNode,
  FormChild,
  Option,
  FlatList,
  DependsOn,
  Answer,
} from './types';

export const generateFlatList = (
  children: FormChild[],
  map: FlatList = new Map()
): FlatList => {
  if (!children || children.length === 0) return map;
  for (let child of children) {
    if (!map.has(child.id)) map.set(child.id, child);
    if (child.children) generateFlatList(child.children, map);
  }
  return map;
};

export const generateTreeData = (
  children: FormChild[] | undefined
): TreeDataNode[] => {
  const arr: TreeDataNode[] = [];
  if (!children || children.length === 0) return arr;
  for (let child of children) {
    arr.push({
      key: child.id,
      title: child.label,
      children: generateTreeData(child.children),
    });
  }
  return arr;
};

export const reverseToFormChildren = (
  treeData: TreeDataNode[],
  flatList: FlatList
): FormChild[] => {
  return treeData.map((node) => {
    const newNode = flatList.get(node.key) as FormChild;
    if (newNode?.children)
      newNode.children = reverseToFormChildren(node.children, flatList);
    return newNode;
  });
};

export const addNewField = (
  children: FormChild[] | undefined,
  previousChildId: string | undefined,
  newField: FormChild
): FormChild[] => {
  const arr: FormChild[] = previousChildId ? [] : [newField];
  if (!children || children.length === 0) return arr;
  for (let child of children) {
    const children =
      child.type === 'Section'
        ? { children: addNewField(child.children, previousChildId, newField) }
        : {};

    arr.push({
      ...child,
      ...children,
    });
    if (child.id === previousChildId) {
      const children = newField.type === 'Section' ? { children: [] } : {};
      arr.push({ ...newField, ...children });
    }
  }
  return arr;
};

export const deleteField = (
  children: FormChild[],
  fieldToDeleteId: string
): FormChild[] => {
  return children.filter((child) => {
    if (child.children)
      child.children = deleteField(child.children, fieldToDeleteId);
    return child.id !== fieldToDeleteId;
  });
};

export const getAllIds = (
  children: FormChild[] | undefined,
  arr: string[] = []
): string[] => {
  if (!children || children.length === 0) return arr;
  for (let child of children) {
    arr.push(child.id);
    getAllIds(child.children, arr);
  }
  return arr;
};

export const getStatusColor = (status: string | undefined): string => {
  switch (status) {
    case 'Draft':
      return 'processing';
    case 'Published':
      return 'success';
    default:
      return '';
  }
};

export const getOptions = <T>(obj: T): Option[] => {
  // @ts-ignore
  return Object.entries(obj).map(([key, value]) => ({
    label: value,
    value: key,
  }));
};

export const getAboveChoices = (
  flatList: FlatList,
  selectedNodeId: string
): FormChild[] => {
  const choices = [];
  for (let field of flatList.values()) {
    if (field.id === selectedNodeId) break;
    if (field.type === 'Choice') choices.push(field);
  }
  return choices;
};

export const getFieldDependencies = (field: FormChild) => {
  const obj: { [key: string]: DependsOn } = {};
  if (field.dependsOn && field.dependsOn.length > 0) {
    for (let dependency of field.dependsOn) {
      obj[dependency.fieldId] = dependency;
    }
  }
  return obj;
};

export const hasDependant = (flatList: FlatList, id: string) => {
  for (let field of flatList.values()) {
    const fieldDependencies = field.dependsOn;
    if (fieldDependencies && fieldDependencies.length > 0) {
      for (let dependency of fieldDependencies) {
        if (dependency.fieldId === id) return true;
      }
    }
  }
  return false;
};

export const hasDependenciesUnderneath = (
  flatList: FlatList,
  dragNodeId: string,
  dropNodeId: string,
  dropPosition: number
) => {
  const draggedNode = flatList.get(dragNodeId);
  if (!draggedNode?.dependsOn) return false;
  let isUnder = dropPosition === -1 ? true : false;
  const fieldDependencies = getFieldDependencies(draggedNode);
  for (let field of flatList.values()) {
    if (isUnder) {
      if (fieldDependencies[field.id]) return true;
    }
    if (field.id === dropNodeId) isUnder = true;
  }
  return false;
};

export const hasDependantAbove = (
  flatList: FlatList,
  dragNodeId: string,
  dropNodeId: string
) => {
  let allAboveDependencies: { [key: string]: DependsOn } = {};
  for (let field of flatList.values()) {
    const fieldDependencies = field.dependsOn;
    if (fieldDependencies && fieldDependencies.length > 0) {
      for (let dependency of fieldDependencies) {
        allAboveDependencies[dependency.fieldId] = dependency;
      }
    }
    if (field.id === dropNodeId) break;
  }
  return allAboveDependencies[dragNodeId] ? true : false;
};

export const parseMetadataDependencies = (dependencies: {
  [key: string]: string;
}) => {
  return Object.entries(dependencies).map((dependency) => ({
    key: dependency[0],
    value: dependency[1],
  }));
};

//Transforming metadata dependecy array in object
export const normalizeMetadataDependencies = (
  dependencies: { key: string; value: string }[]
) => {
  if (dependencies.length === 0) return;
  const normalizedDeps: { [key: string]: string } = {};
  for (let dependency of dependencies) {
    if (!normalizedDeps[dependency.key])
      normalizedDeps[dependency.key] = dependency.value;
  }
  return normalizedDeps;
};

export const normalizeFormValues = (values: any): FormChild => {
  const newValues: { [key: string]: any } = {};
  for (let [key, value] of Object.entries<any>(values)) {
    switch (key) {
      case 'metadata':
        const metadata = {
          ...value,
          dependOn: normalizeMetadataDependencies(value.dependOn),
        };
        const areAllValuesUndefined = Object.values(metadata).every((v) => !v);
        newValues[key] = areAllValuesUndefined ? undefined : metadata;
        break;
      case 'answers':
      case 'dependsOn':
      case 'attachments':
        const isArrayEmpty = value?.length === 0;
        newValues[key] = isArrayEmpty ? undefined : value;
        break;
      case 'range':
        if (
          values?.approvalRequired === true &&
          (!!value?.min || value?.min === 0 || !!value.max || value?.max === 0)
        ) {
          if (
            values?.errorMessage !== undefined &&
            values?.errorMessage !== ''
          ) {
            const newConditions = [];
            if (!!value?.min || value?.min === 0) {
              newConditions.push({
                value: `${value.min}`,
                operator: 'GreaterThanOrEqual',
              });
            }
            if (!!value?.max || value?.max === 0) {
              newConditions.push({
                value: `${value.max}`,
                operator: 'LessThanOrEqual',
              });
            }
            const validation = {
              conditions: newConditions,
            };
            newValues.isValidate = true;
            newValues.validation = validation;
            newValues.validation.message = values.errorMessage;
          } else {
            throw new Error('Please reenter the Error Message');
          }
        } else {
          newValues.isValidate = false;
          newValues.validation = undefined;
        }
        break;
      case 'validAnswers':
        if (
          values?.approvalRequired === true &&
          !!value?.length &&
          value.length > 0
        ) {
          if (
            values?.errorMessage !== undefined &&
            values?.errorMessage !== ''
          ) {
            const validationChoice = {
              conditions: value.map((item: Answer, i: number) => {
                if (typeof value[i] === 'object') {
                  return value[i];
                } else {
                  return {
                    value: item,
                    operator: 'Match',
                  };
                }
              }),
            };
            newValues.isValidate = true;
            newValues.validation = validationChoice;
            newValues.validation.message = values.errorMessage;
          } else {
            throw new Error('Please reenter the Error Message');
          }
        } else {
          newValues.isValidate = false;
          newValues.validation = undefined;
        }
        break;
      case 'errorMessage':
        break;
      default:
        newValues[key] = value;
    }
  }
  return newValues as FormChild;
};

export const areItemsDifferent = (itemA: any, itemB: any) => {
  return JSON.stringify(itemA) !== JSON.stringify(itemB);
};
