import moment from 'moment';

export const calendarWeeks = [...Array(25)].map((_, i) => {
  const date = moment().add(i - 6, 'week');
  const monday = date.day('Monday').format('DD.MM.YYYY');
  const saturday = date.day('Saturday').format('DD.MM.YYYY');
  const key = `${date.isoWeek()}-${date.year()}`;
  const text = `KW ${date
    .isoWeek()
    .toString()
    .padStart(2, '0')} / ${monday} - ${saturday}`;
  return { key, text };
});
