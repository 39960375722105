import { FC, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Button, Space, InputRef } from 'antd';
import { RootState } from '../../../../store';
import ApiRepository from '../../../../services/api/apiRepository';
import { showNotificationMessage } from '../../../../utils/notification';
import { useIntl } from 'react-intl';
import { SendSvg } from '../../../../components/icons/sendPlainSvg';
import styles from '../detailDrawer.module.scss';

const { TextArea } = Input;

interface Props {
  selectedNote?: any;
  editMode: boolean;
  handleEditMode: (mode: boolean) => void;
  updateMode?: boolean;
  fetchAppointmentData: () => void;
}

const NoteForm: FC<Props> = ({
  selectedNote,
  editMode,
  updateMode = false,
  handleEditMode,
  fetchAppointmentData,
}) => {
  const intl = useIntl();

  const detailDrawerState = useSelector(
    (state: RootState) => state.detailDrawer
  );
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      note: selectedNote?.text,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNote]);

  const handleSave = async () => {
    if (detailDrawerState?.workOrderDetail?.externalCaseId) {
      setLoading(true);
      handleEditMode(false);
      try {
        const values = await form.validateFields();
        await ApiRepository.sendWorkOrderNote(
          detailDrawerState?.workOrderDetail?.externalCaseId,
          updateMode
            ? selectedNote.appointmentId
            : detailDrawerState?.appointmentDetail?.id,
          values.note,
          updateMode ? selectedNote.id : ''
        );
        fetchAppointmentData();
        setLoading(false);
      } catch (error: any) {
        showNotificationMessage({
          message: intl.formatMessage({
            id: error.errorKey ?? 'error_post_appointment_note',
          }),
          error: error,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (editMode === true) inputRef?.current?.focus({ cursor: 'end' });
  }, [editMode]);

  return (
    <Form
      form={form}
      style={{
        marginTop: '4px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Form.Item
        name="note"
        rules={[{ required: true, message: 'Insert a note' }]}
        style={{ width: !updateMode ? '554px' : '530px' }}
      >
        <TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          allowClear
          ref={inputRef}
        />
      </Form.Item>
      <Form.Item style={{ width: '38px' }}>
        <Space>
          <Button
            type="primary"
            className={styles.noteFormButton}
            loading={loading}
            onClick={handleSave}
          >
            <SendSvg width={18.75} height={17.5} />
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default NoteForm;
