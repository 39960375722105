import React from 'react';

const User: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="#ACACB3"
    />
    <path
      d="M23.3238 10.5225C23.3137 10.5044 23.2996 10.4863 23.2836 10.4723C23.1831 10.3718 23.0204 10.3718 22.92 10.4723L19.4586 13.9336L18.0945 12.5696L21.5579 9.10619C21.574 9.09011 21.586 9.07404 21.5981 9.05596C21.6704 8.93342 21.6302 8.77672 21.5077 8.7044C19.5349 7.53922 16.9514 7.80641 15.2539 9.50194C13.9079 10.8479 13.4619 12.7564 13.9179 14.472L8.03179 20.3582C7.97152 20.4185 7.97554 20.5169 8.03781 20.5792L11.447 23.9883C11.5092 24.0506 11.6097 24.0546 11.6679 23.9944L17.5521 18.1102C19.2697 18.5682 21.1782 18.1243 22.5262 16.7763C24.2217 15.0787 24.4889 12.4952 23.3238 10.5225ZM21.5037 15.7517C20.3365 16.9189 18.5847 17.1439 17.1925 16.4267L17.0157 16.6035L17.0137 16.6015L11.5092 22.108L9.91817 20.5169L14.5407 15.8944C14.5407 15.8944 14.5407 15.8964 14.5427 15.8964L15.6034 14.8357C14.8863 13.4435 15.1113 11.6917 16.2784 10.5245C16.6549 10.1477 17.1082 9.85663 17.6075 9.67114C18.1067 9.48565 18.6402 9.41012 19.1713 9.44971L16.5014 12.1176C16.3808 12.2383 16.3131 12.402 16.3131 12.5726C16.3131 12.7432 16.3808 12.9069 16.5014 13.0276L19.0025 15.5287C19.1233 15.6493 19.2869 15.7171 19.4576 15.7171C19.6282 15.7171 19.7919 15.6493 19.9126 15.5287L22.5804 12.8609C22.6548 13.9015 22.2912 14.9662 21.5037 15.7517Z"
      fill="white"
    />
    <path
      d="M16 31C7.71573 31 1 24.2843 1 16H-1C-1 25.3888 6.61116 33 16 33V31ZM31 16C31 24.2843 24.2843 31 16 31V33C25.3888 33 33 25.3888 33 16H31ZM16 1C24.2843 1 31 7.71573 31 16H33C33 6.61116 25.3888 -1 16 -1V1ZM16 -1C6.61116 -1 -1 6.61116 -1 16H1C1 7.71573 7.71573 1 16 1V-1Z"
      fill="white"
    />
  </svg>
);

export default User;
