import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Divider, Modal, Space, Spin, Typography } from 'antd';
import {
  ExclamationCircleOutlined,
  PhoneOutlined,
  CalendarOutlined,
  PushpinOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {
  AppointmentType,
  IAppointmentStatusActionType,
} from '../../constants/types';
import styles from './appointmentDetailsSidebar.module.scss';
import translation from '../../i18n/translation';
import Sidebar from '../../components/sidebar/sidebar';
import SectionCollapse from './components/sectionCollapse';
import { CarSvg } from '../../components/icons/carSvg';
import AppointmentContainer from './components/appointmentContainer';
import { NotesSvg } from '../../components/icons/notesSvg';
import { MailboxSvg } from '../../components/icons/mailboxSvg';
import { InfoSvg } from '../../components/icons/infoSvg';
import { WorkOrderSvg } from '../../components/icons/workOrderSvg';
import { DownloadSvg } from '../../components/icons/downloadSvg';
import AccessControl from '../../components/accessControl';
import { IFeatures } from '../../store/rolesAndPrivileges';

interface IAppointmentDetailsSidebar {
  loading?: boolean;
  userData: any;
  relatedAppointments?: AppointmentType[];
  isAllFormsApproved: boolean;
  onUpdateAppointmentStatus?: (
    actionType: IAppointmentStatusActionType
  ) => void;
}

const AppointmentDetailsSidebar: React.FC<IAppointmentDetailsSidebar> = ({
  loading,
  userData,
  relatedAppointments,
  isAllFormsApproved = false,
  onUpdateAppointmentStatus = null,
}) => {
  const { Text } = Typography;
  const { confirm } = Modal;

  const intl = useIntl();

  const showConfirm = (
    actionType: IAppointmentStatusActionType,
    allFormsApproved: boolean = false
  ) => {
    confirm({
      title: intl.formatMessage({
        id:
          actionType === IAppointmentStatusActionType.close
            ? 'close_appointment'
            : 'reopen_appointment',
      }),
      icon: <ExclamationCircleOutlined />,
      okText:
        actionType === IAppointmentStatusActionType.close
          ? intl.formatMessage({ id: 'close_appointment_confirm' })
          : 'OK',
      cancelText: intl.formatMessage({ id: 'cancel' }),
      content: intl.formatMessage({
        id:
          actionType === IAppointmentStatusActionType.close
            ? allFormsApproved
              ? 'close_appointment_message'
              : 'close_appointment_pending_forms_message'
            : 'reopen_appointment_message',
      }),
      onOk() {
        if (onUpdateAppointmentStatus) {
          onUpdateAppointmentStatus(actionType);
        }
      },
    });
  };

  const onOpenAddressLink: any = (url: string) => {
    window.open(url, '_blank', 'noreferrer noopener');
  };

  return (
    <Sidebar width={290} sider="left" breakpoint="sm">
      {loading ? (
        <div className={styles.container}>
          <Spin style={{ marginTop: '25px' }} />
        </div>
      ) : (
        <div className={styles.container}>
          <Space direction="vertical">
            {userData?.customer?.name && (
              <Space style={{ padding: '0 0 8px 16px' }}>
                <MailboxSvg />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text strong className={styles.name}>
                    {userData?.customer?.name}
                  </Text>
                  <Text type="secondary" className={styles.subtext}>
                    {translation('customer_name')}
                  </Text>
                </div>
              </Space>
            )}

            {userData?.customerAddress?.address1 && (
              <Space
                className={styles.spaceContent}
                style={{ maxWidth: '90%' }}
              >
                <PushpinOutlined className={styles.pushpin} />
                <Text
                  type="secondary"
                  className={styles.addressLink}
                  onClick={() =>
                    onOpenAddressLink(
                      `http://maps.google.com/maps/dir/${userData?.customerAddress?.address1}/`
                    )
                  }
                >
                  {userData?.customerAddress?.address1}
                </Text>
              </Space>
            )}

            {userData?.customerAddress?.telephone && (
              <Space className={styles.spaceContent}>
                <PhoneOutlined className={styles.phone} />
                <Text type="secondary">
                  {userData?.customerAddress?.telephone}
                </Text>
              </Space>
            )}
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space style={{ alignItems: 'start', padding: '16px 0 0 16px' }}>
                <InfoSvg />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text strong>Info </Text>
                </div>
              </Space>
              <Space
                direction="vertical"
                style={{ width: '100%', padding: '8px 0' }}
                className={styles.hover}
              >
                {userData?.externalCaseId && (
                  <Space className={styles.spaceContent}>
                    <Text type="secondary" strong>
                      {translation('case_id')}:
                    </Text>
                    <Text ellipsis type="secondary" style={{ width: '135px' }}>
                      {userData?.externalCaseId}
                    </Text>
                  </Space>
                )}

                {userData?.appointmentType?.name && (
                  <Space className={styles.spaceContent}>
                    <WorkOrderSvg style={{ margin: '2px 0 0 -1px' }} />
                    <Text type="secondary">
                      {userData?.appointmentType?.name}
                    </Text>
                  </Space>
                )}

                {userData?.team?.name && (
                  <Space className={styles.spaceContent}>
                    <TeamOutlined style={{ marginRight: '3px' }} />
                    <Text type="secondary">{userData?.team?.name}</Text>
                  </Space>
                )}

                {userData?.appointmentDate && (
                  <Space className={styles.spaceContent}>
                    <CalendarOutlined />
                    <Text type="secondary">
                      {intl.formatDate(userData?.appointmentDate)}
                    </Text>
                  </Space>
                )}
              </Space>
            </Space>
            <div style={{ padding: '0 16px' }}>
              <Divider style={{ margin: '0' }} />
            </div>
            <Space direction="vertical" className={styles.scrollableContainer}>
              {userData?.files && Boolean(userData?.files.length) && (
                <SectionCollapse
                  defaultOpen={true}
                  title={intl.formatMessage({
                    id: 'documents',
                  })}
                  titleIcon={<NotesSvg />}
                >
                  <Space
                    direction="vertical"
                    style={{ alignItems: 'start', padding: '0 0 0 38px' }}
                  >
                    {userData?.files.map((f: any, index: number) => (
                      <div
                        className={styles.link}
                        key={`download-link-${index}`}
                      >
                        <a
                          download
                          target="_blank"
                          rel="noreferrer"
                          href={f.url}
                          className={styles.linkA}
                        >
                          <DownloadSvg className={styles.fileTextOutlined} />{' '}
                          {f.fileName}
                        </a>
                      </div>
                    ))}
                  </Space>
                </SectionCollapse>
              )}

              {!!relatedAppointments?.length && (
                <SectionCollapse
                  defaultOpen={true}
                  title={intl.formatMessage({
                    id: 'appointments',
                  })}
                  titleIcon={<CarSvg />}
                >
                  {relatedAppointments.map((appointment, i) => (
                    <AppointmentContainer
                      key={`related-appointment-${i}`}
                      title={appointment?.serviceAppointmentType}
                      appointmentDate={
                        appointment?.appointmentDate
                          ? intl.formatDate(appointment?.appointmentDate)
                          : ''
                      }
                      appointmentStatus={
                        appointment?.additionalInformation?.appointmentStatus
                      }
                      teamName={appointment?.team?.name}
                    />
                  ))}
                </SectionCollapse>
              )}
            </Space>
          </Space>
          <AccessControl featureKey={IFeatures.appointmentClose}>
            {onUpdateAppointmentStatus && userData?.status === 'Stopped' && (
              <div style={{ margin: '0 16px' }}>
                <Button
                  type="primary"
                  block
                  onClick={() =>
                    showConfirm(
                      IAppointmentStatusActionType.close,
                      isAllFormsApproved
                    )
                  }
                  shape="round"
                >
                  {' '}
                  {translation('close_appointment')}
                </Button>
              </div>
            )}
            {onUpdateAppointmentStatus && userData?.status === 'Closed' && (
              <div style={{ margin: '0 16px' }}>
                <Button
                  type="primary"
                  block
                  onClick={() => showConfirm(IAppointmentStatusActionType.open)}
                  shape="round"
                >
                  {' '}
                  {translation('reopen_appointment')}
                </Button>
              </div>
            )}
          </AccessControl>
        </div>
      )}
    </Sidebar>
  );
};

export default AppointmentDetailsSidebar;
