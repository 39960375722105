export const CheckoutSvg = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <rect
      width="16"
      height="16"
      rx="3"
      transform="matrix(-1 0 0 1 16.666 0)"
      fill="#C7C7CC"
    />
    <path
      d="M7.99935 3.33333C7.63116 3.33333 7.33268 3.03486 7.33268 2.66667C7.33268 2.29848 7.63116 2 7.99935 2C8.36754 2 8.66602 2.29848 8.66602 2.66667C8.66602 3.03486 8.36754 3.33333 7.99935 3.33333Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.0007L10 11.334"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.9987 14.0007L7.33203 11.334L9.33203 9.33398L8.66536 5.33398"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.666 8.00065L11.3327 6.00065L8.66602 5.33398L6.66602 7.33398L4.66602 8.00065"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
