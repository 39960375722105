import { FC, useState, useEffect, useMemo } from 'react';
import { Select, Form, Typography, Tooltip } from 'antd';
import { Option, DisplayAs, FieldItemProps, DisplayAsInput } from '../types';
import { getOptions } from '../utils';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemDisplayAs: FC<FieldItemProps> = ({
  editMode,
  fieldChanges,
  isPublishedItem,
  isNewField,
  formInstance,
}) => {
  const selectedFieldType = Form.useWatch('type');
  const displayAsType = Form.useWatch('displayAs');
  const [displayAsOptions, setDisplayAsOptions] = useState<Option[]>([]);

  const publishedItemValues = useMemo(
    () => ({
      displayAs: fieldChanges?.displayAs.publishedItem,
    }),
    [fieldChanges]
  );

  useEffect(() => {
    if (selectedFieldType === 'Choice') {
      setDisplayAsOptions(getOptions(DisplayAs));
      if (isNewField && formInstance && displayAsType === undefined) {
        formInstance.setFieldsValue({
          displayAs: DisplayAs.Radio,
        });
      }
    }
    if (selectedFieldType === 'Input') {
      setDisplayAsOptions(getOptions(DisplayAsInput));
      if (isNewField && formInstance && displayAsType === undefined) {
        formInstance.setFieldsValue({
          displayAs: DisplayAsInput.Other,
        });
      }
    }
  }, [selectedFieldType, formInstance, isNewField, displayAsType]);

  return selectedFieldType === 'Choice' ||
    selectedFieldType === 'Input' ||
    isPublishedItem ? (
    <Form.Item
      name="displayAs"
      label={
        fieldChanges?.displayAs.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('display_as_tooltip')}>
              <span>{translation('display_as')} </span>{' '}
            </Tooltip>

            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemDisplayAs />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('display_as_tooltip')}>
            <span>{translation('display_as')} </span>{' '}
          </Tooltip>
        )
      }
      rules={[{ required: true, message: 'Select how to display the field' }]}
    >
      <Select options={displayAsOptions} disabled={!editMode} />
    </Form.Item>
  ) : (
    <></>
  );
};

export default FormItemDisplayAs;
