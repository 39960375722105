export const CraftosSvg = () => (
  <svg
    width="100%"
    viewBox="0 0 1024 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_706_6352)">
      <path
        d="M1338.23 583.849C1404.79 867.164 1257.43 1373.83 868.297 1327.94C693.658 1307.34 462.98 1193 361.241 1012.63C190.83 710.518 593.641 355.97 907.187 364.482C1065.76 368.787 1292.56 389.467 1338.23 583.849Z"
        fill="url(#paint0_radial_706_6352)"
      />
      <g filter="url(#filter0_d_706_6352)">
        <path
          d="M641.388 723.973H720.973V644.388L628.124 551.54C657.826 537.355 691.195 532.726 723.636 538.292C756.077 543.858 785.995 559.345 809.269 582.62C832.544 605.894 848.031 635.812 853.597 668.253C859.163 700.694 854.534 734.063 840.349 763.765L1067 990.416C1077.55 1000.97 1083.48 1015.28 1083.48 1030.21C1083.48 1045.13 1077.55 1059.45 1067 1070C1056.45 1080.55 1042.13 1086.48 1027.21 1086.48C1012.28 1086.48 997.969 1080.55 987.416 1070L760.765 843.349C731.063 857.534 697.694 862.163 665.253 856.597C632.812 851.031 602.894 835.544 579.62 812.269C556.345 788.995 540.858 759.077 535.292 726.636C529.726 694.195 534.355 660.826 548.54 631.124L641.388 723.973Z"
          fill="url(#paint1_linear_706_6352)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_706_6352"
        x="525"
        y="536"
        width="582.482"
        height="582.482"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="16" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.0862745 0 0 0 0 0.0941176 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_706_6352"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_706_6352"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_706_6352"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(962.222 952.273) rotate(-135.871) scale(1106.22 1002.4)"
      >
        <stop stopColor="#FFC700" />
        <stop offset="1" stopColor="#FFED4E" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_706_6352"
        x1="786.121"
        y1="788.718"
        x2="1002.91"
        y2="998.66"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F2F2F3" />
      </linearGradient>
      <clipPath id="clip0_706_6352">
        <rect width="1024" height="1024" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
