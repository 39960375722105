import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tag, Button, Popover, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedDate } from 'react-intl';
import styles from '../appointmentTypeDetail.module.scss';
import { isEqual } from '../utils';
import ApiRepository from '../../../../services/api/apiRepository';
import async from 'async';
import { AppointmentTypeFile, AppointmentTypeParams } from '../../types';
import AppointmentTypesActions from '../../actions';
import * as routes from '../../../../router/routes';

interface Props {
  title?: string;
  appointmentType?: AppointmentTypeParams | null;
  onReset: () => void;
  isSaveButtonActive: boolean;
  formObject: any;
}

const AppointmentTypeHeader: FC<Props> = ({
  title,
  onReset,
  isSaveButtonActive,
  formObject,
  appointmentType,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const popoverContent = (
    <div>
      <p>
        <strong>Created:</strong>{' '}
        {<FormattedDate value={appointmentType?.createdAt} />}
      </p>
      <p>
        <strong>Updated:</strong>{' '}
        {<FormattedDate value={appointmentType?.updatedAt} />}
      </p>
    </div>
  );

  const handleUpdateGroup = async (group: any) => {
    try {
      const res = await ApiRepository.updateGroup(group);
      return res?.response;
    } catch (error: any) {
      message.error(error?.message, 5);
    }
  };
  const handleCreateNewGroup = async (group: any) => {
    try {
      const res = await ApiRepository.createGroup(group);
      return res?.response;
    } catch (error: any) {
      message.error(error?.message, 5);
      return error;
    }
  };
  const handleDeleteGroup = async (group: any) => {
    try {
      await ApiRepository.deleteGroup(group.id);
    } catch (error: any) {
      return error;
    }
  };
  const handleUpdateAppointmentType = async (appointmentType: any) => {
    try {
      const res = await ApiRepository.updateAppointmentTypeDetail(
        appointmentType
      );

      return res?.response;
    } catch (error: any) {
      message.error(error?.message, 5);
      return error;
    }
  };
  const handleCreateAppointmentType = async (appointmentType: any) => {
    try {
      const res = await ApiRepository.createAppointmentType(appointmentType);
      const responseId = res?.response.id;
      return responseId;
    } catch (error: any) {
      message.error(error?.message, 5);
      return error;
    }
  };

  const submitChanges = async () => {
    const values = await formObject.validateFields();
    if (values?.errorFields?.length > 0) {
      return;
    }
    const currentFormState = formObject.getFieldsValue(true);
    const newAppointmentTypeObject = {
      id: currentFormState.id,
      name: currentFormState.name,
      description: currentFormState.description,
      workTypeIds: currentFormState?.workTypes?.length
        ? currentFormState.workTypes.map((item: any) => item.id)
        : [],
      externalType: currentFormState.externalType,
      files: currentFormState?.files?.length
        ? currentFormState.files.filter(
            (file: AppointmentTypeFile) => file.status !== 'deleted'
          )
        : [],
    };
    //if id exists, the Appointment Type needs updating:
    if (currentFormState.id !== undefined) {
      // using route PUT /appointmentTypes/:id
      //Groups changes check first:
      const initialGroups = appointmentType?.groups;
      let indexedGroupsObj: any = {};
      if (initialGroups?.length && initialGroups.length > 0) {
        initialGroups.forEach((item: any) => {
          if (!indexedGroupsObj[item.id]) {
            indexedGroupsObj[item.id] = {
              ...item,
            };
          }
        });
      }
      const newGroupsState = currentFormState?.groups
        ? [...currentFormState?.groups]
        : [];
      const areGroupsEqual = isEqual(initialGroups, newGroupsState);
      //next functions kick in only if groups differ from the original state:
      if (!areGroupsEqual) {
        const updateableGroupsArray = newGroupsState.filter(
          (group) => group.id !== undefined && group.status !== 'deleted'
        );

        const updatedGroups = updateableGroupsArray.filter(
          (item) =>
            indexedGroupsObj[item.id] &&
            !isEqual(item, indexedGroupsObj[item.id])
        );

        const preparedUpdatedGroups = updatedGroups.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            sequence: item.sequence,
            appointmentTypeId: currentFormState.id,
            workTypeIds: item.workTypes.map((form: any) => form.id),
          };
        });

        const newlyCreatedGroups = newGroupsState.filter((item) => !item.id);
        const preparedNewGroups = newlyCreatedGroups.map((item: any) => {
          return {
            name: item.name,
            description: item.description,
            sequence: item.sequence,
            appointmentTypeId: currentFormState.id,
            workTypeIds: item.workTypes.map((form: any) => form.id),
          };
        });
        const deletedGroups = newGroupsState.filter(
          (item) => item.status === 'deleted'
        );

        Promise.all([
          async.each([newAppointmentTypeObject], handleUpdateAppointmentType),

          async.each(preparedUpdatedGroups, handleUpdateGroup),
          async.each(preparedNewGroups, handleCreateNewGroup),
          async.each(deletedGroups, handleDeleteGroup),
        ])
          .then((values) => {
            //refetching the data, reload => to do: take it outside of groups influence
            dispatch(AppointmentTypesActions.setLoading(true));
            onReset();
            dispatch(
              AppointmentTypesActions.fetchSelectedAppointmentTypeAsync(
                currentFormState.id
              )
            );
          })
          .catch((err: any) => {
            message.error(err?.message, 5);
          });
      } else {
        try {
          handleUpdateAppointmentType(newAppointmentTypeObject);
          dispatch(AppointmentTypesActions.setLoading(true));
          onReset();
          dispatch(
            AppointmentTypesActions.fetchSelectedAppointmentTypeAsync(
              currentFormState.id
            )
          );
        } catch (err: any) {
          message.error(err, 5);
        }
      }
    } else {
      //if no id, we are creating a new Appointment Type first and then groups:
      const newId = await handleCreateAppointmentType(newAppointmentTypeObject);

      if (!!newId) {
        const newlyCreatedGroups = currentFormState.groups;
        if (newlyCreatedGroups?.length && newlyCreatedGroups.length > 0) {
          const preparedGroups = newlyCreatedGroups.map((group: any) => {
            return {
              name: group.name,
              description: group.description,
              sequence: group.sequence,
              workTypeIds: group.workTypes.map((form: any) => form.id),
              appointmentTypeId: newId,
            };
          });
          async
            .each(preparedGroups, handleCreateNewGroup)
            .then(() => {
              dispatch(AppointmentTypesActions.setLoading(true));
              onReset();
              dispatch(
                AppointmentTypesActions.fetchSelectedAppointmentTypeAsync(newId)
              );
              navigate(routes.appointmentTypeDetail.pathWithParams(newId));
            })
            .catch((err) => {
              message.error(err?.message, 5);
            });
        } else {
          dispatch(AppointmentTypesActions.setLoading(true));
          onReset();
          dispatch(
            AppointmentTypesActions.fetchSelectedAppointmentTypeAsync(newId)
          );
          navigate(routes.appointmentTypeDetail.pathWithParams(newId));
        }
      }
    }
  };

  const defaultControls = [
    <Popover key="3c" content={popoverContent} placement="leftTop">
      <InfoCircleOutlined style={{ fontSize: '1.1em' }} />
    </Popover>,
    <Button key="2b" onClick={onReset}>
      Cancel
    </Button>,
    <Button
      key="2c"
      type="primary"
      disabled={!isSaveButtonActive}
      onClick={() => submitChanges()}
    >
      Save
    </Button>,
  ];

  return (
    <>
      <PageHeader
        className={styles.formHeader}
        title={title}
        tags={[
          <Tag key={appointmentType?.status ? appointmentType?.status : '22a'}>
            {appointmentType?.status}
          </Tag>,
        ]}
        onBack={() => navigate(`/${routes.ROOT_PAGES.appointmentTypes}`)}
        extra={[...defaultControls]}
      />
    </>
  );
};

export default AppointmentTypeHeader;
