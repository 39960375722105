import { FC, useEffect, useState } from 'react';
import { Typography, Spin, Space, Empty, Button, Divider } from 'antd';
import DelayReasonLogActions from './actions';
import styles from '../status-log-history/statusChangeLog.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { DelayReasonLogState } from './types';
import { AppointmentType } from '../../../constants/types';
import WidgetDelayAndStatusModal from '../../../components/widgetDelayAndStatusModal';
import SectionCollapse from '../detail-drawer/components/sectionCollapse';
import { EditOutlined } from '@ant-design/icons';
import translation from '../../../i18n/translation';
import DelayReasonItem from './components/delayReasonItem';
import { useIntl } from 'react-intl';

const { Text } = Typography;

interface Props {
  appointmentDetail: AppointmentType;
}

const DelayReasonLog: FC<Props> = ({ appointmentDetail }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const delayReasonLogState: DelayReasonLogState = useSelector(
    (state: RootState) => state.delayReasonLogData
  );

  const [showDelayedReasonModal, setShowDelayedReasonModal] = useState<any>(
    false
  );
  const onHandleGetDelayReasonLogs = () => {
    dispatch(
      DelayReasonLogActions.fetchDelayReasonLogAsync(appointmentDetail?.id)
    );
  };

  useEffect(() => {
    if (appointmentDetail?.id) {
      onHandleGetDelayReasonLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetail?.id]);

  const renderDelayReasonItem = (log: any, i: number) => {
    return (
      <Space
        style={{ width: '100%' }}
        direction="vertical"
        key={`appointment-delay-reason-${i}`}
      >
        {i !== 0 && <Divider style={{ width: '100%', margin: '0' }} />}
        <Space style={{ marginTop: '8px', width: '100%' }}>
          <Text type="secondary">{log?.updatedBy?.name}</Text>
          <Text disabled>
            {intl.formatDate(log?.timestamp, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}{' '}
            {intl.formatDate(log?.timestamp, {
              timeStyle: 'short',
              hour12: false,
            })}
          </Text>
        </Space>
        <DelayReasonItem delayedEndReason={log?.newValue} />
      </Space>
    );
  };

  return (
    <>
      <SectionCollapse
        key="DelayedEndReasonCollapse"
        title={intl.formatMessage({ id: 'delay_reason_history' })}
        defaultOpen={true}
        action={
          <Button
            type="link"
            icon={<EditOutlined />}
            style={{ padding: 0, margin: 0, height: '22px' }}
            onClick={() => setShowDelayedReasonModal(true)}
          >
            {' '}
            {translation('change_delay_reason')}
          </Button>
        }
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {delayReasonLogState.loadingDelayReasonLog ? (
            <Spin className={styles.spin} />
          ) : delayReasonLogState.delayReasonLog.length > 0 ? (
            delayReasonLogState.delayReasonLog.map((item, i) =>
              renderDelayReasonItem(item, i)
            )
          ) : (
            <Empty description={false} />
          )}
        </Space>
      </SectionCollapse>
      <WidgetDelayAndStatusModal
        appointment={appointmentDetail}
        modalOpen={showDelayedReasonModal}
        setModalOpen={setShowDelayedReasonModal}
        type="delay"
        cannotCompleteReasonCodeList={null}
        onCancel={(reloadCache) => {
          setShowDelayedReasonModal(false);
          if (reloadCache) onHandleGetDelayReasonLogs();
        }}
      />
    </>
  );
};

export default DelayReasonLog;
