import { FC, useMemo } from 'react';
import { Form, Tooltip, Typography } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FieldItemProps, FormFieldType } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
};

const formats = [
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'script',
  'indent',
  'link',
  'color',
  'background',
  'align',
];

const FormItemContent: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const selectedFieldType = Form.useWatch('type');
  const publishedItemValues = useMemo(
    () => ({
      content: fieldChanges?.content.publishedItem,
    }),
    [fieldChanges?.content]
  );

  return (
    <Form.Item
      rules={[
        {
          required: selectedFieldType === FormFieldType.Instruction,
          message: 'The instruction text is required for this field.',
        },
      ]}
      name="content"
      label={
        fieldChanges?.content.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('instruction_text_tooltip')}>
              <span>{translation('instruction_text')} </span>{' '}
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemContent />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('instruction_text_tooltip')}>
            <span>{translation('instruction_text')} </span>
          </Tooltip>
        )
      }
    >
      {editMode ? (
        <ReactQuill modules={modules} formats={formats} key="richText-edit" />
      ) : (
        <ReactQuill readOnly modules={{ toolbar: false }} key="richText-read" />
      )}
    </Form.Item>
  );
};

export default FormItemContent;
