import { AnyAction } from 'redux';
import { appInfoBannerTypes } from '../../constants/types';
import ApiRepository from '../../services/api/apiRepository';

const actions = {
  getAppInfoBannerText() {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: appInfoBannerTypes.GET_APP_INFO_DATA,
          payload: await ApiRepository.getAppInfoBannerText(),
        });
      } catch (err: any) {
        dispatch({
          type: appInfoBannerTypes.GET_APP_INFO_DATA_FAIL,
          payload: err,
        });
      }
    };
  },
};

export default actions;
