import { Dispatch } from 'redux';
import { DetailDrawerActions } from './types';
import ApiRepository from '../../../services/api/apiRepository';

export const fetchAppointmentDetailStart = () => ({
  type: DetailDrawerActions.FETCH_APPOINTMENT_DETAIL_START,
});

export const fetchWorkOrderDetailAsync = (
  externalId: string,
  externalCaseId: string,
  appointmentTypeName: string
) => async (dispatch: Dispatch) => {
  dispatch(fetchAppointmentDetailStart());
  try {
    const WODetailRes = await ApiRepository.getWorkOrdersDetailsData(
      externalCaseId,
      appointmentTypeName
    );
    dispatch({
      type: DetailDrawerActions.FETCH_WORK_ORDER_DETAIL_SUCCESS,
      payload: {
        workOrderDetail: WODetailRes?.response || null,
        externalId: externalId,
        appointmentTypeName: appointmentTypeName,
      },
    });
  } catch (error) {
    dispatch({
      type: DetailDrawerActions.FETCH_WORK_ORDER_DETAIL_FAILED,
      payload: error,
    });
  }
};

export const resetState = () => ({
  type: DetailDrawerActions.RESET_STATE,
});
