export const antdTheme = {
  token: {
    colorPrimary: '#3e7eff',
    colorPrimaryBg: '#ecf2ff',
    colorPrimaryBgHover: '#c5d8ff',
    colorPrimaryBorder: '#9fbfff',
    colorPrimaryBorderHover: '##78a5ff',
    colorPrimaryHover: '#518bff',
    colorPrimaryTextHover: '#78a5ff',
    colorPrimaryActive: '#2b58b3',
    colorPrimaryTextActive: '#3871e6',
    colorSuccess: '#64d59f',
    colorSuccessBorder: '#88edbd',
    colorWarning: '#ffc700',
    colorError: '#d72d2d',
    colorInfo: '#3e7eff',
    colorInfoBg: '#ecf2ff',
    colorInfoBgHover: '#c5d8ff',
    colorInfoBorder: '#9fbfff',
    colorInfoBorderHover: '#78a5ff',
    colorInfoHover: '#78a5ff',
    colorInfoActive: '#2b58b3',
    colorInfoTextHover: '#78a5ff',
    colorInfoTextActive: '#2b58b3',
    colorTextBase: '#161618',
    colorTextQuaternary: '#c7c7cc',
    colorTextTertiary: '#92929b',
    colorTextSecondary: '#47474d',
    colorText: '#2e2e33',
    colorBorder: '#c7c7cc',
    colorBorderSecondary: '#f2f2f3',
    colorWarningBg: '#FFF9E6',
    colorWarningBgHover: '#FFEEB3',
    colorWarningBorder: '#FFE380',
    colorWarningBorderHover: '#FFD84D',
    colorWarningHover: '#FFCD1A',
    colorWarningActive: '#e6b300',
    colorWarningTextHover: '#FFCD1A',
    colorWarningTextActive: '#e6b300',
    borderRadius: 8,
    borderRadiusXS: 4,
    boxShadowSecondary:
      '0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    boxShadow:
      '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);',
  },
};

export const antdThemeNone = {};
