import { AnyAction } from 'redux';
import { workType, workTypeState } from '../../constants/types';

const initialState: workTypeState = {
  loading: false,
  error: null,
  errorSubmit: false,
  workTypeData: {},
  currentFormField: {},
  assignment: {},
};

const mergeSubmissionComments: any = (data: any) => {
  if (data && data.answers) {
    data.answers.forEach((answer: any) => {
      answer.comments =
        data.comments && data.comments.length
          ? data.comments.filter(
              (comment: any) => comment.answerSnapshotId === answer.id
            )
          : [];
    });
  }
  return data;
};

const appointmentsDetailsReducer = (
  state = initialState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case workType.GET_WORK_TYPE_DATA:
      return {
        ...state,
        workTypeData: mergeSubmissionComments(payload),
        error: null,
        errorSubmit: false,
        loading: false,
      };

    case workType.POST_WORK_TYPE_DATA:
      return {
        ...state,
        ...(payload.id === state.workTypeData?.id && {
          workTypeData: mergeSubmissionComments(payload),
        }),
        error: null,
        errorSubmit: false,
        loading: false,
      };

    case workType.REFRESH_ANSWER_DATA:
      return {
        ...state,
        ...(payload.id === state.workTypeData?.id && {
          workTypeData: mergeSubmissionComments(payload),
        }),
        error: null,
        errorSubmit: false,
        loading: false,
      };

    case workType.SET_FORM_FIELD_LABEL:
      return {
        ...state,
        currentFormField: payload,
      };

    case workType.GET_ASSIGNMENT_DATA:
      return {
        ...state,
        assignment: payload.response,
      };

    case workType.SET_LOADING:
      return {
        ...state,
        loading: payload,
        workTypeData: {},
      };
    case workType.SET_SUBMIT_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case workType.GET_WORK_TYPE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        errorSubmit: false,
      };
    case workType.POST_WORK_TYPE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        errorSubmit: true,
      };
    case workType.RESET_WORK_TYPE_DATA_FAIL:
      return {
        ...state,
        error: null,
        errorSubmit: false,
      };
    case workType.SET_DEFAULT_VALUES:
      return {
        ...state,
        error: null,
        errorSubmit: false,
        workTypeData: {},
        currentFormField: {},
        assignment: {},
      };
    default:
      return state;
  }
};

export default appointmentsDetailsReducer;
