import { AnyAction } from 'redux';
import { TimeLogActions, TimeLogState } from './types';

const initialState: TimeLogState = {
  loadingTimeLog: false,
  timeLog: [],
  error: null,
};

export const timeLogReducer = (
  state: TimeLogState = initialState,
  action: AnyAction
): TimeLogState => {
  switch (action.type) {
    case TimeLogActions.FETCH_TIME_LOG_START:
      return {
        ...state,
        loadingTimeLog: true,
      };
    case TimeLogActions.FETCH_TIME_LOG_SUCCESS:
      return {
        ...state,
        loadingTimeLog: false,
        timeLog: action.payload.response,
      };
    case TimeLogActions.FETCH_TIME_LOG_FAILED:
      return {
        ...state,
        loadingTimeLog: false,
        error: action.payload,
      };
    case TimeLogActions.RESET_STATE:
      return {
        loadingTimeLog: false,
        timeLog: [],
        error: null,
      };
    default:
      return state;
  }
};
