import { AnyAction } from 'redux';
import ApiRepository from '../../../services/api/apiRepository';
import { DelayReasonLogActions } from './types';

const actions = {
  fetchDelayReasonLogAsync(id: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      dispatch({
        type: DelayReasonLogActions.FETCH_DELAY_REASON_LOG_START,
      });
      try {
        dispatch({
          type: DelayReasonLogActions.FETCH_DELAY_REASON_LOG_SUCCESS,
          payload: await ApiRepository.getDelayReasonLogByAppointmentId(id),
        });
      } catch (error: any) {
        dispatch({
          type: DelayReasonLogActions.FETCH_DELAY_REASON_LOG_FAILED,
          payload: error,
        });
      }
    };
  },

  resetState() {
    return {
      type: DelayReasonLogActions.RESET_STATE,
    };
  },
};

export default actions;
