export const RemoveFiltersSvg = ({ style = {} }) => (
  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" style={style}>
    <path
      d="M14.5734 1.60938H1.427C0.989496 1.60938 0.716282 2.08616 0.935925 2.46652L5.0895 9.52723V13.8237C5.0895 14.1397 5.34307 14.3951 5.65735 14.3951H10.3431C10.6574 14.3951 10.9109 14.1397 10.9109 13.8237V9.52723L15.0663 2.46652C15.2841 2.08616 15.0109 1.60938 14.5734 1.60938ZM9.63235 13.1094H6.36807V10.3237H9.63414V13.1094H9.63235ZM9.80378 8.88438L9.63414 9.1808H6.36628L6.19664 8.88438L2.65557 2.89509H13.3449L9.80378 8.88438Z"
      fill="currentColor"
    />
    <line
      x1="13.5858"
      y1="14"
      x2="1"
      y2="1.41421"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
