import { Layout } from 'antd';
import React, { PropsWithChildren } from 'react';
import styles from './sidebar.module.scss';

interface ISidebar extends PropsWithChildren {
  width?: number;
  sider?: 'left' | 'right';
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const Sidebar: React.FC<ISidebar> = ({
  children,
  width = 250,
  sider = 'left',
  breakpoint = 'sm',
}) => {
  const { Sider } = Layout;
  return (
    <Sider
      breakpoint={breakpoint}
      theme={'light'}
      width={width}
      className={styles[sider]}
    >
      {children}
    </Sider>
  );
};

export default Sidebar;
