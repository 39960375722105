import { FC } from 'react';
import { Form, Input, FormInstance } from 'antd';
import translation from '../../../i18n/translation';
import { useIntl } from 'react-intl';

interface Props {
  form: FormInstance;
}

const MetaDataForm: FC<Props> = ({ form }) => {
  const intl = useIntl();

  return (
    <Form layout="vertical" autoComplete="off" form={form}>
      <Form.Item
        label="Key"
        name="key"
        rules={[
          {
            required: true,
            message: translation('enter_key'),
          },
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'enter_key' })} />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: translation('enter_description'),
          },
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'enter_description' })} />
      </Form.Item>
    </Form>
  );
};

export default MetaDataForm;
