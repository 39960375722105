import { Button, Select, Space, Typography } from 'antd';
import { CalendarFilled } from '@ant-design/icons';
import { FilterColumns } from '../../../components/filterSection/filterSection';
import translation from '../../../i18n/translation';
import { calendarWeeks } from '../utils';
import styles from '../progress.module.scss';
import { Content } from 'antd/lib/layout/layout';
import { RemoveFiltersSvg } from '../../../components/icons/removeFiltersSvg';
import { SavedFiltersButton } from './savedFilters';
import { useIntl } from 'react-intl';
import { ROOT_PAGES } from '../../../router/routes';

const { Text } = Typography;
const { Option } = Select;

interface Props {
  onCalendarWeekChanged: any;
  pageTitle: string;
  week: number;
  plId: string[];
  teams: any;
  filterTree: any;
  filters: any;
  customerSearch: string;
  onChangeFilter: any;
  onResetAllFilters: any;
  loading: boolean;
  handleChangeUrl?: (arg0: any) => void;
}

export const ProgressHeader: React.FC<Props> = ({
  onCalendarWeekChanged,
  pageTitle,
  week,
  plId,
  teams,
  filterTree,
  filters,
  customerSearch,
  onChangeFilter,
  loading,
  onResetAllFilters,
  handleChangeUrl = () => {},
}) => {
  const intl = useIntl();

  return (
    <Content className={styles.header}>
      <Space size={16} style={{ width: '100%' }}>
        <div>
          <Text strong style={{ fontSize: '20px', marginRight: '24px' }}>
            {pageTitle}
          </Text>
          <Text strong>{translation('week')}:&nbsp;</Text>
          <Select
            onChange={onCalendarWeekChanged}
            defaultValue={6}
            popupMatchSelectWidth={false}
            value={week - 0 + 6}
          >
            {calendarWeeks.map((kw: any, i: number) => (
              <Option value={i} key={`cl-${kw.key}`}>
                {kw.text}
                {kw.key === calendarWeeks[6].key && (
                  <CalendarFilled className={styles.calendarIcon} />
                )}
              </Option>
            ))}
          </Select>
        </div>
        <FilterColumns
          type={'completion_goal_on'}
          filterTree={filterTree}
          selectedFilters={filters}
          onChangeFilter={onChangeFilter}
          flexDirection="horizontal"
          loading={loading}
          size={10}
        />
      </Space>
      <Space
        size="large"
        align="center"
        style={{ width: '130px', justifyContent: 'flex-end' }}
      >
        <SavedFiltersButton
          page={
            pageTitle ===
            intl.formatMessage({
              id: 'installation-progress',
            })
              ? ROOT_PAGES.installtionProgress
              : ROOT_PAGES.appointments
          }
          loading={loading}
          handleChangeUrl={handleChangeUrl}
        />
        <Button
          onClick={() => onResetAllFilters()}
          disabled={
            (!teams?.length || !teams) &&
            (!filters?.length || !filters) &&
            week - 0 === 0 &&
            (!plId || plId.length === 0) &&
            !customerSearch
          }
        >
          <RemoveFiltersSvg style={{ margin: '0 6px -2px 0' }} />
          {translation('clear_filters')}
        </Button>
      </Space>
    </Content>
  );
};
