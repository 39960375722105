import { AnyAction } from 'redux';
import {
  appointmentsTableTypes,
  appointmentsTeamsTypes,
  AppointmentsTableState,
  AppointmentsTeamsState,
} from '../../constants/types';

const initialState: AppointmentsTableState = {
  loading: false,
  error: null,
  startDate: '',
  endDate: '',
  generatingReportId: '',
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
  },
  appointmentsTableData: [],
};

const initialTeamsState: AppointmentsTeamsState = {
  loading: false,
  error: null,
  appointmentsTeamsData: [],
};

export const appointmentsTableReducer = (
  state = initialState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case appointmentsTableTypes.GET_APPOINTMENTS_DATA:
      let { response, pagination } = payload;
      return {
        ...state,
        appointmentsTableData: response,
        pagination: {
          currentPage: pagination.CurrentPage,
          pageSize: pagination.PageSize,
          totalCount: pagination.TotalCount,
        },
        error: null,
        loading: false,
      };
    case appointmentsTableTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case appointmentsTableTypes.SET_DEFAULT_PAGINATION:
      return {
        ...state,
        pagination: { ...initialState.pagination },
      };
    case appointmentsTableTypes.GET_APPOINTMENTS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case appointmentsTableTypes.SET_GENERATE_REPORT_ID:
      return {
        ...state,
        generatingReportId: payload,
      };
    default:
      return state;
  }
};

export const appointmentsTeamsReducer = (
  state = initialTeamsState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case appointmentsTeamsTypes.GET_APPOINTMENTS_TEAMS_DATA:
      const { response } = payload;
      return {
        ...state,
        appointmentsTeamsData: response,
        error: null,
        loading: false,
      };
    case appointmentsTeamsTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
