import { AnyAction } from 'redux';
import ApiRepository from '../../../services/api/apiRepository';
import { TimeLogActions } from './types';

const actions = {
  fetchTimeLogAsync(externalId: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        dispatch({
          type: TimeLogActions.FETCH_TIME_LOG_SUCCESS,
          payload: await ApiRepository.getTimeLogByExternalId(externalId),
        });
      } catch (error: any) {
        dispatch({
          type: TimeLogActions.FETCH_TIME_LOG_FAILED,
          payload: error,
        });
      }
    };
  },

  resetState() {
    return {
      type: TimeLogActions.RESET_STATE,
    };
  },
};

export default actions;
