import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'antd';
import styles from './appInfoBanner.module.scss';
import { RootState } from '../../store';
import AppInfoBannerActions from './actions';

const AppInfoBanner: React.FC = () => {
  const dispatch = useDispatch();

  const appInfoBannerState = useSelector(
    (state: RootState) => state.appInfoBannerData
  );

  useEffect(() => {
    dispatch(AppInfoBannerActions.getAppInfoBannerText());
  }, [dispatch]);

  return appInfoBannerState.text ? (
    <Alert
      className={styles.banner}
      banner
      closable
      type="warning"
      message={appInfoBannerState.text}
    />
  ) : null;
};

export default AppInfoBanner;
