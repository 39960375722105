export const CheckinSvg = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <rect x="0.333984" width="16" height="16" rx="3" fill="#8BB2FF" />
    <path
      d="M9.00065 3.33333C9.36884 3.33333 9.66732 3.03486 9.66732 2.66667C9.66732 2.29848 9.36884 2 9.00065 2C8.63246 2 8.33398 2.29848 8.33398 2.66667C8.33398 3.03486 8.63246 3.33333 9.00065 3.33333Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 14.0007L7 11.334"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0013 14.0007L9.66797 11.334L7.66797 9.33398L8.33464 5.33398"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.33398 8.00065L5.66732 6.00065L8.33398 5.33398L10.334 7.33398L12.334 8.00065"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
