export enum CheckedInUsersActions {
  FETCH_CHECKED_IN_USERS_START = 'FETCH_CHECKED_IN_USERS_START',
  FETCH_CHECKED_IN_USERS_SUCCESS = 'FETCH_CHECKED_IN_USERS_SUCCESS',
  FETCH_CHECKED_IN_USERS_FAILED = 'FETCH_CHECKED_IN_USERS_FAILED',
  RESET_STATE = 'RESET_STATE',
}

export interface CheckedInUsersState {
  loadingCheckedInUsers: boolean;
  checkedInUsersList: CheckedInUsersObject[];
  error: any;
}

export type CheckedInUsersObject = {
  externalId: string;
  team: {
    id: string;
    name: string;
    location: string;
    accountId: string;
    assignedPlId: string;
    assignedPlName: string;
  };
  user: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
  };
  checkInTime: string;
  checkOutTime?: string;
};
