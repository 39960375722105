import { AnyAction } from 'redux';
import ApiRepository from '../../services/api/apiRepository';
import { WorkOrderActionTypes } from './types';

const actions = {
  getWorkOrderData(externalCaseId: string, appointmentTypeName: string) {
    return async (dispatch: (arg0: AnyAction) => void) => {
      try {
        const response = await ApiRepository.getWorkOrdersDetailsData(
          externalCaseId,
          appointmentTypeName
        );
        dispatch({
          type: WorkOrderActionTypes.GET_WORKORDER_DATA,
          payload: response,
        });
      } catch (err: any) {
        dispatch({
          type: WorkOrderActionTypes.GET_WORKORDER_DATA_FAIL,
          payload: err,
        });
      }
    };
  },

  setLoading(loading: boolean) {
    return {
      type: WorkOrderActionTypes.SET_LOADING,
      payload: loading,
    };
  },

  resetState() {
    return {
      type: WorkOrderActionTypes.GET_WORKORDER_DATA_FAIL,
      payload: null,
    };
  },
};

export default actions;
