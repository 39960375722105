export const FirstReviewStepSvg = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M15.429 16.786L17 21.5M7 21.5L11 9.5L7 21.5ZM13 9.5L14.48 13.939L13 9.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.5C13.1046 9.5 14 8.60457 14 7.5C14 6.39543 13.1046 5.5 12 5.5C10.8954 5.5 10 6.39543 10 7.5C10 8.60457 10.8954 9.5 12 9.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12.5C5.526 15.455 8.588 17.5 12 17.5C15.41 17.5 18.473 15.452 20 12.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5.5V3.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
