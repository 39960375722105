export const PlaySvg = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M2.5 3.33398V12.6673L7.83333 8.00065L2.5 3.33398Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.83301 3.33398V12.6673L15.1663 8.00065L9.83301 3.33398Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
