import { FC, ReactElement, cloneElement } from 'react';
import { Form, Popover, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from '../formBuilderDetail.module.scss';

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 16, offset: 1 },
  },
};

interface Props {
  children: ReactElement;
  initialValues: object;
}

const PublishedItem: FC<Props> = ({ children, initialValues }) => {
  return (
    <Popover
      title="Published version"
      content={
        <Form
          {...formItemLayout}
          initialValues={initialValues}
          className={`${styles.formGenerator} ${styles.publishedItemForm}`}
          labelWrap
        >
          {cloneElement(children, {
            fieldChanges: null,
            isPublishedItem: true,
          })}
        </Form>
      }
    >
      <Text mark>
        <ExclamationCircleOutlined />
      </Text>
    </Popover>
  );
};

export default PublishedItem;
