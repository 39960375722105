export enum AppointmentTypesActionsTypes {
  SET_LOADING = 'FETCH_APPOINTMENT_TYPES_LOADING',
  FETCH_APPOINTMENT_TYPES_GROUPS_SUCCESS = 'FETCH_APPOINTMENT_TYPES_GROUPS_SUCCESS',
  FETCH_APPOINTMENT_TYPES_FAILED = 'FETCH_APPOINTMENT_TYPES_FAILED',
  FETCH_SELECTED_APPOINTMENT_TYPE_GROUP_SUCCESS = 'FETCH_SELECTED_APPOINTMENT_TYPE_GROUP_SUCCESS',
  FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED',
  SAVE_APPOINTMENT_TYPE_SUCCESS = 'SAVE_APPOINTMENT_TYPE_SUCCESS',
  RESET_APPOINTMENT_TYPES_GROUPS = 'RESET_APPOINTMENT_TYPES_GROUPS',
  FETCH_ALL_WORK_TYPES_SUCCESS = 'FETCH_ALL_WORK_TYPES_SUCCESS',
  FETCH_ALL_WORK_TYPES_FAILED = 'FETCH_ALL_WORK_TYPES_FAILED',
}

export interface AppointmentTypeParams {
  id: string;
  name: string;
  description: string;
  workTypes: WorkType[];
  files: [AppointmentTypeFile];
  groups?: [WorkTypesGroup] | [];
  externalType: string;
  status: string; //"Active", "Inactive", "Deleted"
  createdAt: string;
  updatedAt: string;
  createdBy: AppointmentTypeCreator;
  updatedBy: AppointmentTypeCreator;
}

export interface WorkType {
  id: string;
  name: string;
  description: string;
  groups?: string[];
}

export interface WorkTypesGroup {
  id: string;
  name: string;
  appointmentTypeId?: string;
  description?: string;
  sequence?: number;
  status?: string;
  workTypes?: WorkType[];
  recordStatus: string;
  createdAt: string;
  updatedAt?: string;
  createdBy: AppointmentTypeCreator;
  updatedBy?: AppointmentTypeCreator;
  temporaryId?: string;
}

export interface AppointmentTypeFile {
  fileName: string;
  url: string;
  originalFileName: string | null;
  label?: string | undefined;
  status?: string;
  name?: string;
}

export interface AppointmentTypeCreator {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

export type AppointmentTypeStatus = 'draft' | 'active' | '';

export interface Sorter {
  column: SorterColumn;
  order: string;
  field: string;
  columnKey: string;
}

export interface SorterColumn {
  title: any;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

export interface AppointmentTypeDetailParams {
  id?: string;
}

export interface WorkType {
  id: string;
  name: string;
  description: string;
}

export interface AppointmentTypeFile {
  fileName: string;
  url: string;
  originalFileName: string | null;
}

export interface AppointmentTypeCreator {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

/////////////////////////////

export interface FormDetailParams {
  formId: string;
  id: string;
  fieldId: string;
}

export type Priority = 'Default' | 'Priority';

export interface FormType {
  formId: string;
  status: string;
  revision: number;
  name: string;
  description: string;
  publishedAt: string;
  formStatus: string;
  formHandle: string;
  workType: WorkType;
  children: FormChild[];
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: AtedBy;
  updatedBy: AtedBy;
  priority: Priority;
}

export type FormChildItem =
  | string
  | FormFieldType
  | DisplayAs
  | Answer[]
  | Count
  | boolean
  | Metadata
  | ApprovalType
  | DependsOn[]
  | Attachment[]
  | FormChild[]
  | Metadata
  | undefined;

export type FormChild = {
  [key: string]: FormChildItem;
  id: string;
  type: FormFieldType;
  displayAs: DisplayAs;
  label: string;
  content?: string;
  answers?: Answer[];
  requiredAnswerCount?: Count;
  approvalRequired: boolean;
  required: boolean;
  additionalFieldData?: string;
  defaultValue?: string;
  metadata?: Metadata;
  approvalType: ApprovalType;
  dependsOn?: DependsOn[];
  photoCount?: Count;
  attachments?: Attachment[];
  legalText?: string;
  children?: FormChild[];
  commentRequired: boolean;
};

export interface Answer {
  id: string;
  label: string;
  description?: string;
}

export enum ApprovalType {
  Default = 'Default',
  SingleApprover = 'SingleApprover',
}

export enum AttachmentType {
  Link = 'Link',
  Image = 'Image',
  File = 'File',
}

export interface Attachment {
  type: AttachmentType;
  label: null;
  uri: string;
}

export interface DependsOn {
  fieldId: string;
  answerId: string;
  inverted: boolean;
}

export enum DisplayAs {
  Buttons = 'Buttons',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
}

export enum DisplayAsInput {
  Other = 'Other',
  QR = 'QR',
}

export interface Metadata {
  dependOn: { [key: string]: string };
  defaultValueKey: string;
  writeTo: string[];
}

export interface Count {
  min: number;
  max: number;
}

export enum FormFieldType {
  Choice = 'Choice',
  ImageUpload = 'ImageUpload',
  Input = 'Input',
  Section = 'Section',
  Instruction = 'Instruction',
  Date = 'Date',
  Signature = 'Signature',
}

export interface AtedBy {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

export interface Option {
  label?: string;
  value: string | number;
}

export interface FieldItemProps {
  editMode?: boolean;
  selectedNodeId?: string;
  fieldChanges?: FieldChanges | null;
}

export type FlatList = Map<string, FormChild>;

export interface EditMode {
  treeMenu: boolean;
  formDetail: boolean;
}

export enum FormDetailModals {
  newField = 'newField',
  deleteField = 'deleteField',
  editForm = 'editForm',
}
export interface FormDetailModalsState {
  newField: boolean;
  deleteField: boolean;
  editForm: boolean;
}

export interface FieldChangesItem {
  hasChanges: boolean;
  publishedItem: FormChildItem;
}

export type FieldChanges = {
  [key: string]: FieldChangesItem;
};
