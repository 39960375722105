import { FC, useMemo } from 'react';
import { Input, Form, Typography, Tooltip } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemLabel: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const publishedItemValues = useMemo(
    () => ({
      label: fieldChanges?.label.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      name="label"
      label={
        fieldChanges?.label.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('field_name_tooltip')}>
              <span>{translation('formbuilder_field_name')} </span>
            </Tooltip>
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemLabel />
              </PublishedItem>
            }
          </Text>
        ) : (
          <Tooltip title={translation('field_name_tooltip')}>
            {translation('formbuilder_field_name')}{' '}
          </Tooltip>
        )
      }
      rules={[{ required: true, message: 'Insert a field name' }]}
    >
      <Input disabled={!editMode} />
    </Form.Item>
  );
};

export default FormItemLabel;
