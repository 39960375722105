import { EnviromentType } from 'craftos-ui';

export const getEnv = (): EnviromentType => {
  if (IsTest()) {
    return 'test';
  } else if (isAcademy()) {
    return 'academy';
  } else if (isIntegration()) {
    return 'integration';
  } else if (isDevelopment()) {
    return 'test';
  } else if (isLocalhost()) {
    return 'localhost';
  }

  return 'production';
};

export const getApiUrl = (): string => {
  if (isProduction()) {
    return '';
  } else if (isAcademy()) {
    return '-academy';
  } else if (isIntegration()) {
    return '-integration';
  } else if (isDevelopment()) {
    return '-test'; //revert b4 test push
  }

  return '-test';
};

const getSFUatEnvUrl = (): string => {
  if (
    isAcademy() ||
    isIntegration() ||
    IsTest() ||
    isLocalhost() ||
    isDevelopment()
  ) {
    return 'enpalgmbh2--opsuat2.sandbox';
  }

  return 'enpalgmbh2';
};

export const getPlannerAppUrl = (): string =>
  isAcademy() || isIntegration()
    ? 'https://planner-test.craftos.enpal.tech'
    : `https://planner${getApiUrl()}.craftos.enpal.tech`;

export const getSFIntegrationUrl = (): string =>
  isAcademy()
    ? 'https://enpalgmbh2--craftacad.sandbox.my.salesforce.com/lightning'
    : `https://${getSFUatEnvUrl()}.lightning.force.com/lightning`;

const IsTest = (): boolean => {
  return document.location.origin.includes('test');
};

const isLocalhost = (): boolean => {
  return document.location.origin.includes('localhost');
};

const isAcademy = (): boolean => {
  return document.location.origin.includes('academy');
};

const isIntegration = (): boolean => {
  return document.location.origin.includes('integration');
};

const isDevelopment = (): boolean => {
  return document.location.origin.includes('development');
};

const isProduction = (): boolean => {
  return document.location.origin.includes('fulfilment.craftos.enpal.tech');
};
