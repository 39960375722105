import { FC } from 'react';

interface Props {
  style?: object;
  width?: string | number;
  height?: string | number;
}

export const NavigationIcon: FC<Props> = ({
  style,
  width = '14',
  height = '14',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" style={style}>
    <path
      d="M8.82929429,20 C8.41745788,21.1651924 7.30621883,22 6,22 C4.34324524,22 3,20.6568542 3,19 C3,17.3431458 4.34314575,16 6,16 C7.30621883,16 8.41745788,16.8348076 8.82929429,18 L17.5,18 C18.8807119,18 20,16.8807119 20,15.5 C20,14.1192881 18.8807119,13 17.5,13 L6.5,13 C4.01471863,13 2,10.9852814 2,8.5 C2,6.01471863 4.01471863,4 6.5,4 L9.58578644,4 L8.29289322,2.70710678 L9.70710678,1.29289322 L13.4142136,5 L9.70710678,8.70710678 L8.29289322,7.29289322 L9.58578644,6 L6.5,6 C5.11928813,6 4,7.11928813 4,8.5 C4,9.88071187 5.11928813,11 6.5,11 L17.5,11 C19.9852814,11 22,13.0147186 22,15.5 C22,17.9852814 19.9852814,20 17.5,20 L8.82929429,20 Z M6,18 C5.44771525,18 5,18.4477153 5,19 C5,19.5522847 5.44771525,20 6,20 C6.55228475,20 7,19.5522847 7,19 C7,18.4477153 6.55228475,18 6,18 Z M18,2 C19.6568542,2 21,3.34314575 21,5 C21,6.65685425 19.6568542,8 18,8 C16.3431458,8 15,6.65685425 15,5 C15,3.34314575 16.3431458,2 18,2 Z M18,4 C17.4477153,4 17,4.44771525 17,5 C17,5.55228475 17.4477153,6 18,6 C18.5522847,6 19,5.55228475 19,5 C19,4.44771525 18.5522847,4 18,4 Z"
      fillRule="evenodd"
    />
  </svg>
);
