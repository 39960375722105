import { Avatar, Select, Space, Tooltip, Typography } from 'antd';
import TreeSelect, { TreeNode } from 'antd/lib/tree-select';
import cNames from 'classnames';
import { IAppointmentType, ITeam } from '../../../constants/types';
import { getListHueOfNumbers, getNameInitials } from '../../../utils/helpers';
import translation from '../../../i18n/translation';
import styles from '../progress.module.scss';
import { useEffect, useState } from 'react';
const { Text } = Typography;
const { Option } = Select;

const DEFAULT_BRANCH = 'Berlin';

interface Props {
  progressDashboardType: string;
  onCancelBubble: any;
  appointmentTypes?: IAppointmentType[];
  appointmentType?: string | undefined;
  onAppointmentTypeChange?: any;
  branchList: any;
  branch: any;
  onBranchChange: any;
  leadIdList: any;
  teamLeads: any;
  plId: string[];
  onPlChange: any;
  teamsList: any;
  teams: any;
  onTeamsFilterItemSelect: any;
  onResetTeamFilter: any;
  loading: boolean;
}

export const ProgressFilters: React.FC<Props> = ({
  progressDashboardType,
  onCancelBubble,
  loading,
  appointmentTypes,
  appointmentType,
  onAppointmentTypeChange,
  branchList,
  branch,
  onBranchChange,
  leadIdList,
  teamLeads,
  plId,
  onPlChange,
  teamsList,
  teams,
  onTeamsFilterItemSelect,
  onResetTeamFilter,
}) => {
  const hueNumbers = getListHueOfNumbers();
  const [numOfAvatars, setNumOfAvatars] = useState<number>(12);

  const handleWindowResize = () => {
    let { innerWidth } = window;
    if (innerWidth)
      setNumOfAvatars(
        Math.floor(innerWidth / 100) +
          (progressDashboardType !== 'generic' ? 4 : 0)
      );
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowResize);
      handleWindowResize();
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOnAvatar = (val: string) => {
    if (plId?.length === 0) {
      onPlChange([val]);
    } else {
      let ind = plId.indexOf(val);
      if (ind > -1) {
        let updatePlId = [...plId];
        updatePlId.splice(ind, 1);
        onPlChange(updatePlId);
      } else {
        onPlChange([...plId, val]);
      }
    }
  };

  return (
    <div
      className={styles.teamFilterContainer}
      onClick={(event: any) => onCancelBubble(event)}
    >
      <div>
        <Space size="large">
          {progressDashboardType === 'generic' && (
            <Select
              className={styles.branch}
              style={{ width: '190px', marginRight: '5px' }}
              loading={loading || !appointmentTypes}
              defaultValue={appointmentType ? appointmentType : ''}
              value={appointmentType}
              onChange={onAppointmentTypeChange}
              disabled={loading}
            >
              <Option key={`appointmentType-all`} value="">
                {translation('all_appointments')}
              </Option>
              {!!appointmentTypes &&
                appointmentTypes.map(
                  (item: IAppointmentType, index: number) => {
                    return (
                      <Option
                        key={`appointmentType-${item.name}-${index}`}
                        value={item.id}
                      >
                        {item.name}
                      </Option>
                    );
                  }
                )}
            </Select>
          )}

          <Select
            className={styles.branch}
            loading={!branchList.length}
            defaultValue={
              branchList.length > 0 ? branchList[0] : DEFAULT_BRANCH
            }
            value={branch}
            onChange={onBranchChange}
            disabled={loading}
          >
            {branchList.map((branch: string, index: number) => {
              return (
                <Option key={`branches-${branch}-${index}`} value={branch}>
                  {branch}
                </Option>
              );
            })}
          </Select>

          <Avatar.Group maxCount={numOfAvatars}>
            {leadIdList.length
              ? leadIdList.map((id: string, index: number) => {
                  let isSelected = plId.includes(id);
                  return (
                    teamLeads &&
                    id &&
                    id !== 'null' && (
                      <div
                        key={`${id}-${index}`}
                        className={cNames(styles.avatarContent, {
                          [styles.selectedAvatar]: isSelected,
                        })}
                        onClick={() =>
                          loading ? undefined : handleClickOnAvatar(id)
                        }
                      >
                        <Tooltip title={teamLeads[id] ?? 'Unknown'}>
                          <Avatar
                            size={36}
                            className={cNames(
                              'avatarCenterText',
                              styles.plAvatar,
                              {
                                [styles.selectedPLAvatar]: isSelected,
                              }
                            )}
                            style={{
                              backgroundColor: `hsl(${hueNumbers[index]}, 70%,70%)`,
                            }}
                          >
                            <Text
                              strong
                              style={{
                                color: 'white',
                                fontSize: '12px',
                              }}
                            >
                              {getNameInitials(teamLeads[id] ?? '') || '?'}
                            </Text>
                          </Avatar>
                        </Tooltip>
                      </div>
                    )
                  );
                })
              : null}
          </Avatar.Group>
        </Space>
      </div>
      <div style={{ paddingTop: '3px' }}>
        <TreeSelect
          id="ga-select-team"
          className={styles.worktypeFilterTree}
          style={{ width: '20vw' }}
          value={teamsList.length > 0 ? teams : []}
          onChange={onTeamsFilterItemSelect}
          onClear={onResetTeamFilter}
          allowClear
          treeCheckable
          maxTagCount="responsive"
          loading={!teamsList.length}
          popupMatchSelectWidth={false}
          treeNodeLabelProp="title"
          showCheckedStrategy="SHOW_PARENT"
          placeholder={translation('select_teams')}
          filterTreeNode={(input: String, option: any) =>
            option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={loading}
        >
          {teamsList.length > 0 &&
            teamsList.map((team: ITeam, i: number) => (
              <TreeNode value={team.id} key={team.id} title={team.name} />
            ))}
        </TreeSelect>
      </div>
    </div>
  );
};
