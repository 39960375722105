import { FC, useEffect, useState } from 'react';
import { RootState } from '../../../store';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import { showNotificationMessage } from '../../../utils/notification';
import {
  getMetaData,
  setMetaDataLoading,
  resetMetaDataError,
} from '../actions';
import styles from './metaDataModal.module.scss';

import MetaDataTable from './metaDataTable';
import CreateMetaDataModal from './createMetaDataModal';

interface Props {
  visible: boolean;
  hideModal: () => void;
}

const MetaDataModal: FC<Props> = ({ visible, hideModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const metaDataState: any = useSelector((state: RootState) => state.metaData);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(setMetaDataLoading(true));
    dispatch(getMetaData);
  }, [dispatch]);

  useEffect(() => {
    if (metaDataState.submitted) {
      handleCloseModal();
    }
    setMetaDataList(metaDataState.metaDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDataState]);

  useEffect(() => {
    if (metaDataState.error) {
      showNotificationMessage({
        message: intl.formatMessage({ id: metaDataState.error.errorKey }),
        error: metaDataState.error,
        onClose: () => dispatch(resetMetaDataError()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDataState.error]);

  return (
    <Modal
      title="Metadata Repository"
      style={{
        top: 20,
      }}
      bodyStyle={{
        height: 'calc(100vh - 100px)',
      }}
      width="100%"
      open={visible}
      onCancel={hideModal}
      footer={null}
    >
      <div className={styles.metaDataModalButtons}>
        <Button type="primary" onClick={() => setShowModal(true)}>
          New Metadata
        </Button>
      </div>
      <MetaDataTable loading={metaDataState.loading} list={metaDataList} />
      <CreateMetaDataModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </Modal>
  );
};

export default MetaDataModal;
