import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import AppLayout from '../../components/appLayout';
import styles from './approvalPackages.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { IQueue } from '../../constants/types';
import ApprovalPackageActions from './actions';
import { RootState } from '../../store';
import { workTypeAssignment } from '../../router/routes';
import { showNotificationMessage } from '../../utils/notification';
import { useIntl } from 'react-intl';
import translation from '../../i18n/translation';
import useAutoFetchData from '../../utils/useAutoFetchData';
import UserApprovalPackagesDrawer from './userApprovalPackagesDrawer';

const ApprovalPackages: React.FC = () => {
  const { Title, Text } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const approvalPackageState = useSelector(
    (state: RootState) => state.approvalPackage
  );

  const [firstReviewCount, setFirstReviewCount] = useState<number>(0);
  const [secondReviewCount, setSecondReviewCount] = useState<number>(0);
  const [personalReviewCount, setPersonalReviewCount] = useState<number>(0);
  const [approvalPackagesDrawerOpen, setApprovalPackagesDrawerOpen] = useState(
    false
  );
  useEffect(() => {
    dispatch(ApprovalPackageActions.setDefaultValues());
    dispatch(ApprovalPackageActions.setLoading(true));
    dispatch(ApprovalPackageActions.getAssignedApprovalPackageInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAutoFetchData(() => {
    dispatch(ApprovalPackageActions.getAssignedApprovalPackageInfo());
    dispatch(ApprovalPackageActions.setLoading(true));
  });

  useEffect(() => {
    if (
      approvalPackageState.loading &&
      approvalPackageState.assignment?.assignmentId
    ) {
      dispatch(ApprovalPackageActions.setLoading(false));
      navigate(
        `${workTypeAssignment.pathWithParams(
          approvalPackageState.assignment.appointmentId,
          approvalPackageState.assignment.assignmentId
        )}`
      );
    }
  }, [
    dispatch,
    approvalPackageState.assignment,
    approvalPackageState.loading,
    navigate,
  ]);

  useEffect(() => {
    if (approvalPackageState.packageInfo) {
      setFirstReviewCount(
        (approvalPackageState.packageInfo.firstCount ?? 0) +
          (approvalPackageState.packageInfo.firstPriorityCount ?? 0)
      );
      setSecondReviewCount(
        (approvalPackageState.packageInfo.secondCount ?? 0) +
          (approvalPackageState.packageInfo.secondPriorityCount ?? 0)
      );
      if (approvalPackageState.packageInfo?.userStatistics?.length) {
        let personalCount = 0;
        approvalPackageState.packageInfo.userStatistics.forEach((item: any) => {
          personalCount = personalCount + (item.completed ?? 0);
        });
        setPersonalReviewCount(personalCount);
      }
    }
  }, [dispatch, approvalPackageState.packageInfo]);

  useEffect(() => {
    if (approvalPackageState.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id:
            approvalPackageState.error.errorKey ??
            'error_fetch_approval_package',
        }),
        error: approvalPackageState.error,
        onClose: () => dispatch(ApprovalPackageActions.resetError()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalPackageState.error]);

  const onStartReviews = (queue: IQueue) => {
    dispatch(ApprovalPackageActions.setLoading(true));
    dispatch(ApprovalPackageActions.assignApprovalPackage(queue));
  };
  const onToggleDrawer = () => {
    setApprovalPackagesDrawerOpen(!approvalPackagesDrawerOpen);
  };
  const onContinuePackage = () => {
    let appointmentIds =
      approvalPackageState.packageInfo.assignment.formInstance.appointmentId ??
      [];
    if (!appointmentIds || appointmentIds.length < 1) {
      return;
    }
    navigate(
      `${workTypeAssignment.pathWithParams(
        appointmentIds[appointmentIds.length - 1],
        approvalPackageState.packageInfo.assignment?.id
      )}`
    );
  };

  return (
    <AppLayout>
      <Layout className={styles.container}>
        <Spin
          spinning={!!approvalPackageState.loading}
          style={{ height: '100%' }}
        >
          <Space direction="vertical" className={styles.header}>
            <Text>{translation('approval-packages')}</Text>
            <Title level={4}>{translation('approval_package_welcome')}</Title>
            {!!approvalPackageState.packageInfo.assignment?.id && (
              <Alert
                className={styles.twoEyesInfo}
                message={
                  <Text style={{ fontSize: '12px' }} type="secondary">
                    {translation('incomplete_pakage_note')}
                  </Text>
                }
                type="info"
                showIcon
              />
            )}
          </Space>
          <Divider />
          <Row
            align="middle"
            justify="space-between"
            className={styles.reviewedPackagesContainer}
          >
            <Col>
              <Text style={{ color: '#fff' }}>
                {translation('reviewed_packages')}:
              </Text>
              <Badge
                count={personalReviewCount ? personalReviewCount : 0}
                style={{
                  marginLeft: '6px',
                  background: 'var(--color-white)',
                  color: 'var(--color-black)',
                  boxShadow: 'none',
                }}
                overflowCount={999}
                showZero
              />
            </Col>
            <Col>
              <Button
                type="text"
                style={{ color: 'var(--color-white)' }}
                onClick={onToggleDrawer}
              >
                <LeftOutlined /> {translation('open_review_history')}
              </Button>
            </Col>
          </Row>
          <Space direction="vertical" className={styles.btnContainer}>
            <Row align="middle" justify="space-between">
              <Col>
                <Space>
                  <Text>{translation('assign_first_queue')}</Text>
                  <Badge
                    count={firstReviewCount ? firstReviewCount : 0}
                    style={{
                      background: 'none',
                      boxShadow: 'none',
                      color: 'var(--color-black)',
                      fontWeight: 'bold',
                    }}
                    showZero
                  />
                </Space>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={styles.fifoBtn}
                  disabled={
                    !!approvalPackageState.packageInfo.assignment?.id ||
                    firstReviewCount === 0
                  }
                  icon={<RightOutlined />}
                  onClick={() => onStartReviews(IQueue.First)}
                >
                  <span>{translation('start_first_queue')}</span>
                </Button>
              </Col>
            </Row>
            <Row align="middle" justify="space-between">
              <Col>
                <Space>
                  <Text>{translation('assign_second_queue')}</Text>
                  <Badge
                    count={secondReviewCount ? secondReviewCount : 0}
                    style={{
                      background: 'none',
                      boxShadow: 'none',
                      color: 'var(--color-black)',
                      fontWeight: 'bold',
                    }}
                    showZero
                  />
                </Space>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={styles.fifoBtn}
                  disabled={
                    !!approvalPackageState.packageInfo.assignment?.id ||
                    secondReviewCount === 0
                  }
                  icon={<RightOutlined />}
                  onClick={() => onStartReviews(IQueue.Second)}
                >
                  <span>{translation('start_second_queue')}</span>
                </Button>
              </Col>
            </Row>
          </Space>
          {!!approvalPackageState.packageInfo.assignment?.id && (
            <div className={styles.footerContainer}>
              <Divider />
              <Row align="middle" justify="space-between">
                <Col>
                  <Text>{translation('your_assigned_package')}</Text>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className={styles.fifoBtn}
                    icon={<RightOutlined />}
                    onClick={onContinuePackage}
                  >
                    <span>{translation('continue_package')}</span>
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Spin>
        <UserApprovalPackagesDrawer
          open={approvalPackagesDrawerOpen}
          onClose={onToggleDrawer}
        />
      </Layout>
    </AppLayout>
  );
};

export default ApprovalPackages;
