import { FC, useMemo } from 'react';
import { Input, Form, Typography } from 'antd';
import { FieldItemProps } from '../types';

import PublishedItem from './PublishedItem';

const { Text } = Typography;
const { TextArea } = Input;

const FormItemLegalText: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const publishedItemValues = useMemo(
    () => ({
      legalText: fieldChanges?.legalText.publishedItem,
    }),
    [fieldChanges]
  );

  return (
    <Form.Item
      name="legalText"
      label={
        fieldChanges?.legalText.hasChanges ? (
          <Text mark>
            Legal text{' '}
            {
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemLegalText />
              </PublishedItem>
            }
          </Text>
        ) : (
          'Legal text'
        )
      }
      rules={[{ required: true, message: 'Insert the legal text' }]}
    >
      <TextArea disabled={!editMode} autoSize />
    </Form.Item>
  );
};

export default FormItemLegalText;
