import { useEffect, useRef } from 'react';

const useAutoFetchData = (
  callback: () => void,
  delay: number | null = 60000
) => {
  const doBeforeCallback = () => {
    if (callback && window.document.hasFocus()) {
      callback();
    }
  };

  const savedCallback = useRef(doBeforeCallback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = doBeforeCallback;
    // eslint-disable-next-line
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

export default useAutoFetchData;
