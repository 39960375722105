import { Action, AnyAction } from 'redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { ThunkAction } from 'redux-thunk';
import { RootState } from './index';
import { config } from '../config';
import { SET_ROLES_LIST, SET_TOKEN } from '../constants/actionTypes';
import { getHomePageUrl } from 'craftos-ui';
import { getEnv } from '../utils/environment';

export const setToken = (token?: string, user?: any): AnyAction => {
  return {
    type: SET_TOKEN,
    payload: { token, user },
  };
};

export const setRolesList = (rolesList?: any): AnyAction => {
  return {
    type: SET_ROLES_LIST,
    payload: { rolesList },
  };
};

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.authentication.clientId,
    authority: `https://enpalcraftos.b2clogin.com/enpalcraftos.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_CRAFT`,
    knownAuthorities: ['enpalcraftos.b2clogin.com'],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

export const thunkLogin = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  const { tokenBE, user } = await asyncLogin();
  const { rolesAndPrivileges } = await asyncFetchRolesAndPrivileges(tokenBE!);

  dispatch(setToken(tokenBE, user));
  dispatch(setRolesList(rolesAndPrivileges));
};

export const asyncFetchRolesAndPrivileges = async (tokenBE: string) => {
  let rolesAndPrivileges = [];
  try {
    let resp = await fetch(`${config.apiUrl}/Auth`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenBE}`,
      },
    });

    if (resp.ok) {
      rolesAndPrivileges = await resp.json();
    } else if (resp.status === 403) {
      window.location.href = getHomePageUrl(getEnv());
    }
  } catch (error) {}
  return { rolesAndPrivileges };
};

export const asyncLogin = async () => {
  let tokenResponse;
  try {
    tokenResponse = await publicClientApplication.handleRedirectPromise();
  } catch (e) {
    await publicClientApplication.loginRedirect({
      scopes: config.authentication.scopes,
    });
  }
  let tokenBE;

  let accountObj;
  if (tokenResponse) {
    accountObj = tokenResponse.account;
  } else {
    accountObj = publicClientApplication.getAllAccounts()[0];
  }

  if (!accountObj) {
    await publicClientApplication.loginRedirect({
      scopes: config.authentication.scopes,
    });
  } else {
    try {
      tokenBE = await publicClientApplication.acquireTokenSilent({
        account: publicClientApplication.getAllAccounts()[0],
        scopes: config.authentication.scopes,
      });
    } catch (e) {
      await publicClientApplication.loginRedirect({
        scopes: config.authentication.scopes,
      });
    }
  }

  return {
    tokenBE: tokenBE?.accessToken,
    user: publicClientApplication.getAllAccounts()[0],
    expires: tokenBE?.expiresOn,
  };
};

export const asyncLogout = async () => {
  try {
    await publicClientApplication.logoutRedirect();
  } catch (e) {}
};
