import { Empty, Space, Typography } from 'antd';
import styles from './documents.module.scss';
import { FileMTWIcon } from '../../../../components/icons/fileMTW';
const { Text, Link } = Typography;

interface DocumentsProps {
  filesInfo?: any[];
}

const WODocuments: React.FC<DocumentsProps> = ({ filesInfo = [] }) => {
  return (
    <>
      {filesInfo?.length > 0 ? (
        <Space
          style={{
            maxWidth: '100%',
            overflowX: 'auto',
            padding: '8px 8px 24px 0',
          }}
        >
          {filesInfo?.map((file: any, i: number) => (
            <Link href={file.url} target="_blank">
              <div
                key={`${file?.fileName}-${i}`}
                className={styles.fileWrapper}
              >
                <FileMTWIcon />
                <Text style={{ maxWidth: '120px' }} ellipsis>
                  {file?.fileName}
                </Text>
              </div>
            </Link>
          ))}
        </Space>
      ) : (
        <Empty className={styles.iconCentered} description={false} />
      )}
    </>
  );
};

export default WODocuments;
