import { FC, useMemo, useState } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import { WorkTypesGroup } from '../types';
import { getUniqueId } from '../../../utils/helpers';
import translation from '../../../i18n/translation';
const { TextArea } = Input;

interface Props {
  visible: boolean;
  handleChangesForGroup: (arg0: string, arg1: WorkTypesGroup) => void;
  selectedUpdateGroup: WorkTypesGroup | undefined;
  setSelectedUpdateGroup: (arg1: WorkTypesGroup | undefined) => void;
  hideModal: () => void;
}

const GroupDetailsModal: FC<Props> = ({
  visible,
  handleChangesForGroup,
  selectedUpdateGroup,
  setSelectedUpdateGroup,
  hideModal,
}) => {
  const [formObj] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    const values = await formObj.validateFields();
    if (!!selectedUpdateGroup) {
      selectedUpdateGroup?.id
        ? handleChangesForGroup('update-selected', {
            ...values,
            id: selectedUpdateGroup.id,
            workTypes: selectedUpdateGroup.workTypes,
          })
        : handleChangesForGroup('update-selected', {
            ...values,
            workTypes: selectedUpdateGroup.workTypes,
            temporaryId: selectedUpdateGroup.temporaryId,
          });
    } else {
      let temporaryId = getUniqueId();
      handleChangesForGroup('create-new', {
        ...values,
        temporaryId,
        workTypes: [],
      });
    }
    setSelectedUpdateGroup(undefined);
    setLoading(false);
    hideModal();
  };
  const initialFormState: { [key: string]: any } = useMemo(
    () => ({
      name: selectedUpdateGroup?.name,
      description: selectedUpdateGroup?.description,
      sequence: selectedUpdateGroup?.sequence,
    }),
    [selectedUpdateGroup]
  );

  return (
    <Modal
      destroyOnClose
      title={
        !!selectedUpdateGroup
          ? selectedUpdateGroup.name + ' Details'
          : 'New Group Details'
      }
      okText={!!selectedUpdateGroup ? 'Update' : 'Create'}
      open={visible}
      onOk={handleOk}
      onCancel={hideModal}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      zIndex={2020}
    >
      <Form
        form={formObj}
        layout="vertical"
        initialValues={initialFormState}
        style={{ zIndex: 2021 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: translation('provide_appointment_type_name'),
            },
          ]}
        >
          <Input placeholder="Insert a name" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} placeholder="Insert a description" />
        </Form.Item>
        <Form.Item name="sequence" label="Sequence number">
          <InputNumber min={0} max={10} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GroupDetailsModal;
