import { AnyAction } from 'redux';
import { StatusChangeLogActions, StatusChangeLogState } from './types';

const initialState: StatusChangeLogState = {
  loadingStatusChangeLog: false,
  statusChangeLog: [],
  error: null,
};

export const statusChangeLogReducer = (
  state: StatusChangeLogState = initialState,
  action: AnyAction
): StatusChangeLogState => {
  switch (action.type) {
    case StatusChangeLogActions.FETCH_STATUS_CHANGE_LOG_START:
      return {
        ...state,
        loadingStatusChangeLog: true,
      };
    case StatusChangeLogActions.FETCH_STATUS_CHANGE_LOG_SUCCESS:
      return {
        ...state,
        loadingStatusChangeLog: false,
        statusChangeLog:
          action.payload.response.externalAppointmentStatusLogs ?? [],
      };
    case StatusChangeLogActions.FETCH_STATUS_CHANGE_LOG_FAILED:
      return {
        ...state,
        loadingStatusChangeLog: false,
        error: action.payload,
      };
    case StatusChangeLogActions.RESET_STATE:
      return {
        loadingStatusChangeLog: false,
        statusChangeLog: [],
        error: null,
      };
    default:
      return state;
  }
};
