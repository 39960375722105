import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Typography, Popover, Spin, Col } from 'antd';
import CheckedInUsersActions from './actions';
import { IAppointment } from '../../../constants/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import styles from './checkedInUsers.module.scss';
import { CheckedInUsersState } from './types';
import translation from '../../../i18n/translation';
import { UserOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

interface Props extends PropsWithChildren {
  selectedField: IAppointment;
}

const CheckedInUsersPopover: FC<Props> = ({ selectedField, children }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const checkedInUsersState: CheckedInUsersState = useSelector(
    (state: RootState) => state.checkedInUsersData
  );

  const [isVisible, setIsVisible] = useState(false);

  const handleClickChange = (visible: boolean) => {
    setIsVisible(visible);
    if (!visible) {
      dispatch(CheckedInUsersActions.resetState());
    }
  };

  useEffect(() => {
    if (selectedField && isVisible) {
      const { id } = selectedField;
      if (id) {
        dispatch(CheckedInUsersActions.fetchCheckedInUsersAsync(id));
      }
    }
  }, [dispatch, selectedField, isVisible]);

  return (
    <Popover
      placement="right"
      title={translation('checked_in_users')}
      onOpenChange={handleClickChange}
      open={isVisible}
      trigger="click"
      content={
        checkedInUsersState?.loadingCheckedInUsers ? (
          <Spin size="small" className={styles.spin} />
        ) : (
          <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
            {checkedInUsersState.checkedInUsersList.map((log, i: number) => (
              <div className={styles.userLog} key={`${i}-chekin-user`}>
                <div className={styles.userIcon}>
                  <UserOutlined />
                </div>{' '}
                <div className={styles.userDetail}>
                  <Col>
                    {log?.user?.firstName} {log?.user?.lastName}
                  </Col>
                  <Col>
                    <Typography.Text type="secondary">
                      {intl.formatDate(log?.checkInTime, {
                        month: 'short',
                        day: 'numeric',
                      })}
                      ,{' '}
                      {intl.formatDate(log?.checkInTime, {
                        year: 'numeric',
                      })}{' '}
                      {intl.formatTime(log?.checkInTime, {
                        timeStyle: 'short',
                        hour12: false,
                      })}{' '}
                    </Typography.Text>
                  </Col>
                </div>
              </div>
            ))}
          </div>
        )
      }
    >
      {children}
    </Popover>
  );
};

export default CheckedInUsersPopover;
