export const DelayedSvg = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <rect x="0.5" width="16" height="16" rx="3" fill="#FFDAD4" />
    <path
      d="M8.5 4.00195V8.99414M8.5 11.9883V11.9983"
      stroke="#D72D2D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
