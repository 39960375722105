import React from 'react';

export const ChartMTWIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="chart-infographic">
      <path
        id="Vector"
        d="M4.66667 7.33333C6.13943 7.33333 7.33333 6.13943 7.33333 4.66667C7.33333 3.19391 6.13943 2 4.66667 2C3.19391 2 2 3.19391 2 4.66667C2 6.13943 3.19391 7.33333 4.66667 7.33333Z"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M4.66675 2V4.66667H7.33342"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M6 11.333V13.9997"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M11.3333 9.33301V13.9997"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M8.66675 8.66699V14.0003"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M14 8V14"
        stroke="#161618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
