import { FC } from 'react';

interface Props {
  style?: object;
  width?: string | number;
  height?: string | number;
}

export const LocationIcon: FC<Props> = ({
  style,
  width = '10',
  height = '14',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 14"
    fill="none"
    style={style}
  >
    <path
      d="M5.00004 0.333252C2.42004 0.333252 0.333374 2.41992 0.333374 4.99992C0.333374 6.15992 0.666707 7.24659 1.27337 8.22659C1.90671 9.25325 2.74004 10.1333 3.38004 11.1599C3.69337 11.6599 3.92004 12.1266 4.16004 12.6666C4.33337 13.0333 4.47337 13.6666 5.00004 13.6666C5.52671 13.6666 5.66671 13.0333 5.83337 12.6666C6.08004 12.1266 6.30004 11.6599 6.61337 11.1599C7.25337 10.1399 8.08671 9.25992 8.72004 8.22659C9.33337 7.24659 9.66671 6.15992 9.66671 4.99992C9.66671 2.41992 7.58004 0.333252 5.00004 0.333252ZM5.00004 6.83325C4.08004 6.83325 3.33337 6.08659 3.33337 5.16659C3.33337 4.24659 4.08004 3.49992 5.00004 3.49992C5.92004 3.49992 6.66671 4.24659 6.66671 5.16659C6.66671 6.08659 5.92004 6.83325 5.00004 6.83325Z"
      fill="#1890FF"
    />
  </svg>
);
