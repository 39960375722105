import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Button, Space, Spin, Form, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { EditOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { normalizeFormValues } from '../utils';
import { FormChild, EditMode, FormType, FlatList } from '../types';
import FormGenerator from './FormGenerator';
import styles from '../formBuilderDetail.module.scss';
import translation from '../../../i18n/translation';

interface Props {
  selectedNode: FormChild | undefined;
  editMode: EditMode;
  saveLoading: boolean;
  openDeleteFieldModal: () => void;
  handleEditMode: (key: keyof EditMode, mode: boolean) => void;
  handleSave: (form: FormType, updatedFlatList: FlatList) => void;
}

const FormDetail: FC<Props> = ({
  selectedNode,
  editMode,
  saveLoading,
  openDeleteFieldModal,
  handleEditMode,
  handleSave,
}) => {
  const loading = useSelector(
    (state: RootState) =>
      state.formBuilderDetail.loadingForm ||
      state.formBuilderDetail.loadingPublishedForm
  );
  const flatList = useSelector(
    (state: RootState) => state.formBuilderDetail.flatList
  );
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  const [formIstance] = Form.useForm();

  const handleSaveField = async () => {
    if (selectedNode && flatList && form) {
      try {
        const values = await formIstance.validateFields();
        const normalizedValues = normalizeFormValues(values);
        const newFlatList: FlatList = flatList.set(selectedNode.id, {
          ...selectedNode,
          ...normalizedValues,
        });
        handleSave(form, newFlatList);
      } catch (error: any) {
        if (!!error?.errorFields && error?.errorFields?.length > 0) {
          for (let err of error.errorFields) {
            message.error(err.errors[0]);
          }
        } else {
          message.error(error?.message);
        }
      }
    }
  };

  return (
    <div className={styles.contentWrapper}>
      {loading ? (
        <Spin size="large" className={styles.spin} />
      ) : (
        selectedNode && (
          <>
            <PageHeader
              title={translation('selected_field')}
              className={styles.contentHeader}
              extra={
                !editMode.formDetail ? (
                  [
                    <Button
                      key="2"
                      type="primary"
                      disabled={editMode.treeMenu || saveLoading}
                      onClick={() => handleEditMode('formDetail', true)}
                      icon={<EditOutlined />}
                    >
                      {' '}
                      {translation('edit')}
                    </Button>,
                    <Button
                      key="1"
                      type="primary"
                      danger
                      disabled={editMode.formDetail || editMode.treeMenu}
                      onClick={openDeleteFieldModal}
                      icon={<DeleteOutlined />}
                    >
                      {' '}
                      {translation('delete')}
                    </Button>,
                  ]
                ) : (
                  <Space>
                    <Button
                      type="primary"
                      loading={saveLoading}
                      onClick={handleSaveField}
                      icon={<SaveOutlined />}
                    >
                      {' '}
                      {translation('save')}
                    </Button>
                    <Button onClick={() => handleEditMode('formDetail', false)}>
                      {' '}
                      {translation('cancel_editing')}
                    </Button>
                  </Space>
                )
              }
            />

            <div className={styles.contentContainer}>
              <FormGenerator
                selectedNode={selectedNode}
                editMode={editMode.formDetail}
                formInstance={formIstance}
              />
            </div>
          </>
        )
      )}
    </div>
  );
};

export default FormDetail;
