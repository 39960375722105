import { FC } from 'react';
import { useIntl } from 'react-intl';
import styles from '../detailDrawer.module.scss';

interface Props {
  date: string | undefined;
}

const DateAvatar: FC<Props> = ({ date }) => {
  const intl = useIntl();

  return (
    <div className={styles.dateAvatarContainer}>
      <div className={`${styles.center} ${styles.dateAvatarMonth}`}>
        {intl.formatDate(date, { month: 'short' }).toUpperCase()}
      </div>
      <div className={`${styles.center} ${styles.dateAvatarDay}`}>
        {intl.formatDate(date, { day: '2-digit' })}
      </div>
    </div>
  );
};

export default DateAvatar;
