import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import {
  Descriptions,
  Tag,
  Tooltip,
  Button,
  Popover,
  message,
  Modal,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { FormattedDate, useIntl } from 'react-intl';
import { fetchFormAsync, resetState } from '../actions';
import { getStatusColor } from '../utils';
import { EditMode, FormDetailParams } from '../types';
import ApiRepository from '../../../services/api/apiRepository';
import styles from '../formBuilderDetail.module.scss';
import * as routes from '../../../router/routes';
import translation from '../../../i18n/translation';
import Iframe from 'react-iframe';
import { getApiUrl, getEnv } from '../../../utils/environment';
import AccessControl from '../../../components/accessControl';
import { IFeatures } from '../../../store/rolesAndPrivileges';

interface Props {
  editMode: EditMode;
  openEditFormModal: () => void;
}
const { confirm } = Modal;

const FormHeader: FC<Props> = ({ editMode, openEditFormModal }) => {
  const navigate = useNavigate();
  const { fieldId }: FormDetailParams = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  // const loading = useSelector((state: RootState) => state.formBuilderDetail.loading);
  const form = useSelector((state: RootState) => state.formBuilderDetail.form);
  const [showPreview, setShowPreview] = useState(false);

  const handlePublishConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: 'publish_confirm' }),
      icon: <ExclamationCircleFilled />,
      okText: intl.formatMessage({ id: 'publish' }),
      onOk: handlePublish,
    });
  };

  /* eslint-disable */
  const handlePublish = async () => {
    if (form) {
      try {
        await ApiRepository.publishForm(form.id);
        dispatch(resetState());
        dispatch(fetchFormAsync(form.id));
        setShowPreview(false);
        navigate({
          pathname: routes.formBuilderDetail.pathWithParams(
            '0',
            form.id,
            fieldId
          ),
        });
      } catch (error: any) {
        if (error?.message) {
          message.error(error?.message);
        }
      }
    }
  };

  const handlePreview = () => {
    if (form && form.id) {
      window.open(
        `https://app${getApiUrl()}.craftos.enpal.tech/preview/${form?.id}`,
        '_blank'
      );
    }
  };

  const popoverContent = (
    <div>
      <p>
        <strong>{translation('revision')}:</strong> {form?.revision}
      </p>
      <p>
        <strong>{translation('created')}:</strong>{' '}
        {<FormattedDate value={form?.createdAt} />}
      </p>
      <p>
        <strong>{translation('updated')}:</strong>{' '}
        {<FormattedDate value={form?.updatedAt} />}
      </p>

      {form?.publishedAt && (
        <p>
          <strong>{translation('published')}:</strong>{' '}
          {<FormattedDate value={form?.publishedAt} />}
        </p>
      )}
    </div>
  );

  const priorityTag =
    form?.priority === 'Priority'
      ? [
          <Tag key="1" color="success">
            {form?.priority}
          </Tag>,
        ]
      : [];

  return (
    <>
      <PageHeader
        className={styles.formHeader}
        title={form?.name}
        subTitle={form?.formHandle}
        tags={[
          <Tag key="2" color={getStatusColor(form?.status)}>
            {form?.status}
          </Tag>,
          ...priorityTag,
        ]}
        onBack={() => navigate(`/${routes.ROOT_PAGES.formBuilder}`)}
        extra={[
          <Popover key="3" content={popoverContent} placement="bottomLeft">
            <InfoCircleOutlined style={{ fontSize: '1.1em' }} />
          </Popover>,
          <Button
            key="btnFromBuilderEdit"
            type="primary"
            disabled={editMode.formDetail || editMode.treeMenu}
            onClick={openEditFormModal}
          >
            {' '}
            {translation('edit')}
          </Button>,
          <AccessControl featureKey={IFeatures.formBuilderAccess}>
            <Button
              key="btnFormBuilderPublish"
              disabled={
                editMode.formDetail ||
                editMode.treeMenu ||
                form?.status !== 'Draft'
              }
              onClick={handlePublishConfirm}
            >
              {' '}
              {translation('publish')}
            </Button>
          </AccessControl>,
          <AccessControl featureKey={IFeatures.formBuilderAccess}>
            <Button
              key="btnFromBuilderPreview"
              disabled={editMode.formDetail || editMode.treeMenu}
              onClick={handlePreview}
            >
              {' '}
              {translation('preview_comments')}
            </Button>
          </AccessControl>,
        ]}
      >
        <Descriptions size="small" column={3} labelStyle={{ fontWeight: 700 }}>
          <Descriptions.Item span={3} label="Work type">
            <Tooltip title={form?.workType?.description}>
              {form?.workType?.name}
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Description">
            {form?.description}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <Modal
        onOk={handlePublishConfirm}
        open={showPreview}
        style={{ top: 20 }}
        width={window.innerWidth / 4}
        bodyStyle={{ padding: 0 }}
        closable={false}
        onCancel={() => {
          setShowPreview(false);
        }}
        okText={translation('publish')}
        okButtonProps={{ disabled: form?.status !== 'Draft' }}
        destroyOnClose
        okType="primary"
        maskClosable={false}
        cancelText={translation('cancel')}
      >
        <Iframe
          url={`https://app${
            getEnv() !== 'production' ? '-test' : ''
          }.craftos.enpal.tech/preview/${form?.id}`}
          width={`${window.innerWidth / 4}px`}
          height={`${window.innerHeight - 100}px`}
          id="craft-app-preview"
          display="block"
          frameBorder={0}
          position="relative"
        />
      </Modal>
    </>
  );
};

export default FormHeader;
