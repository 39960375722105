import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Table, TableColumnsType, Layout, Button, Popover, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import translation from '../../../i18n/translation';
import * as routes from '../../../router/routes';
import AppointmentTypesActions from '../actions';
import AppLayout from '../../../components/appLayout';
import { Sorter, AppointmentTypeParams } from '../types';
import styles from './appointmentTypesTable.module.scss';

const columns: TableColumnsType<AppointmentTypeParams> = [
  {
    title: translation('name'),
    dataIndex: 'name',
    key: 'name',
    width: '180px',
  },
  {
    title: translation('description'),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'External Type',
    dataIndex: 'externalType',
    key: 'externalType',
  },
  {
    title: translation('status'),
    dataIndex: 'status',
    key: 'status',
    width: '140px',
  },
  {
    //title: translation('workTypes'),
    title: 'Work Types',
    dataIndex: 'wt',
    key: 'wt',
    width: '130px',
    render: (_, record) => {
      const content = record.workTypes.map((item, i) => {
        return <p key={item.id}>{item.name}</p>;
      });
      if (record.workTypes.length > 0) {
        return (
          <>
            <Popover
              placement="left"
              title="Work Types"
              content={content}
              trigger="hover"
            >
              <Button type="text"> {record.workTypes.length}</Button>
            </Popover>
          </>
        );
      }
    },
  },
  {
    //title: translation('groups'),
    title: 'Groups',
    dataIndex: 'groups',
    key: 'groups',
    width: '130px',
    render: (_, record) => {
      const content = record?.groups?.map((item: any) => {
        return <p key={item.id}>{item.name}</p>;
      });
      if (record?.groups?.length) {
        return (
          <>
            <Popover
              placement="left"
              title="Groups"
              content={content}
              trigger="hover"
            >
              <Button type="text"> {record.groups.length}</Button>
            </Popover>
          </>
        );
      }
    },
  },
  {
    title: 'Files',
    dataIndex: 'files',
    key: 'files',
    width: '130px',
    render: (_, record) => {
      const content = !!record.files
        ? record.files.map((item, i) => {
            return <p key={i}>{item.fileName}</p>;
          })
        : '';
      if (!!record.files && record.files.length) {
        return (
          <>
            <Popover
              placement="left"
              title="Files"
              content={content}
              trigger="hover"
            >
              <Button type="text">{record.files.length}</Button>
            </Popover>
          </>
        );
      }
    },
  },
];

const getQueryStringValue = (
  locationSearch: string,
  target: string,
  defaultValue: string | number
) => {
  let value = defaultValue;
  locationSearch
    .slice(1)
    .split('&')
    .forEach((str) => {
      const searchQuery = str.split('=');
      if (searchQuery[0] === target) value = searchQuery[1];
    });
  return value;
};

const getSorter = (sorter: Sorter) => {
  if (!sorter?.column) return '';
  const sortOrder = sorter.order === 'ascend' ? '-' : '';
  return `${sortOrder}${sorter.columnKey}`;
};

const AppointmentTypesTable: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const appointmentTypesState = useSelector(
    (state: RootState) => state.appointmentTypes
  );

  const [sortedBy, setSortedBy] = useState<string>(
    getQueryStringValue(location.search, 'sortedBy', '') as string
  );

  const handleTableChange = (_: any, sorter: any) => {
    setSortedBy(getSorter(sorter));
  };

  useEffect(() => {
    dispatch(AppointmentTypesActions.fetchAppointmentTypesAndGroupsAsync());
  }, [dispatch, sortedBy]);

  useEffect(() => {
    navigate({
      pathname: location.pathname,
    });
  }, [navigate, location.pathname]);

  return (
    <AppLayout>
      <Layout className={styles.contentLayout}>
        <PageHeader
          title="Appointment Types"
          className={styles.header}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  navigate(
                    routes.appointmentTypeDetail.pathWithParams('create-new')
                  )
                }
              >
                Add New Appointment Type
              </Button>
            </Space>
          }
        />

        <Layout.Content className={styles.wrapper}>
          <div className={styles.tableContent}>
            <Table
              size="small"
              showHeader
              sticky={{ offsetHeader: -16 }}
              columns={columns}
              dataSource={appointmentTypesState.appointmentTypes}
              rowKey={(record) => record.id}
              pagination={false}
              loading={appointmentTypesState.loading}
              onChange={handleTableChange}
              onRow={(appointmentType) => {
                return {
                  onClick: () => {
                    const id = appointmentType.id;
                    navigate(routes.appointmentTypeDetail.pathWithParams(id));
                  },
                };
              }}
            />
          </div>
        </Layout.Content>
      </Layout>
    </AppLayout>
  );
};

export default AppointmentTypesTable;
