import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Tag, Typography, Space, Divider, Tooltip } from 'antd';
import { AlertIcon, StopWatchIconSmall } from '../../../../components/icons';
import translation from '../../../../i18n/translation';
import styles from '../detailDrawer.module.scss';
import { config } from '../../../../config';
import DateAvatar from './dateAvatar';
import AppointmentStatusTag from '../../../../components/appointmentStatusTag';
import {
  AppointmentType,
  IAppointment,
  IAppointmentStatus,
  IAppointmentStatusType,
} from '../../../../constants/types';
import UserAvatarCircle from '../../../../components/icons/userAvatarCircleSvg';
import PeopleGroupSvg from '../../../../components/icons/peopleGroupSvg';
import ParallelAppointmentSvg from '../../../../components/icons/parallelAppointmentSvg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { LastAppointmentSvg } from '../../../../components/icons/lastAppointmentSvg';

const { Text, Link } = Typography;

interface Props {
  appointment: AppointmentType | null;
  selectedAppointment?: IAppointment | null;
  withDivider?: boolean;
  appointmentStatusList?: string[];
  isParallel?: boolean;
  isLast?: boolean;
  isPlannerLinkVisible?: boolean;
  refreshData: () => void;
}

const Appointment: FC<Props> = ({
  appointment,
  selectedAppointment,
  withDivider,
  isParallel = false,
  isLast = false,
  appointmentStatusList,
  isPlannerLinkVisible = false,
  refreshData,
}) => {
  const intl = useIntl();
  const teamLeadsState = useSelector(
    (state: RootState) => state.progressTeams.teamLeads
  );

  return (
    <Row wrap={false}>
      <Col flex="none">
        <DateAvatar date={appointment?.appointmentDate} />
      </Col>
      <Col flex="auto">
        <Row>
          <Col flex="auto">
            <div className={styles.appointmentContentLine}>
              <Space size="middle" align="center">
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  {(isParallel || isLast) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      {isLast && (
                        <Tooltip
                          placement="topLeft"
                          title={intl.formatMessage({
                            id: 'last_appointment_tooltip',
                          })}
                        >
                          <div className={styles.center}>
                            <LastAppointmentSvg />
                          </div>
                        </Tooltip>
                      )}
                      {isParallel && (
                        <Tooltip
                          placement="topLeft"
                          title={intl.formatMessage({
                            id: 'parallel_appointment',
                          })}
                        >
                          <div className={styles.center}>
                            <ParallelAppointmentSvg />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  <Link
                    strong
                    href={`${config.sfIntegrationUrl}/r/ServiceAppointment/${appointment?.externalId}/view`}
                    target="_blank"
                  >
                    {appointment?.appointmentNumber}
                  </Link>
                  {selectedAppointment?.atRisk && (
                    <div className={styles.center}>
                      <AlertIcon />
                    </div>
                  )}
                  <Tag>{appointment?.serviceAppointmentType}</Tag>
                </div>
                {isPlannerLinkVisible && (
                  <Link
                    id="ga4-detail-drawer-planner-link"
                    href={`${config.plannerUrl}/request/${selectedAppointment?.externalCaseId}`}
                    target="_blank"
                  >
                    <Tag color="#FFD880" className={styles.plannerTag}>
                      {translation('change_team')}
                    </Tag>
                  </Link>
                )}
              </Space>
            </div>
            <div className={styles.appointmentContentLine}>
              <Space size="middle" align="center">
                <Text style={{ fontWeight: 500, color: '#000000D9' }}>
                  Start:{' '}
                  <Text
                    {...(appointment?.appointmentDate &&
                    appointment?.appointmentScheduledDate &&
                    appointment?.appointmentDate !==
                      appointment?.appointmentScheduledDate
                      ? { type: 'danger', italic: true }
                      : { type: 'secondary' })}
                    style={{ fontWeight: 400 }}
                  >
                    {intl
                      .formatDate(appointment?.appointmentDate, {
                        weekday: 'short',
                      })
                      .toUpperCase()}{' '}
                    <StopWatchIconSmall style={{ marginLeft: '4px' }} />{' '}
                    {intl.formatTime(appointment?.appointmentDate, {
                      timeStyle: 'short',
                      hour12: false,
                    })}
                  </Text>
                </Text>
                <Text style={{ fontWeight: 500, color: '#000000D9' }}>
                  {translation('end')}:{' '}
                  <Text
                    {...(appointment?.appointmentEndDate &&
                    appointment?.appointmentScheduledEndDate &&
                    appointment?.appointmentEndDate !==
                      appointment?.appointmentScheduledEndDate
                      ? { type: 'danger', italic: true }
                      : { type: 'secondary' })}
                    style={{ fontWeight: 400 }}
                  >
                    {intl
                      .formatDate(appointment?.appointmentEndDate, {
                        weekday: 'short',
                      })
                      .toUpperCase()}{' '}
                    <StopWatchIconSmall style={{ marginLeft: '4px' }} />{' '}
                    {intl.formatTime(appointment?.appointmentEndDate, {
                      timeStyle: 'short',
                      hour12: false,
                    })}
                  </Text>
                </Text>
              </Space>
            </div>

            <div className={styles.appointmentContentLine}>
              <div className={styles.center} style={{ maxHeight: '22px' }}>
                <PeopleGroupSvg />
              </div>
              <Text
                type="secondary"
                style={{
                  margin: '0 14px 0 8px',
                  maxWidth: '170px',
                  textAlign: 'left',
                  color: '#5F5F68',
                }}
              >
                {appointment?.team?.name}
              </Text>

              {appointment?.team?.assignedPlId && (
                <div className={styles.center} style={{ maxHeight: '22px' }}>
                  <UserAvatarCircle />
                </div>
              )}
              <Text
                type="secondary"
                style={{
                  margin: '0 0 0 8px',
                  maxWidth: '148px',
                  textAlign: 'left',
                  color: '#5F5F68',
                }}
              >
                {appointment?.team?.assignedPlId &&
                  teamLeadsState[appointment?.team?.assignedPlId]}
              </Text>
            </div>
          </Col>
          <Col
            flex="164px"
            className={styles.center}
            style={{
              paddingLeft: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <AppointmentStatusTag
              type={IAppointmentStatusType.serviceAppointment}
              appointment={
                selectedAppointment
                  ? { ...selectedAppointment!, ...appointment! }
                  : appointment
              }
              status={appointment?.externalStatus ?? IAppointmentStatus.none}
              statusList={appointmentStatusList}
              onSuccess={() => refreshData()}
            />
          </Col>
        </Row>

        {withDivider && <Divider className={styles.divider} />}
      </Col>
    </Row>
  );
};

export default Appointment;
