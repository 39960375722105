import notification from 'antd/lib/notification';
import { INotificationProps, NotificationType } from '../constants/types';

export const showNotificationMessage = (args: INotificationProps) => {
  args = { placement: 'bottomRight', duration: 5, ...args };

  switch (args.error?.type || args.notificationType) {
    case NotificationType.warn:
      showWarnNotification(args);
      break;
    case NotificationType.info:
      showInfoNotification(args);
      break;
    case NotificationType.success:
      showSuccessNotification(args);
      break;
    case NotificationType.default:
      showDefaultNotification(args);
      break;
    default:
      showErrorNotification(args);
  }
};

const showErrorNotification = (args: INotificationProps) => {
  notification.error({
    ...args,
    description: `${args.error.code}: ${args.error.message}`,
    onClose: args.onClose,
  });
};

const showInfoNotification = (args: INotificationProps) => {
  notification.destroy();
  notification.info({
    ...args,
  });
};

const showSuccessNotification = (args: INotificationProps) => {
  notification.destroy();
  notification.success({
    ...args,
  });
};

const showWarnNotification = (args: INotificationProps) => {
  notification.destroy();
  notification.warning({
    ...args,
  });
};

const showDefaultNotification = (args: INotificationProps) => {
  notification.destroy();
  notification.open({
    ...args,
  });
};
