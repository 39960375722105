import React, { PropsWithChildren } from 'react';
import { AppLayout } from 'craftos-ui';
import { RootState, store } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import actionSelect from '../changeLanguage/actions';
import { asyncLogout } from '../../store/authenticationActions';
import { getEnv } from '../../utils/environment';
import { isValidExtCaseId, isValidObjectId } from '../../utils/helpers';
import * as routes from '../../router/routes';
import { Input } from 'antd';
import { useIntl } from 'react-intl';
const { Search } = Input;

interface Props extends PropsWithChildren {
  hideApps?: boolean;
}

const AppLayoutWrapper: React.FC<Props> = ({ children, hideApps = false }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentLanguage = useSelector(
    (state: RootState) => state.changeLanguage.currentLanguage
  );

  const loginUser = store.getState().authentication.loginUser;

  const handleChange = (value: string) => {
    dispatch(actionSelect.setLanguage(value));
    localStorage.setItem('user-language', value);
  };

  const handleSetSearch = (val: string) => {
    let customer =
      !isValidExtCaseId(val) && !isValidObjectId(val) ? val : undefined;
    let externalCaseId = isValidExtCaseId(val) ? val : undefined;

    if (customer || externalCaseId) {
      const url = `${window.location.origin}${routes.workOrders.path}${
        customer
          ? `?pageSize=10&customer=${customer}`
          : `?pageSize=10&externalCaseId=${externalCaseId}`
      }`;
      window.open(url, '_blank', 'noreferrer noopener');
    }
  };

  const handleLogout = () => asyncLogout();
  return (
    <AppLayout
      appName="CraftOS"
      onLanguageChange={handleChange}
      currentLanguage={currentLanguage}
      user={loginUser}
      onLogout={handleLogout}
      appEnviroment={getEnv()}
      hideApps={hideApps}
      headerPlaceholder={
        <Search
          style={{ width: '230px' }}
          onSearch={(val) => handleSetSearch(val)}
          placeholder={intl.formatMessage({
            id: 'customer_search',
          })}
          defaultValue=""
          allowClear
        />
      }
    >
      {children}
    </AppLayout>
  );
};

export default AppLayoutWrapper;
