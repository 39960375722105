import { FC } from 'react';
import { Modal, Typography } from 'antd';
import { FormChild } from '../types';
import { useIntl } from 'react-intl';

const { Text, Title } = Typography;

interface Props {
  selectedNode: FormChild | undefined;
  visible: boolean;
  saveLoading: boolean;
  hideModal: () => void;
  handleDeleteField: () => void;
}

const DeleteFieldModal: FC<Props> = ({
  selectedNode,
  visible,
  saveLoading,
  hideModal,
  handleDeleteField,
}) => {
  const intl = useIntl();
  return (
    <Modal
      centered
      destroyOnClose
      title={intl.formatMessage({ id: 'delete_field' })}
      open={visible}
      onOk={handleDeleteField}
      okText={intl.formatMessage({ id: 'yes' })}
      onCancel={hideModal}
      cancelText={intl.formatMessage({ id: 'no' })}
      okButtonProps={{
        loading: saveLoading,
      }}
      cancelButtonProps={{
        disabled: saveLoading,
      }}
    >
      <Title level={5}>
        {intl.formatMessage({ id: 'confirm_delete_field' })}{' '}
        <Text style={{ color: '#51A9FF' }}>{selectedNode?.label}</Text> ?
      </Title>
      {selectedNode?.type === 'Section' && (
        <Text>{intl.formatMessage({ id: 'delete_field_warning' })}</Text>
      )}
    </Modal>
  );
};

export default DeleteFieldModal;
