import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  Form,
  Divider,
  Select,
  Row,
  Col,
  Empty,
  Button,
  Input,
  Typography,
  Tooltip,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldItemProps, Metadata } from '../types';
import { parseMetadataDependencies, areItemsDifferent } from '../utils';
import styles from '../formBuilderDetail.module.scss';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemMetadata: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const metadata = Form.useWatch('metadata');
  const metadataOptions = useSelector(
    (state: RootState) => state.formBuilderDetail.metadata
  );
  const publishedMetadata = useMemo(
    () => fieldChanges?.metadata.publishedItem as Metadata,
    [fieldChanges?.metadata.publishedItem]
  );
  const publishedItemValues = useMemo(
    () => ({
      metadata: {
        dependOn: parseMetadataDependencies(publishedMetadata?.dependOn || {}),
        defaultValueKey: publishedMetadata?.defaultValueKey,
        writeTo: publishedMetadata?.writeTo,
      },
    }),
    [publishedMetadata]
  );

  return (
    <>
      <Divider orientation="left">
        {fieldChanges?.metadata.hasChanges ? (
          <Text mark>
            <Tooltip title={translation('metadata_tooltip')}>
              <span> Metadata </span>{' '}
            </Tooltip>
            <PublishedItem initialValues={publishedItemValues}>
              <FormItemMetadata />
            </PublishedItem>
          </Text>
        ) : (
          <Tooltip title={translation('metadata_tooltip')}>
            <span> Metadata </span>{' '}
          </Tooltip>
        )}
      </Divider>

      <Form.Item
        label={
          <Text
            mark={
              !!fieldChanges &&
              areItemsDifferent(
                metadata?.dependOn,
                parseMetadataDependencies(publishedMetadata?.dependOn || {})
              )
            }
          >
            <Tooltip title={translation('depend_on_tooltip')}>
              <span>{translation('depends_on')} </span>{' '}
            </Tooltip>
          </Text>
        }
      >
        <Form.List name={['metadata', 'dependOn']}>
          {(fields, { add, remove }) => {
            return (
              <>
                <div className={styles.nestContent}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row key={key} justify="space-between" align="top">
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'key']}
                          label="Label"
                          rules={[{ required: true, message: 'Select a key' }]}
                        >
                          <Select
                            showSearch
                            disabled={!editMode}
                            fieldNames={{ label: 'key', value: 'key' }}
                            options={metadataOptions as any}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, 'value']}
                          label="Value"
                          rules={[
                            { required: true, message: 'Select a value' },
                          ]}
                        >
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                      {editMode && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ marginTop: '16px' }}
                        />
                      )}
                    </Row>
                  ))}
                  {!editMode && fields.length === 0 && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No metadata dependencies"
                    />
                  )}
                  {editMode && (
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add metadata dependencies
                    </Button>
                  )}
                </div>
              </>
            );
          }}
        </Form.List>
      </Form.Item>
      <Form.Item
        name={['metadata', 'defaultValueKey']}
        label={
          <Text
            mark={
              !!fieldChanges &&
              areItemsDifferent(
                metadata?.defaultValueKey,
                publishedMetadata?.defaultValueKey
              )
            }
          >
            <Tooltip title={translation('default_value_key_tooltip')}>
              <span> Default value key </span>{' '}
            </Tooltip>
          </Text>
        }
      >
        <Select
          allowClear
          showSearch
          disabled={!editMode}
          fieldNames={{ label: 'key', value: 'key' }}
          options={metadataOptions as any}
        />
      </Form.Item>
      <Form.Item
        name={['metadata', 'writeTo']}
        label={
          <Text
            mark={
              !!fieldChanges &&
              areItemsDifferent(metadata?.writeTo, publishedMetadata?.writeTo)
            }
          >
            <Tooltip title={translation('write_to_tooltip')}>
              <span>{translation('write_to')} </span>{' '}
            </Tooltip>
          </Text>
        }
      >
        <Select
          mode="tags"
          disabled={!editMode}
          fieldNames={{ label: 'key', value: 'key' }}
          options={metadataOptions as any}
        />
      </Form.Item>
    </>
  );
};

export default FormItemMetadata;
