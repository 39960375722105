export enum DetailDrawerActions {
  FETCH_APPOINTMENT_DETAIL_START = 'FETCH_APPOINTMENT_DETAIL_START',
  FETCH_WORK_ORDER_DETAIL_SUCCESS = 'FETCH_WORK_ORDER_DETAIL_SUCCESS',
  FETCH_WORK_ORDER_DETAIL_FAILED = 'FETCH_WORK_ORDER_DETAIL_FAILED',
  RESET_STATE = 'RESET_STATE',
}

export interface Activity {
  startedAt: string;
  stoppedAt: string;
  completedAt: string;
}

export interface AnswerCounts {
  total: number;
  invisible: number;
  open: number;
  openRequired: number;
  openApprovable: number;
  submitted: number;
  resubmitted: number;
  approvalPending: number;
  approved: number;
  rejected: number;
  autoApproved: number;
  inReview: number;
  questions: number;
  groups: Group[];
}

export interface Group {
  groupId: string;
  name: string;
  status: string;
  updatedTime: string;
}

export interface Type {
  id: string;
  name: string;
  description: string;
  workTypes?: Type[];
}

export interface AtedBy {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

export interface Customer {
  firstName: string;
  lastName: string;
  name: string;
}

export interface CustomerAddress {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  telephone: string;
}

export interface Document {
  url: string;
  generatedAt: string;
}

export interface File {
  fileName: string;
  url: string;
  originalFileName: string;
}

export interface Note {
  text: string;
  updatedAt: string;
  updatedBy: AtedBy;
}

export interface Team {
  id: string;
  name: string;
  location: string;
  accountId: string;
  assignedPlId: string;
  assignedPlName: string;
}

export interface SelectedAppointment {
  externalId: string;
  externalCaseId: string;
}
