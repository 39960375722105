import { FC, useRef, useState, useEffect, useMemo } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Empty,
  InputRef,
  Typography,
  Radio,
  Tooltip,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { FieldItemProps } from '../types';
import styles from '../formBuilderDetail.module.scss';

import PublishedItem from './PublishedItem';
import translation from '../../../i18n/translation';

const { Text } = Typography;

const FormItemAnswers: FC<FieldItemProps> = ({ editMode, fieldChanges }) => {
  const formInstance = Form.useFormInstance();
  const answers = Form.useWatch('answers');
  const defaultValue = Form.useWatch('defaultValue');
  const displayAs = Form.useWatch('displayAs');
  const newField = useRef<InputRef | null>(null);
  const [newFields, setNewFields] = useState(0);
  const isButton = displayAs === 'Buttons';

  const publishedItemValues = useMemo(
    () => ({
      answers: fieldChanges?.answers.publishedItem,
      defaultValue: fieldChanges?.defaultValue.publishedItem,
    }),
    [fieldChanges]
  );

  const handleNewField = (
    add: (defaultValue?: any, insertIndex?: number | undefined) => void
  ) => {
    add();
    setNewFields((prev) => prev + 1);
  };

  const deleteField = (
    remove: (index: number | number[]) => void,
    name: number
  ) => {
    remove(name);
    if (answers?.[name]?.id === defaultValue)
      formInstance.setFieldsValue({ defaultValue: undefined });
  };

  const handleSelectDefault = (answerId: string) => {
    if (answerId === defaultValue) {
      formInstance.setFieldsValue({ defaultValue: undefined });
    } else {
      formInstance.setFieldsValue({ defaultValue: answerId });
    }
  };

  useEffect(() => {
    newField?.current?.focus();
  }, [newFields]);

  return (
    <>
      <Form.Item noStyle name="defaultValue">
        {/* Added hidden input to solve console error */}
        <Input style={{ display: 'none' }} />
      </Form.Item>

      <Form.Item
        label={
          fieldChanges?.answers.hasChanges ||
          fieldChanges?.defaultValue.hasChanges ? (
            <Text mark>
              <Tooltip title={translation('answers_tooltip')}>
                <span>{translation('answers')} </span>{' '}
              </Tooltip>
              <PublishedItem initialValues={publishedItemValues}>
                <FormItemAnswers />
              </PublishedItem>
            </Text>
          ) : (
            <Tooltip title={translation('answers_tooltip')}>
              <span>{translation('answers')} </span>{' '}
            </Tooltip>
          )
        }
        rules={[{ required: true, validator: async () => {} }]}
      >
        <Form.List
          name="answers"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('Add an answer'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <div className={styles.nestContent}>
                {fields.map(({ key, name, ...restField }, i) => (
                  <Row key={key} justify="space-between" align="top">
                    <Col span={isButton ? 22 : 16}>
                      <Form.Item
                        {...restField}
                        name={[name, 'label']}
                        label="Label"
                        rules={[
                          {
                            required: true,
                            message: 'Insert a label',
                          },
                        ]}
                      >
                        <Input
                          disabled={!editMode}
                          placeholder="Label"
                          ref={i === fields.length - 1 ? newField : undefined}
                        />
                      </Form.Item>
                    </Col>
                    {!isButton && (
                      <Col span={5}>
                        <Form.Item
                          label={
                            <Tooltip title={translation('default_tooltip')}>
                              <span>Default</span>
                            </Tooltip>
                          }
                        >
                          <Radio
                            checked={
                              defaultValue &&
                              defaultValue === answers?.[name]?.id
                            }
                            disabled={!editMode || !answers?.[name]?.id}
                            onClick={() =>
                              handleSelectDefault(answers?.[name]?.id)
                            }
                          />
                          {!answers?.[name]?.id && (
                            <Tooltip
                              title={translation('answer_field_warning')}
                            >
                              <QuestionOutlined />
                            </Tooltip>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {editMode && (
                      <MinusCircleOutlined
                        onClick={() => deleteField(remove, name)}
                        style={{ marginTop: '16px' }}
                      />
                    )}
                  </Row>
                ))}
                {!editMode && fields.length === 0 && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No answers"
                  />
                )}
                {editMode && (
                  <Button
                    type="dashed"
                    onClick={() => handleNewField(add)}
                    block
                    icon={<PlusOutlined />}
                  >
                    {' '}
                    {translation('add_answer')}
                  </Button>
                )}
              </div>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default FormItemAnswers;
