import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface Props {
  featureKey: string;
  children?: React.ReactNode;
}

const AccessControl: React.FC<Props> = ({ featureKey, children }: Props) => {
  const privilegesState = useSelector(
    (state: RootState) => state.authentication?.privileges ?? {}
  );

  return privilegesState[featureKey] ? <>{children}</> : null;
};

export default AccessControl;
