import { FC, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import ApiRepository from '../../services/api/apiRepository';
import { useIntl } from 'react-intl';

interface Props {
  visible: boolean;
  handleNewFormSuccess: () => void;
  hideModal: () => void;
}

const NewFormModal: FC<Props> = ({
  visible,
  handleNewFormSuccess,
  hideModal,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const newWorkType = await ApiRepository.createNewWorkType(values);
      await ApiRepository.createNewForm({
        ...values,
        workTypeId: newWorkType?.response.id,
      });
      handleNewFormSuccess();
      hideModal();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.message) {
        message.error(error?.message);
      }
      if (error?.errorFields) {
        for (let err of error.errorFields) {
          message.error(err.errors[0]);
        }
      }
    }
  };

  return (
    <Modal
      destroyOnClose
      title={intl.formatMessage({ id: 'new_form' })}
      okText="Create"
      open={visible}
      onOk={handleOk}
      onCancel={hideModal}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Insert a name',
            },
          ]}
        >
          <Input placeholder="Insert a name" />
        </Form.Item>
        <Form.Item
          name="description"
          label={intl.formatMessage({ id: 'description' })}
          rules={[
            {
              required: true,
              message: 'Insert a description',
            },
          ]}
        >
          <Input placeholder="Insert a description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewFormModal;
