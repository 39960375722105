export const MailboxSvg = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
    <path
      d="M10 21V14.5C10 13.5717 9.63125 12.6815 8.97487 12.0251C8.3185 11.3687 7.42826 11 6.5 11C5.57174 11 4.6815 11.3687 4.02513 12.0251C3.36875 12.6815 3 13.5717 3 14.5V21H21V15C21 13.9391 20.5786 12.9217 19.8284 12.1716C19.0783 11.4214 18.0609 11 17 11H6.5"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11V3H16L18 5L16 7H12"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15H7"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
