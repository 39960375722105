import { FC, useEffect, useState } from 'react';
import { Typography, Collapse, Spin, Empty, Col, Row, Divider } from 'antd';
import styles from '../detailDrawer.module.scss';
import { DownOutlined, WifiOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { CheckedInUsersState } from '../../checked-in-users-popover/types';
import CheckedInUsersActions from '../../checked-in-users-popover/actions';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { CheckinSvg } from '../../../../components/icons/checkinSvg';
import { CheckoutSvg } from '../../../../components/icons/chechoutSvg';
const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
  appointmentId: string;
}

const CheckinLog: FC<Props> = ({ appointmentId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const checkinLogState: CheckedInUsersState = useSelector(
    (state: RootState) => state.checkedInUsersData
  );

  const [isOpen, setIsOpen] = useState(false);

  const onHandleGetCheckinLogs = () => {
    dispatch(
      CheckedInUsersActions.fetchCheckedInUsersAsync(appointmentId, false)
    );
  };

  useEffect(() => {
    if (isOpen && appointmentId) {
      onHandleGetCheckinLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const renderCheckinLogItem = (log: any) => (
    <Row className={styles.flexStart} style={{ height: '46px' }}>
      <Col className={styles.center} style={{ width: '10%' }}>
        <WifiOutlined
          style={{
            color: !log?.checkOutTime ? '#64D59F' : '#E2E2E4',
          }}
        />
      </Col>

      <Col style={{ width: '40%' }}>
        <Text>
          {log?.user?.firstName} {log?.user?.lastName}
        </Text>
      </Col>
      <Col style={{ width: '25%' }}>
        <div className={styles.flexStart}>
          <CheckinSvg />{' '}
          <Text style={{ padding: '0 8px' }}>
            {intl.formatTime(log?.checkInTime, {
              timeStyle: 'short',
              hour12: false,
            })}
          </Text>
        </div>
      </Col>
      <Col style={{ width: '25%' }}>
        <div className={styles.flexStart}>
          <CheckoutSvg />{' '}
          <Text style={{ padding: '0 8px' }}>
            {log?.checkOutTime
              ? intl.formatTime(log?.checkOutTime, {
                  timeStyle: 'short',
                  hour12: false,
                })
              : '--'}
          </Text>
        </div>
      </Col>
    </Row>
  );

  return (
    <Collapse
      key="CheckinCollapse"
      ghost
      collapsible="header"
      className={styles.collapse}
      activeKey={isOpen ? 'drawer-checkin-log-panel-1' : undefined}
      onChange={() => setIsOpen(!isOpen)}
      items={[
        {
          key: 'drawer-checkin-log-panel-1',
          showArrow: false,
          label: (
            <>
              <Text className={styles.panelHeader}>
                {intl.formatMessage({ id: 'checkin_history' })}
              </Text>
              <DownOutlined rotate={isOpen ? 180 : 0} />
            </>
          ),
          children: checkinLogState.loadingCheckedInUsers ? (
            <Spin className={styles.spin} />
          ) : checkinLogState.checkedInUsersList.length > 0 ? (
            checkinLogState.checkedInUsersList.map((log: any, i: number) =>
              i === 0 ||
              !moment(
                checkinLogState.checkedInUsersList[i - 1]?.checkInTime
              ).isSame(log?.checkInTime, 'day') ? (
                <div key={`checkin-log-item-${i}`}>
                  <div className={styles.checkinDateHeader}>
                    {intl.formatDate(log?.checkInTime, {
                      day: 'numeric',
                    })}
                    .{' '}
                    {intl.formatDate(log?.checkInTime, {
                      month: 'short',
                    })}{' '}
                    {intl.formatDate(log?.checkInTime, {
                      year: 'numeric',
                    })}
                  </div>
                  <Divider
                    style={{ margin: 0, borderTop: '2px solid #E2E2E4' }}
                  />
                  {renderCheckinLogItem(log)}
                  <Divider style={{ margin: 0 }} />
                </div>
              ) : (
                <div key={`checkin-log-item-${i}`}>
                  {renderCheckinLogItem(log)}
                  <Divider style={{ margin: 0 }} />
                </div>
              )
            )
          ) : (
            <Empty description={false} />
          ),
        },
      ]}
    />
  );
};

export default CheckinLog;
