export const SendSvg = ({ width = 15, height = 14 }) => (
  <svg width={width} height={height} viewBox="0 0 15 14" fill="none">
    <path
      d="M6.32959 8.16667L12.7659 1.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7662 1.75L8.96298 12.25C8.93731 12.3058 8.89609 12.3532 8.84423 12.3864C8.79237 12.4195 8.73205 12.4372 8.67042 12.4372C8.6088 12.4372 8.54847 12.4195 8.49661 12.3864C8.44475 12.3532 8.40354 12.3058 8.37786 12.25L6.32995 8.16667L2.23413 6.125C2.17811 6.09941 2.13064 6.05832 2.09736 6.00661C2.06408 5.95491 2.04639 5.89477 2.04639 5.83333C2.04639 5.7719 2.06408 5.71176 2.09736 5.66005C2.13064 5.60835 2.17811 5.56726 2.23413 5.54167L12.7662 1.75Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
