import { Button, Space, Typography } from 'antd';
import styles from './appointmentCard.module.scss';
import AppointmentStatusTag from '../../../../components/appointmentStatusTag';
import {
  IAppointmentStatus,
  IAppointmentStatusType,
} from '../../../../constants/types';
import { useIntl } from 'react-intl';
import {
  ChartMTWIcon,
  LongArrowMTWIcon,
  MenuFoldLeftMTWIcon,
  MenuFoldRightMTWIcon,
} from '../../../../components/icons';
import { useState } from 'react';
import PeopleGroupSvg from '../../../../components/icons/peopleGroupSvg';
import moment from 'moment';
const { Text } = Typography;

interface CustomerInfoProps {
  appointment?: any;
}

const AppointmentCard: React.FC<CustomerInfoProps> = ({ appointment }) => {
  const intl = useIntl();
  const [showMore, setShowMore] = useState<boolean>(false);

  const generateDateTag = (date: string, category = 'primary') => (
    <div
      className={
        category === 'primary'
          ? styles.dateTag
          : category === 'scheduled'
          ? styles.dateTagScheduled
          : styles.dateTagSecondary
      }
    >
      {intl.formatDate(date, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      })}{' '}
      {intl.formatDate(date, {
        weekday: 'short',
      })}
    </div>
  );
  return !!appointment ? (
    <div className={styles.appointmentCardContainer}>
      <Space className={styles.appointmentInformation}>
        <Space style={{ minWidth: '400px' }}>
          {generateDateTag(
            appointment?.appointmentDate,
            moment().isAfter(appointment?.appointmentEndDate)
              ? 'secondary'
              : 'primary'
          )}
          <Text>
            {intl.formatDate(appointment?.appointmentDate, {
              timeStyle: 'short',
              hour12: false,
            })}
          </Text>
          <LongArrowMTWIcon />
          <Text>
            {intl.formatDate(appointment?.appointmentEndDate, {
              timeStyle: 'short',
              hour12: false,
            })}
          </Text>

          {generateDateTag(
            appointment?.appointmentEndDate,
            moment().isAfter(appointment?.appointmentEndDate)
              ? 'secondary'
              : 'primary'
          )}
        </Space>
        <Space style={{ minWidth: '180px' }}>
          <div className={styles.serviceAppointmentTypeTag}>
            {appointment?.serviceAppointmentType}
          </div>
        </Space>
        <div className={styles.appointmentTeam}>
          <div className={styles.alignedIcons}>
            <PeopleGroupSvg />
          </div>
          <div className={styles.flexCenter}>{appointment?.team?.name}</div>
        </div>

        {appointment?.team?.assignedPlName && (
          <div className={styles.appointmentTeamManagement}>
            <div className={styles.alignedIcons}>
              <ChartMTWIcon />
            </div>
            <div className={styles.flexCenter}>
              {appointment?.team?.assignedPlName}
            </div>
          </div>
        )}

        {showMore && (
          <Space style={{ minWidth: '400px', marginRight: '12px' }}>
            {generateDateTag(appointment?.scheduledDate, 'scheduled')}
            <Text>
              {intl.formatDate(appointment?.scheduledDate, {
                timeStyle: 'short',
                hour12: false,
              })}
            </Text>
            <LongArrowMTWIcon />
            <Text>
              {intl.formatDate(appointment?.scheduledEndDate, {
                timeStyle: 'short',
                hour12: false,
              })}
            </Text>

            {generateDateTag(appointment?.scheduledEndDate, 'scheduled')}
          </Space>
        )}
        {(appointment?.appointmentEndDate !== appointment?.scheduledEndDate ||
          appointment?.appointmentDate !== appointment?.scheduledDate) && (
          <Button
            onClick={() => setShowMore(!showMore)}
            className={styles.moreButton}
          >
            <div className={styles.flexCenter} style={{ gap: '6px' }}>
              {showMore ? <MenuFoldLeftMTWIcon /> : <MenuFoldRightMTWIcon />}
              {showMore ? 'Less' : 'More'}
            </div>
          </Button>
        )}
        <Space direction="vertical"></Space>
      </Space>
      <Space>
        <AppointmentStatusTag
          type={IAppointmentStatusType.serviceAppointment}
          appointment={appointment}
          status={appointment?.externalStatus ?? IAppointmentStatus.none}
        />
      </Space>
    </div>
  ) : null;
};

export default AppointmentCard;
